const View = require('core/src/functions/view');
const Function = require('core/src/function');

const CalendarView = Function.extend(View, "CalendarView");

/**
 * @override
 * @param {object} input The data to pass with the event.
 * @returns {object} The data to be passed with the RenderEvent
 */
CalendarView.prototype.alterRenderData = function(renderData) {
	if (!_.isArray(renderData.events)) {
		renderData.events = View.arraysToTable(renderData.events);
	}
	return renderData;
};

/**
 * @override
 * @returns {undefined}
 */
CalendarView.prototype.onInit = function() {
	this.setParameters({
		'#events': undefined
	});
};

module.exports = CalendarView;