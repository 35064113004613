import GraphSelector from './graph-selector';
import createDefaultCodeEvaluator from 'core/src/default-code-evaluator';
import ApiClientAbstract from 'core/src/api-client-abstract';
import Err from 'utils/src/error';

import Log from '_common/utils/src/log';
import _ from 'lodash';
import { validate } from 'utils/src/validation';
import { defaultsDeepByKeys } from 'core/src/utils/deep-struct';
import { t } from 'core/src/language';
import { parseNumber } from "utils/src/string";

// ES5 import to match the right Dependency
const CodeEvaluator = require('_common/core/src/code-evaluator');

const log = Log.instance("function/networkview/styles");

// Default final styles
export const defaultStyles = {
	node: {
		"display": "circle",
		"width": 80,
		"height": 20,
		"fillColor": "#ddd",
		"opacity": '1',
		"lineColor": "#333",
		"lineWidth": '3px',
		"lineOpacity": '1',
		"label": "node",
		"image": "NO_IMAGE", // TODO: can be undefined as soon as D3Graph.ensureNodeHasStyleSet doesn't use defaultsDeep anymore
		"labelStyle": {
			"fontWeight": "500",
			"color": "black",
			"stroke": "none",
			"strokeWidth": "1"
		}
	},
	rel: {
		"fillColor": "#999",
		"width": 1,
		"label": "(%).type",
		"marker": {
			"display": "arrow",
			"width": 10,
			"lineWidth": 1,
			"color": "grey",
			"fillColor": "grey"
		},
		"labelStyle": {
			"fontWeight": "500",
			"color": "black",
			"stroke": "none",
			"strokeWidth": "1"
		}
	},
	group: {
	}
};

// Default style definitions (before evaluation)
export const defaultStyleDefinitions = {
	node: {
		node: {
			...defaultStyles.node,
			"label": "(%).properties.name",
			"image": "(%).properties.image",
		}
	},
	rel: {
		rel: {
			...defaultStyles.rel,
			"label": "(%).type"
		}
	},
	group: {
		group: defaultStyles.group
	}
};

export const defaultOrder = {
	node: ['node'],
	rel: ['rel'],
	group: ['group']
};

const NUMBERS = new Set(['height', 'width', 'opacity', 'lineOpacity', 'lineWidth', 'strokeWidth']);

export default class GraphStyles {
	constructor(dependencies, definitions = {}) {
		this.definitions = {};
		this.order = {};
		this.dependencies = dependencies;
		this.resetDefinitions(undefined);
		this.setDefinitions(undefined, definitions);

		if(dependencies) {
			this.codeEvaluator = dependencies.get(CodeEvaluator, createDefaultCodeEvaluator);
			this.api = dependencies.get(ApiClientAbstract);
		} else {
			this.codeEvaluator = createDefaultCodeEvaluator();
		}
	}

	static clone(styles, copyDefinitions = true) {
		const clone = new GraphStyles(styles.dependencies);
		if(copyDefinitions) {
			clone.setDefinitions(undefined, _.cloneDeep(styles.definitions));
			clone.setOrder(undefined, _.cloneDeep(styles.order));
		}
		return clone;
	}

	static mergeStyles(styles) {
		return _.defaultsDeep({}, ..._.reverse(styles));
	}

	static matchSelector(entity, selector, entityType) {
		if(entityType === 'node') {
			return GraphSelector.matchNodeSelector(entity, selector);
		}
		if(entityType === 'rel') {
			return GraphSelector.matchRelSelector(entity, selector);
		}
		if(entityType === 'group') {
			return GraphSelector.matchGroupSelector(entity, selector);
		}

		return false;
	}

	static reviseStyles(styles, entity, entityType) {
		if(entityType === 'node') {
			if ((styles.label === '' || _.isNil(styles.label)) && entity.labels.length) {
				styles.label = entity.labels[0];
			}
			if (_.includes(['circle', 'square', 'triangle'], styles.display)) {
				styles.height = styles.width;
			}
		}
	}

	static applyDefaults(style, entityType) {
		if(entityType === 'relation') entityType = 'rel';
		defaultsDeepByKeys(style, defaultStyleDefinitions[entityType][entityType]);
	}

	static flatten(definitions) {
		return _.concat(definitions.node, definitions.rel, definitions.group);
	}

	static unflatten(styles) {
		const definitions = {node:{}, rel:{}, group:{}};
		_.forEach(styles, (style, selector) => {
			let expanded = GraphSelector.expandSelector(selector);
			if(expanded.node) {
				definitions.node[selector] = style;
			} else if (expanded.rel) {
				definitions.rel[selector] = style;
			} else if (expanded.group) {
				definitions.group[selector] = style;
			} else {
				log.warn(`Unknown selector '${selector}'.`);
			}
		});
		return definitions;
	}

	/**
	 * Reset style properties to default that have been marked as 'evaluated' in the `evaluated` style property.
	 * @param {object} style		Style object
	 * @param {string} entityType	Entity type (e.g. 'node', 'rel', 'group')
	 * @param {[string]} path		Path of the current (sub-)style object (for recursion)
	 */
	static resetEvaluatedStyles(style, entityType, path = []) {
		if(!_.isPlainObject(style)) return;

		if(entityType === 'relation') entityType = 'rel';

		if(!('evaluated' in style)) return;
		_.forEach(style.evaluated, property => {
			const newPath = [...path, property].join('.');
			if(_.isPlainObject(style[property])) {
				this.resetEvaluatedStyles(style[property], entityType, newPath);
				return;
			}
			style[property] = _.get(defaultStyles[entityType], newPath);
		});
	}

	static fromStylesNode(dependencies, userStylesNode) {
		const styles = new GraphStyles(dependencies);
		styles.loadFromStylesNode(userStylesNode);
		return styles;
	}

	new() {
		return GraphStyles.clone(this, false);
	}

	setParent(parentStyles) {
		this.parent = parentStyles;
	}

	/**
	 *
	 * @param {GraphStyles|object} styles	GraphStyles or definitions object
	 * @returns {*|GraphStyles}
	 */
	extend(styles) {
		const extended = styles instanceof GraphStyles
			? styles
			: new GraphStyles(this.dependencies, styles); // assume they are definitions
		extended.setParent(this);
		return extended;
	}

	async loadUserStyles() {
		const stylesNode = await this.api.requestUserStyleNode();
		this.loadFromStylesNode(stylesNode);
	}

	loadFromGraphStyles(styles) {
		this.setDefinitions(undefined, _.cloneDeep(styles.getDefinitions()));
		this.setOrder(undefined, _.cloneDeep(styles.getOrder()));
	}

	loadFromStylesNode(node) {
		const stylesString = _.get(node, 'properties.STYLES');
		const stylesOrderString = _.get(node, 'properties.selectorsOrder');

		const check = validate({
			stylesString: [stylesString, 'isString'],
			stylesOrderString: [stylesOrderString, 'isString']
		});

		if (!check.isValid()) {
			log.error("Could not validate style node", node);
			alert(t('Style node is currupted. More information in the console.'));
			return false;
		}
		const valid = check.getValue();

		let userStyles;
		let order;

		try {
			userStyles = JSON.parse(valid.stylesString);
			order = JSON.parse(valid.stylesOrderString);
		}
		catch (exception) {
			log.error("Could not parse styles", valid);
			alert(t('Style node ({{nodeId}})) is corrupted. More information in the console.', {nodeId: node.id}));
			return false;
		}

		const definitions = {node:{}, rel:{}, group:{}};
		const graphStylesOrder = {};

		_.forEach(['node', 'rel', 'group'], type => {
			graphStylesOrder[type] = [];
			_.forEach(order[type], key => {

				let selector = key;
				if (selector === 'Default style') {
					selector = type;
				}

				delete userStyles[type][key]['expandedSelector'];
				delete userStyles[type][key]['styleName'];

				definitions[type][selector] = userStyles[type][key];
				graphStylesOrder[type].push(selector);
			});
		});

		this.definitions = definitions;
		this.order = graphStylesOrder;
	}

	getDefaultDefinition(entityType) {
		if(entityType === 'relation') entityType = 'rel';

		return defaultStyleDefinitions[entityType][entityType];
	}

	resetDefinitions(entityType) {
		if(entityType === 'relation') entityType = 'rel';
		if (entityType) {
			this.setDefinitions(entityType, _.cloneDeep(defaultStyleDefinitions[entityType]));
			return;
		}
		this.setDefinitions(undefined, _.cloneDeep(defaultStyleDefinitions));
	}

	setOrder(entityType, order) {
		if(!entityType) {
			this.order = order;
			return;
		}
		if(entityType === 'relation') entityType = 'rel';
		this.order[entityType] = order;
	}

	getOrder(entityType) {
		if(!entityType) {
			return this.order;
		}
		if(entityType === 'relation') entityType = 'rel';
		return this.order[entityType];
	}

	setDefinitions(entityType, definitions) {
		definitions = definitions || {};
		if(!entityType) {
			_.forEach(definitions, (styles, entityType) => this.setDefinitions(entityType, styles));
			return;
		}
		if(entityType === 'relation') entityType = 'rel';
		this.definitions[entityType] = definitions;
		this.setOrder(entityType, _.keys(definitions)); // default order from keys
	}

	setDefinition(entityType, selector, style) {
		if(!_.isPlainObject(style)) {
			log.error("Style must be object.");
			return;
		}
		if(entityType === 'relation') entityType = 'rel';
		if(!(selector in this.getDefinitions(entityType))) {
			this.getOrder(entityType).push(selector);
		}
		this.definitions[entityType][selector] = style;
	}

	removeDefinition(entityType, selector) {
		delete this.definitions[entityType][selector];
		_.remove(this.order[entityType], listSelector => listSelector === selector);
	}

	getDefinitions(entityType) {
		if(!entityType) {
			return this.definitions;
		}
		if(entityType === 'relation') entityType = 'rel';
		return this.definitions[entityType];
	}

	orderStyles(entityType, styles) {
		const ordered = [];
		_.forEach(this.getOrder(entityType), selector => {
			if(selector in styles) {
				ordered.push(styles[selector]);
			}
		});
		return ordered;
	}

	computeStyles(entity, entityType, evaluate = true, explicitEvaluationOnly = false) {
		// Parent styles
		const parentStyles = this.parent ? this.parent.computeStyles(entity, entityType, false) : {};

		// Local styles
		const matches = this.findMatchingStyles(entity, entityType);
		const ordered = this.orderStyles(entityType, matches);

		// Merge parent, local, entity
		let style = GraphStyles.mergeStyles(_.concat(parentStyles, ordered, entity.style));

		if(evaluate) {
			style = this.evaluateStyles(style, entity, explicitEvaluationOnly);
		}
		style.matchedSelectors = _.keys(matches);
		if(this.parent) {
			// Include parent's matched selectors in final style
			style.matchedSelectors = [...parentStyles.matchedSelectors, style.matchedSelectors];
		}
		GraphStyles.applyDefaults(style, entityType);
		GraphStyles.reviseStyles(style, entity, entityType);
		return style;
	}

	/**
	 * Evaluate code in style properties
	 * @param style
	 * @param entity
	 * @param explicitOnly
	 * @return {undefined|*}
	 */
	evaluateStyles(style, entity, explicitOnly = false) {
		if(!this.codeEvaluator) {
			return style;
		}

		// Recursion
		if(_.isPlainObject(style)) {
			const subStylesWereEvaluated = (value) => _.isPlainObject(value) && (_.get(value, 'evaluated.length') > 0);
			const codeWasEvaluated = (value, original) =>
				!_.isPlainObject(value)
				&& !_.isEqual(value, original)
				&& !_.isEqual(_.toString(value), original); // we don't consider evaluation of "1" to 1 'evaluation' in this context

			style.evaluated = style.evaluated || [];
			return _.mapValues(style, (subStyle, key) => {
				const evaluated = this.evaluateStyles(subStyle, entity, explicitOnly);
				// If value was evaluated, mark it (if not already marked)
				if(subStylesWereEvaluated(evaluated) || codeWasEvaluated(evaluated, subStyle)) {
					if(style.evaluated.indexOf(key) < 0) {
						style.evaluated.push(key);
					}
				}
				if(NUMBERS.has(key) && !_.isNumber(evaluated)) {
					return parseNumber(evaluated, 1);
				}
				return evaluated;
			});
		}

		if(!_.isString(style)) {
			return style;
		}

		const start = "evaluate(";
		const trimmed = _.trim(style);
		if(explicitOnly && !(trimmed.substring(0,start.length) === start && trimmed.substring(style.length-1) === ")")) {
			// Skip
			return style;
		}

		// Single value
		try {
			let code = 'let value = ' + CodeEvaluator.replaceCodePlaceholders(String(style), {
				'(%)': '_entity'
			});
			let options = {expressionSet: 'full'};
			let context = {_entity: entity, properties: entity.properties};
			let evaluated = this.codeEvaluator.evaluate(code, context, options);
			return evaluated.value;
		} catch (e) {
			// Could not evaluate as code. Return original value.
			return style;
		}
	}

	findMatchingStyles(entity, entityType) {
		if(entityType === 'relation') entityType = 'rel';

		const matches = {};
		_.forEach(this.getDefinitions(entityType), (style, selector) => {
			if(GraphStyles.matchSelector(entity, selector, entityType)) {
				matches[selector] = style;
			}
		});
		return matches;
	}

	async saveAsUserStyles() {
		if(!this.api) {
			const message = t('Cannot save user styles: no API provided.');
			const err = new Err(message);
			log.error(message);
			return Promise.reject(err);
		}

		let styleNode;
		try {
			styleNode = await this.api.requestUserStyleNode();
		} catch(e) {
			styleNode = await this.api.createUserStyleNode();
		}

		this.toUserStylesNode(styleNode);

		log.log('Saving styles.');

		return this.api.saveStyleNode(styleNode);
	}

	toUserStylesNode(existingNode) {
		const styleNode = existingNode ? existingNode : {
			labels: ['IA_UserStyles'],
			properties: {}
		};
		styleNode.properties.STYLES = JSON.stringify(this.definitions);
		styleNode.properties.selectorsOrder = JSON.stringify(this.order);
		return styleNode;
	}
}
