const ViewRenderer = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/htmlview/renderer");
const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const formToObject = require('client/src/utils/formToObject');
const ComponentLoader = require('client/src/component-loader').default;
require('../css/html-view.css');

const clipboard = require("clipboard-polyfill/build/clipboard-polyfill.promise");
const toClipboard = function(textForClipboard, type) {
	type = type || 'text/plain';
	var dt = new clipboard.DT();
	dt.setData(type, textForClipboard);
	clipboard.write(dt);
};

const readFileDataFromForm = async function (form) {
	var fileInputs = form[0].querySelectorAll('input[type=file]');
	var data = {};
	var fileReader = new FileReader();
	for(var input of fileInputs){
		try{
			fileReader.readAsText(input.files[0]);
			data[input.name]= await new Promise((resolve) => {
				fileReader.onload = function () {
					resolve(fileReader.result);
				};
			});
		}
		catch (e) {
			log.error('input file read error.', e);
		}
	}
	return data;
};

/* Inheritance and constructor */

const HtmlViewRenderer = function(dependencies) {
	ViewRenderer.call(this);

	this.componentLoader = new ComponentLoader(dependencies);
	this.html = '';
};
HtmlViewRenderer.viewType = 'HtmlView';
HtmlViewRenderer.prototype = Object.create(ViewRenderer.prototype);

HtmlViewRenderer.prototype.$div = null;
HtmlViewRenderer.prototype.$output = null;
HtmlViewRenderer.prototype.originalHtml = '';
HtmlViewRenderer.prototype.componentLoader = null;
HtmlViewRenderer.prototype.autoScrollBottom = false;

/* Methods */

HtmlViewRenderer.prototype.submitForm = async function(form) {
	var $form = $(form);
	var formData = formToObject($form);
	var fileData = await readFileDataFromForm($form);
	formData = Object.assign(formData, fileData);

	this.trigger({
		type: 'submit',
		form: $form.attr('name'),
		data: formData
	});
	if($form.hasClass('close-on-submit')) {
		this.close();
	}
};

HtmlViewRenderer.prototype.doUpdate = function(renderData, mutations, collectionMutations) {
	// Only replace html if it's really changed; prevent interactor areas from being cleared without reason.
	if(renderData.html !== this.originalHtml) {
		this.$output.html(renderData.html);
		this.originalHtml = renderData.html;
		this.componentLoader.loadComponents(this.$output);
	}
};

HtmlViewRenderer.prototype.setupSubmitOnChange = function($html) {
	// Add submit-on-change functionality
	var getInputValue = function(input) {
		var $input = $(input);

		var val = $input.val();

		if ($input.attr('type') === 'checkbox') {
			return $input.is(':checked') ? val : false;
		}

		return val;
	};

	$html.find('[submit-on-change]').each(function() {
		var $this = $(this);
		var lastValue = getInputValue($this);
		var debounceMs = $this.attr('submit-on-change');
		var form = $this.parents('form').first();

		debounceMs = (debounceMs > 0) ? debounceMs : 500;

		if (! form.length) {
			console.error('Input is not inside a form', this);
			return;
		}

		$this.removeAttr('submit-on-change');
		$this.attr('submit-on-change-activated', debounceMs);

		$this.on('change keyup', _.debounce(
			function() {
				var currentValue = getInputValue(this);
				if (currentValue == lastValue) {
					return;
				}

				lastValue = currentValue;
				form.submit();
			},
			debounceMs
		));

	});
};

HtmlViewRenderer.prototype.setHtml = function(html) {
	this.$output.append(html);
	this.setupSubmitOnChange(this.$output);
	this.componentLoader.loadComponents(this.$output);
};

HtmlViewRenderer.prototype.doRender = function(renderData) {
	var self = this;

	this.$div = $('<div>');
	this.$div.addClass('HtmlView h-100');

	this.$output = $('<div class="html-output h-100">');

	// Html will be added later, to make sure content exists on page for further initializations (e.g. jsPanel prevents <script> if set directly)
	this.originalHtml = renderData.html;
	this.autoScrollBottom = renderData.autoScrollBottom;

	var $styles = this.$output.find('style');
	if($styles.length > 0) {
		log.warn("HtmlView template should not contain <style> elements. Styling can be added in $css parameter.");
	}
	$styles.remove();

	this.$div.append(this.$output);

	// Add click trigger
	this.$div.on('click', '.trigger-click', function() {
		var data = $(this).data();
		self.userEvent({
			type: 'click',
			data: data
		});
	});
	this.$div.on('click', 'button:has(.trigger-click)', function() { // special case for Firefox
		log.warn("A .trigger-click element cannot be clicked inside a button element in Firefox. " +
			"Alternative is to make the button itself the .trigger-click element.");
	});

	// Add send text to clipboard functionality
	this.$div.on('click', '[to-clipboard]', function() {
		toClipboard($(this).attr('to-clipboard'), $(this).attr('data-type'));
	});

	// Add context menus
	this.$div.on('contextmenu', '[class^="context-"],[class*=" context-"]', function() {
		var element = $(this);
		var matchContext = element.attr("class").match(/context-([\w-]+)/);
		if(_.isArray(matchContext)) {
			var contextClass = matchContext[1];
			var data = element.data();
			self.openContextMenu(contextClass, data);
		}
		return false;
	});

	// Form submissions
	this.$div.on('submit', 'form', function(e) {
		e.preventDefault();
		self.submitForm(this);
	});

	this.$div.on('interactor-event', function(e,params) {
		self.userEvent(params);
	});

	return this.$div;
};

HtmlViewRenderer.prototype.onReady = function() {
	this.setHtml(this.originalHtml);
	if (this.autoScrollBottom) {
		const container = this.$div.closest('.container-content')[0];
		container.scrollTop = container.scrollHeight;
	}
};

module.exports = HtmlViewRenderer;
