"use strict";

const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const log = require('core/src/log').instance("function/yfilesview/renderer");

const ViewRenderer = require('client/src/view-renderer');
const GraphStyles = require('core/src/graph/graph-styles').default;
const IFactory = require('core/src/i-factory').default;
const Registry = require('core/src/registry').default;
const { GraphRegistry, isGraph } = require('core/src/graph/i-graph');
const { GraphFilters } = require('client/src/graph/graph-filters');
const { hasChangesInChildren, findChangeFromRoot } = require('core/src/utils/changes');
const { isTrue } = require('core/src/utils/validation');
const Profiler = require('utils/src/profiler');
const Language = require("core/src/language").default;
const IGraphileon = require("core/src/i-graphileon").default;
const APIClientAbstract = require('core/src/api-client-abstract').default;
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');

/**
 * @typedef {{id:string|number, labels:string[], properties?:object, x?:number, y?:number, fixed?:boolean, meta?:{store?:string}}} Node
 * @typedef {{id:string|number, type:string, properties?:object, source:string|number, target:string|number, meta?:{store?:string}}} Relation
 * @typedef {Node & {children:(string|number)[]}} Group
 */

class YFilesViewRenderer extends ViewRenderer {

	constructor(dependencies) {
		super(dependencies);
		this.dependencies = dependencies;
		this.language = dependencies.get(Language);
		this.graphileon = dependencies.get(IGraphileon);
		this.api = dependencies.get(APIClientAbstract);

		/**
		 * ID of this instance
		 * @type {Number}
		 */
		this.id = _.uniqueId();
		/**
		 * The YFilesGraph instance
		 * @type {YFilesGraph}
		 */
		this.graph = null;
		/**
		 * Wrapper element containing graph and toolbar
		 * @type {Object}
		 */
		this.wrapperEl = null;
		/**
		 * Graph element
		 * @type {Object}
		 */
		this.graphEl = null;
		/**
		 * Toolbar container
		 * @type {Object}
		 */
		this.toolbarEl = null;
		/**
		 * Overview check element
		 * @type {Object}
		 */
		this.overviewCheck = null;
		/**
		 * Available layouts to show in selectbox
		 * @type {Array}
		 */
		this.availableLayouts = [];
		/**
		 * Layout name (default is organic)
		 * @type {String}
		 */
		this.layoutName = "organic";
		/**
		 * Layout selector element
		 * @type {object}
		 */
		this.layoutSelectEl = null;
		/**
		 * The state of the model the renderer is currently showing
		 * @type {{}}
		 */
		this.model = {};

		this.pdfExportEl = null;
		this.paperSizeSelectEl = null;
		this.pdfExportPaperSize = "A4";
		this.pdfExportPageOrientation = "l";

		this.loading = new Promise((resolve, reject) => {
			this.resolveLoading = resolve;
			this.rejectLoading = reject;
		});

		/**
		 *
		 * @type {GraphFilters}
		 */
		this.filters = null;

		this.factory = dependencies.get(IFactory);
		this.styles = dependencies.get(GraphStyles);
		this.registry = dependencies.get(Registry);
		this.registry.require(GraphRegistry, isGraph);

		this.localStyles = this.styles.extend();
		this._autoCompleteStatus = true;

		this._selectionModes = ['marquee', 'lasso'];
		this._selectionMode = 0;
	}

	/**
	 * Get nodes from model.
	 * @returns {Node[]}
	 */
	getNodes() {
		return this.model.nodes;
	}

	/**
	 * Get relations from model
	 * @returns {Relation[]}
	 */
	getRelations() {
		return this.model.relations;
	}

	/**
	 * Get nodes that do not have their filter property set to `false`.
	 * @returns {Node[]}
	 */
	getFilteredNodes() {
		return _.filter(this.model.nodes, node => node[this.model.filterProperty] !== false);
	}

	/**
	 * Get relations that do not have their filter property set tot `false`.
	 * @returns {Relation[]}
	 */
	getFilteredRelations() {
		return _.filter(this.model.relations, rel => rel[this.model.filterProperty] !== false);
	}

	/**
	 * Get x,y coordinate of given node in the graph.
	 * @param {Node} node
	 * @returns {{x:number, y:number}|false}
	 */
	getPosition(node) {
		if (! node) {
			return false;
		}

		if (_.isFinite(node.x) && _.isFinite(node.y)) {
			return {x: node.x, y: node.y};
		}

		if (_.isFinite(node.px) && _.isFinite(node.py)) {
			return {x: node.px, y: node.py};
		}

		return false;
	}

	/**
	 * Get unique index of the given entity.
	 * @param {Node|Relation} entity
	 * @returns {string}
	 */
	getIndex(entity) {
		const store = _.get(entity, 'meta.store');
		let prefix = store ? `${store}_` : '';

		// If it seems like a relationship include source and target in the index
		// If one changes this means that it is a "new" relationship even if it has the same id and store
		if (_.has(entity, 'source') && _.has(entity, 'target')) {
			return `${prefix}${entity.id}-${entity.source}-${entity.target}`;
		}

		return `${prefix}${entity.id}`;
	}

	/**
	 * Get selected nodes from visualization.
	 * @returns {Tag[]|*[]}
	 */
	getSelectedNodes() {
		if(!this.graph) return [];

		return this.graph.getSelectedNodes();
	}

	/** @private */
	initDOM() {
		const self = this;
		// Main graph element
		this.graphEl = $("<div/>")
			.css({
				position: "relative",
				overflow: "hidden",
				outline: 0,
				width: "100%",
				height: "100%",
				padding: "0",
				margin: "0",
			});

		// Toolbar container
		this.toolbarEl = this.createToolbar();
		this.createContextMenus();

		// Wrapper element for all of the components
		this.wrapperEl = $("<div/>")
			.css({
				width: "100%",
				height: "100%",
				"padding-top": "50px",
				"box-sizing": "border-box",
				position: "relative"
			})
			.append(
				$("<div/>")
					.css({
						width: "100%",
						height: "100%",
						position: "relative"
					})
					.append(
						this.toolbarEl,
						this.graphEl,
					)
			);

		this.loader = $('<div class="fa fa-spinner fa-spin"></div>').css({
			position: 'absolute',
			'font-size': '2rem',
			top: '50%',
			left: '50%',
			'margin-left': '-1.5rem'
		})
		this.wrapperEl.append(this.loader);

		$(self.wrapperEl).ready(function() {
			const toolbar = self.toolbarEl.get(0);
			const popoverTriggerList = toolbar.querySelectorAll('[data-bs-toggle="popover"]')
			let counter = 0;
			const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
				let options = {};
				const idClass = 'yfiles-popover-'+counter;
				let headerClasses = '';
				let bodyClasses = '';

				const contentID = popoverTriggerEl.getAttribute('data-bs-content-id');
				if (contentID) {
					options.html = true;
					options.content = $(self[contentID]);

					if (contentID === "filtersEl") {
						headerClasses = "d-none"
						bodyClasses = "p-0"
					}
				}

				options.animation = false;
				options.trigger = "click";
				options.template = `
					<div class="popover ${idClass} mw-100" role="tooltip">
						<div class="popover-arrow"></div>
						<div class="popover-header ${headerClasses} bg-secondary-subtle"></div>
						<div class="popover-body ${bodyClasses}"></div>
					</div>
				`

				const popover = new bootstrap.Popover(popoverTriggerEl, options);

				const closeOutsideClick = popoverTriggerEl.getAttribute('close-click-outside');
				if (closeOutsideClick) {
					// after the popover is shown, add a tabindex and then focus the parent element 
					popoverTriggerEl.addEventListener('shown.bs.popover',  () => {
						let el = document.querySelector('.'+idClass)
						if(!el) return;
	
						// tabindex needed
						el.setAttribute("tabindex", -1)
					
						el.addEventListener("focusout", async (e) => {
							if (e.relatedTarget && e.relatedTarget.isEqualNode(popoverTriggerEl)) {
								return;
							}
							if(!el.contains( e.relatedTarget)){
								popover.hide()
							}
						})
	
						el.focus() 
					})
	
					// remove the focus event on close
					popoverTriggerEl.addEventListener('hide.bs.popover',  () => {
						let el = document.querySelector('.'+idClass)
						if(!el) return;
						el.removeEventListener("focusout", ()=>{})
					})
				}

				counter++;
			})
		})
	}

	/**
	 * Show/hide the loader indicating the view is not ready.
	 * @param {boolean} show
	 */
	showLoader(show = true) {
		show ? this.loader.show() : this.loader.hide();
	}

	/** @private */
	async initGraph(model) {
		this.showLoader(true);
		try {
			const YFilesGraph = (await import(/* webpackChunkName: 'yfiles' */ './yfiles-view-renderer/yfiles-graph')).default
			await this.graphileon.requireScript(`${this.api.getServer()}/api/license/yfiles.js?2.2.0`).catch(()=>{}); // logging is already taken care of
			this.showLoader(false);

			this.graph = new YFilesGraph(this.graphEl, {
				getIndex: this.getIndex.bind(this),
				// Source/Target index assumes source and target are in same store as relation.
				edgeSourceNodeIndex: edge => {
					const store = _.get(edge, 'meta.sourceStore', _.get(edge, 'meta.store'));
					return this.getIndex({id: edge.source, meta: {store}});
				},
				edgeTargetNodeIndex: edge => {
					const store = _.get(edge, 'meta.targetStore', _.get(edge, 'meta.store'));
					return this.getIndex({id: edge.target, meta: {store}});
				},
				getPosition: this.getPosition.bind(this),
				canChangeRelationEnds: _.get(model, 'canChangeRelationEnds', false),
				zoomToFitStatus: _.get(model, 'zoomToFitStatus', false)
			});
			this.setupEvents(this.graph);
			this.resolveLoading(this.graph);
		} catch(e) {
			this.rejectLoading(e);
		}
	}

	/* @private */
	setupEvents(graph) {
		const addEventListener = event => {
			let method = `on${_.upperFirst(event)}`;
			if (!_.isFunction(this[method])) {
				log.error(`Cannot listen to '${event}' event. YFilesViewRenderer.${method} is not a function.`);
				return;
			}
			graph.on(event, this[method].bind(this));
		};
		addEventListener('nodeClick');
		addEventListener('nodeRightClick');
		addEventListener('nodeDoubleClick');
		graph.on('edgeClick', this.onRelationClick.bind(this));
		graph.on('edgeRightClick', this.onRelationRightClick.bind(this));
		graph.on('edgeDoubleClick', this.onRelationDoubleClick.bind(this));
		addEventListener('groupClick');
		addEventListener('groupRightClick');
		addEventListener('groupDoubleClick');
		addEventListener('canvasClick');
		addEventListener('canvasRightClick');
		addEventListener('canvasDoubleClick');
		addEventListener('click');
		addEventListener('delete');
		addEventListener('link');
		addEventListener('linkToCanvas');
		addEventListener('selection');
		addEventListener('localUpdate');
		addEventListener('relationSourceChanged');
		addEventListener('relationTargetChanged');
		addEventListener('zoomToFitChanged');
	}

	doRender(model) {
		// Layouts should be an array
		if (_.isArray(model.layouts)) {
			this.availableLayouts = model.layouts;
		} else {
			// For backward compatibility, we also accept a JSON array string
			try {
				this.availableLayouts = JSON.parse(model.layouts)
			} catch (e) {
				this.availableLayouts = []
			}
		}
		this.model = model;
		this._selectionMode = this._selectionModes.indexOf(model.selectionMode) !== -1 ? this._selectionModes.indexOf(model.selectionMode) : 0;

		this.initDOM();
		this.initGraph(model);

		const initialChanges = _.mapValues(model, value => ({
			old: undefined,
			new: value
		}));

		this.doUpdate(model, initialChanges);

		// Register
		this.registry.add(GraphRegistry, this);

		return this.wrapperEl;
	}

	async doUpdate(model, changes, mutationId) {
		await this.loading;

		Profiler.start("yfiles-view-renderer.doUpdate");
		this.model = model;

		if(hasChangesInChildren(changes, 'filters')) {
			this.filters.loadFromSimpleList(model.filters);
		}

		this.filters.updateAutoFilters();
		this.filters.updateInterface();

		if (this.filters.filterGraph()) {
			// If items are filtered in graph a new NetworkViewRenderer.doUpdate will be issued
			// through the NetworkViewRenderer.filter method and we will revisit the rest later
			return;
		}

		Profiler.start("yfiles-view-renderer.doUpdate.styles");

		// Extend styles with used styles and styles parameter
		this.localStyles = this.styles;
		if(model.usedStyles && model.usedStyles.length) {
			this.localStyles = new GraphStyles(this.dependencies); // Specific styles nodes are used so don't use user styles
			_.forEach(model.usedStyles, stylesNode => {
				this.localStyles = this.localStyles.extend(GraphStyles.fromStylesNode(this.dependencies, stylesNode));
			});
		}
		// Extend styles with specifically defined styles on YFilesView
		this.localStyles = this.localStyles.extend(GraphStyles.unflatten(model.styles));

		this.updateStyles();

		Profiler.stop("yfiles-view-renderer.doUpdate.styles");

		Profiler.start("yfiles-view-renderer.doUpdate.data");
		this.graph.setLayoutOptions(model.layoutOptions);

		if (_.some(['nodes', 'relations', 'groups', 'layout', 'styles'], this.hasChangedIn(changes))) {
			this.graph.setData({
				nodes: model.nodes,
				edges: model.relations,
				groups: model.groups
			}, model.layout);
		}
		Profiler.stop("yfiles-view-renderer.doUpdate.data");

		this.graph.showOverview(model.showOverview);
		this.graph.setSelectionMode(this._selectionModes[this._selectionMode]);

		// Update layout selection
		if(this.layoutSelectEl) {
			this.layoutSelectEl.val(model.layout);
		}

		// Check/uncheck overview
		let overviewChecked = model.showOverview === undefined || _.hasBooleanValue(model.showOverview, true);
		this.overviewCheck.prop('checked', overviewChecked);

		// Check/uncheck autocomplete
		this._autoCompleteStatus = model.autoCompleteStatus === undefined || _.hasBooleanValue(model.autoCompleteStatus, true);
		this.setButtonActiveStatus(this._autoCompleteStatus, "yfiles-view-autocomplete");

		this.graph.setSelection({
			nodes: model.state.selected.nodes,
			edges: model.state.selected.relations,
			groups: model.state.selected.groups
		});

		this.registry.notifyChange(GraphRegistry, this);

		Profiler.stop("yfiles-view-renderer.doUpdate");

		this.graph.setZoomToFit(model.zoomToFitStatus);
		this.doResize();

		// Focusing when jsPanel is colapsed breaks jsPanel header so we prevent that
		const graphVisibleSize = this.graph.graphEl[0].getBoundingClientRect();
		if (graphVisibleSize.height) {
			// Focus to be able to get keyboard keys status
			this.graph.graphEl.focus();
		}
	}

	/**
	 * Recalculate and apply styles to all entities in the graph.
	 */
	updateStyles() {
		Profiler.start("yfiles-view-renderer.updateStyles.final");
		_.forEach({
			nodes: 'node',
			relations: 'relation',
			groups: 'group'
		}, (entityType, collection) => {
			_.forEach(this.model[collection], entity => {
				delete entity.style;
				entity.style = this.localStyles.computeStyles(entity, entityType, false);
				entity.finalStyle = this.localStyles.evaluateStyles(entity.style, entity, this.model.stylesEvaluation === 'explicit');
				GraphStyles.reviseStyles(entity.finalStyle, entity, entityType);
			})
		});
		Profiler.stop("yfiles-view-renderer.updateStyles.final");

		_.forEach(this.model.nodes, entity => {
			_.forEach(this.model.nodeTemplates, (template, selector) => {
				const match = GraphStyles.matchSelector(entity, selector, 'node');
				if (!match) {
					return;
				}
				
				if (_.isNil(entity.nodeTemplate)) {
					entity.nodeTemplate = template
				}
				
				if (entity.selector && selector.length > entity.selector.length) {
					entity.nodeTemplate = template
				}

				entity.selector = selector;
			})
			delete entity.selector;
		});

		if(this.graph) {
			Profiler.start("yfiles-view-renderer.updateStyles.graph");
			this.graph.updateStyles();
			Profiler.stop("yfiles-view-renderer.updateStyles.graph");
		}
	}

	async doResize() {
		await this.loading;
		if(this.graph.zoomToFitStatus) {
			this.graph.zoomToFit();
		}
	}

	onClose() {
		this.registry.remove(GraphRegistry, this);
	}

	onNodeClick(event) {
		this.trigger({
			type: 'nodeClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	async onNodeRightClick(event) {
		await this.onSelectionUpdate;
		if (!this.openContextMenu('node', event.data)) {
			this.trigger({
				type: 'nodeRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onNodeDoubleClick(event) {
		if(this.model.explorable) {
			this.event("findNeighbours", [event.data.id]);
			this.graph.setVisibleNodeAfterLayout(event.data);
		}
		this.trigger({
			type: 'nodeDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onRelationClick(event) {
		this.trigger({
			type: 'relationClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	async onRelationRightClick(event) {
		await this.onSelectionUpdate;
		if (!this.openContextMenu('relation', event.data)) {
			this.trigger({
				type: 'relationRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onRelationDoubleClick(event) {
		this.trigger({
			type: 'relationDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onGroupClick(event) {
		this.trigger({
			type: 'groupClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onGroupRightClick(event) {
		if (!this.openContextMenu('group', event)) {
			this.trigger({
				type: 'groupRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onGroupDoubleClick(event) {
		this.trigger({
			type: 'groupDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onCanvasClick(event) {
		this.trigger({
			type: 'canvasClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onCanvasRightClick(event) {
		const position = event.position;
		position.graphX = position.x;
		position.graphY = position.y;
		if (!this.openContextMenu('canvas', position)) {
			this.trigger({
				type: 'canvasRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}

	onCanvasDoubleClick(event) {
		this.trigger({
			type: 'canvasDoubleClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onClick(event) {
		const data = $(event.el).data();
		data.node = event.node;
		this.trigger({
			type: 'click',
			data: data
		});
	}

	onDelete(event) {
		this.requestUpdate({
			"_update.remove": {
				nodes: event.nodes,
				relations: event.edges,
				groups: event.groups
			}
		});
	}

	/**
	 * Handle the event in which the user links one node to another (by drawing an edge).
	 * @param event
	 */
	onLink(event) {
		this.trigger({
			type: 'link',
			...event
		});
	}

	/**
	 * Handle the event when linking one node to another is canceled (by drawing an edge).
	 * @param event
	 */
	 onLinkToCanvas(event) {
		this.trigger({
			type: 'linkToCanvas',
			...event
		});
	}

	onSelection(event) {
		this.onSelectionUpdate = this.requestUpdate({
			state: {selected: {nodes: event.nodes, relations: event.edges, groups: event.groups}}
		});
	}

	/**
	 * Handle the event in which changes to entities occurred in the graph, which should be applied to the model.
	 * @param {{nodes?:Node[], edges?:Relation[], groups?:Group[]}} updates
	 */
	onLocalUpdate(updates) {
		if('edges' in updates) {
			updates.relations = updates.edges;
			delete updates.edges;
		}

		const changes = {};
		_.forEach(Object.keys(updates), category => {
			changes[category] = updates[category].map(update => ({...update.update, ...this.getIdentifier(update.entity)}))
		});
		this.requestUpdate({"_update.change": changes});
	}

	onRelationSourceChanged(event) {
		this.trigger({
			type: 'relationSourceChanged',
			relation: event.edge,
			newSource: event.newSource
		});
	}

	onRelationTargetChanged(event) {
		this.trigger({
			type: 'relationTargetChanged',
			relation: event.edge,
			newTarget: event.newTarget
		});
	}

	/**
	 * Handle the event in which any of the filters changed.
	 * @param {{nodes?:Node[], relations?:Relation[], groups?:Group[]}} visible
	 */
	onFilter(visible) {
		this.requestUpdate({
			'state.visible': visible
		});
	}

	onZoomToFitChanged(status) {
		this.setButtonActiveStatus(status, 'yfiles-view-zoom-to-fit');
		this.requestUpdate({
			zoomToFitStatus: status
		})
	}

	/**
	 * Create an object with properties that, together, uniquely identify the given entity.
	 * @param {Node|Relation} entity
	 * @returns {object}
	 */
	getIdentifier(entity) {
		return _.pick(entity, ['id', 'meta.store']);
	}

	/** @private
	 * Create filter panel
	 * */
	createFilters() {
		const container = $('<div class="yfiles-view-filters">');
		this.filters = new GraphFilters({
			container: container,
			graph: {
				getNodes: this.getNodes.bind(this),
				getRelations: this.getRelations.bind(this),
				getFilteredNodes: this.getFilteredNodes.bind(this),
				getFilteredRelations: this.getFilteredRelations.bind(this)
			},
			autoFilterStatus: isTrue(this.model.addAutoFilters)
		});
		this.filters.on(GraphFilters.Event.FILTER, this.onFilter.bind(this));
		this.filters.on(GraphFilters.Event.FILTER_RENDERER, this.onFilter.bind(this));

		return container;
	}

	/**
	 * @private
	 * Create toolbar UI
	 */
	createToolbar() {
		const self = this;

		const toolbarEl = $("<div/>")
			.hide()
			.addClass("btn-toolbar form-control bg-secondary-subtle rounded-0")
			.attr("role", "toolbar")
			.css({
				"z-index": "99",
				position: "absolute",
				left: "0",
				right: "0",
				top: "-45px",
			});

		if (this.availableLayouts.length) {
			// Layout select
			this.layoutSelectEl = $("<select/>")
				.addClass('form-select w-auto')
				.change(function() {
					self.requestUpdate({layout: $(this).val()});
				});

			// Lazy load yfiles-layout file
			import(/* webpackChunkName: 'yfiles' */  './yfiles-view-renderer/layout/yfiles-layout').then(module => {
				const YFilesLayout = module.default;
				for (let key in YFilesLayout.LAYOUTS) {
					if (this.availableLayouts.indexOf(key) > -1) {
						this.layoutSelectEl.append(
							$("<option value='"+ key +"'/>")
								.text(YFilesLayout.LAYOUTS[key].name)
								.prop("selected", key === this.layoutName)
						);
					}
				}
			});
		}

		this.paperSizeSelectEl = $('<select/>')
			.addClass("col form-select")
			.on('change', function() {
				self.pdfExportPaperSize = $(this).val(); 
			});

		const paperSizes = ['A3', 'A4', 'A5', 'A6', 'Letter'];

		for (let key of paperSizes) {
			this.paperSizeSelectEl.append(
				$("<option value='"+ key +"'/>")
					.text(key.toUpperCase())
					.prop("selected", key === "A4")
			);
		}

		this.pageOrientationSelectEl = $('<select/>')
			.addClass("col form-select")
			.on('change', function() {
				self.pdfExportPageOrientation = $(this).val(); 
			})
			.append("<option value='l'>Landscape</option>")
			.append("<option value='p'>Portrait</option>");

		this.pdfExportPaperSizeEl = $('<div>')
			.addClass("row mb-2 d-flex align-items-center")
			.append('<label class="col">Paper Size</label>')
			.append(this.paperSizeSelectEl)

		this.pdfExportPageOrientationEl = $('<div>')
			.addClass("row mb-2 d-flex align-items-center")
			.append('<label class="col">Page Orientation</label>')
			.append(this.pageOrientationSelectEl)

		this.pdfExportMarginEl = $('<div>')
			.addClass("row mb-2 d-flex align-items-center")
			.append('<label class="col">Margin(mm)</label>')
			.append('<input class=" pdf-margin col form-control" type="number" value=5>')

		this.pdfExportFileNameEl = $('<div>')
			.addClass("row mb-2 d-flex align-items-center")
			.append('<label class="col">File name</label>')
			.append(`<input class="pdf-file-name col form-control" type="text" value="">`)

		this.pdfExportButtonsEl = $('<div>')
			.addClass("row")
			.append($('<button type="button" class="col me-1 btn btn-sm btn-primary" >Export To PDF</button>').on('click', (e) => self.onExportToPDF(e)))
			.append($('<button type="button" class="col ms-1 btn btn-sm btn-primary" >Export To PNG</button>').on('click', (e) => self.onExportToPNG(e)))

		this.pdfExportEl = $('<div class="container"></div>')
			.append(this.pdfExportPaperSizeEl)
			.append(this.pdfExportPageOrientationEl)
			.append(this.pdfExportMarginEl)
			.append(this.pdfExportFileNameEl)
			.append(this.pdfExportButtonsEl)

		this.overviewCheck = $("<input/>")
			.attr("type", "checkbox")
			.addClass("form-check-input me-2")
			.change(function () {
				self.requestUpdate({showOverview: $(this).is(":checked")});
				$(this).prop("checked", !$(this).is(':checked')); // wait for Model to return with the changes
			});

		this.filtersEl = this.createFilters();

		const toolbarButtonGroup = $("<div/>")
			.addClass("d-flex")

		toolbarButtonGroup.append(
			// Layout select
			this.layoutSelectEl,

			// Zoom to fit button
			$("<button/>")
				.attr("title", "Zoom to fit")
				.attr("data-bs-toggle", "button")
				.css({
					"display": this.model.canSwitchZoomToFitStatus ? "inline-block" : "none"
				})
				.addClass("yfiles-view-zoom-to-fit radio")
				.addClass("btn btn-light ms-1")
				.append("<span class=\"fa fa-expand-arrows-alt\"></span>")
				.click(() => this.onZoomToFitChanged(! this.graph.zoomToFitStatus)),

			// Autocomplete toggle
			$("<button/>")
				.attr("title", "Auto complete")
				.attr("data-bs-toggle", "button")
				.addClass("yfiles-view-autocomplete radio")
				.addClass("btn btn-light ms-1")
				.append("<span class=\"fa fa-asterisk\"></span>")
				.css({
					"display": this.model.canSwitchAutoCompleteStatus ? "inline-block" : "none",
				})
				.click(() => self.toggleAutoComplete()),

			// Filters button
			$("<button/>")
				.attr("title", "Filters")
				.attr("data-bs-toggle", "popover")
				.attr("data-bs-placement", "bottom")
				.attr("data-bs-content-id", "filtersEl")
				.attr("data-bs-offset", "-153, 8")
				.addClass("yfiles-view-filters-toggle")
				.addClass("btn btn-light ms-1")
				.append("<span class=\"fa fa-filter\"/>", {
					class: "d-flex justify-content-center"
				})
				.css("display", this.model.canSetFilters ? "inline-block" : "none"),

			// Styles button
			$("<button/>")
				.attr("title", "Style")
				.css({
					"display": this.model.canSetStyles ? "inline-block" : "none"
				})
				.addClass("yfiles-view-style-manager")
				.addClass("btn btn-light ms-1")
				.append("<span class=\"fa fa-paint-brush\"></span>")
				.click(function() { self.openStylesWindow() }),

			// Selection mode toggle button
			$("<button/>")
				.attr("title", "Toggle Selection Mode")
				.attr("data-bs-toggle", "button")
				.addClass("yfiles-view-toggle-selection")
				.addClass("btn btn-light ms-1")
				.append(`<span class=\"fa fa-vector-square marquee\" style='display: ${this._selectionMode === 0 ? 'inline-block' : 'none'}'></span>`)
				.append(`<span class=\"fa fa-object-ungroup lasso\" style='display: ${this._selectionMode === 1 ? 'inline-block' : 'none'}'></span>`)
				.css({
					"display": this.model.canSwitchSelectionStatus ? "inline-block" : "none",
				})
				.click(() => self.toggleSelectionMode()),

			// Export button
			$('<button/>')
				.attr("title", "Export")
				.attr("data-bs-toggle", "popover")
				.attr("data-bs-placement", "bottom")
				.attr("data-bs-content-id", "pdfExportEl")
				.attr("close-click-outside", "true")
				.attr("tabindex", "-1")
				.addClass("btn btn-light ms-1")
				.css({
					"display": this.model.canExportPDF ? "inline-block" : "none"
				})
				.append(`<span class=\"fa fa-file-export\"></span>`),

			// Overview toggle
			$("<div/>")
				.addClass("input-group-text ms-1")
				.append(
					this.overviewCheck,
					"Overview"
				)
				.css({
					display: this.model.canSwitchOverviewStatus ? "inline-block" : "none",
				}),

		);

		toolbarEl.append(toolbarButtonGroup);

		if (this.layoutSelectEl) {
			this.layoutSelectEl
				.css("display", this.model.canSetLayout ? "inline-block" : "none");
		}

		toolbarEl.addClass("yfiles-view-toolbar");

		if (this.model.toolbarVisible) toolbarEl.show();

		return toolbarEl;
	}

	setButtonActiveStatus(status, selector){
		let $button = this.wrapperEl.find(`.${selector}`);
		if (!$button.length){
			return;
		}

		$button.removeClass("active");

		if (status){
			$button.addClass("active");
		}
	}

	/**
	 * Open an instance of NetworkStylesView
	 */
	openStylesWindow() {
		this.factory.executeFunction({
			_functionName: 'NetworkStylesView',
			'$container.height': '95%',
			'$container.width': '50%',
			'$container.id' : 'myStyling',
			'name': 'My styles'
		});
	}

	/**
	 * Toggle the autoCompleteStatus in the model (which will then be propagated to the renderer).
	 */
	toggleAutoComplete() {
		this._autoCompleteStatus = !this._autoCompleteStatus;
		this.requestUpdate({ autoCompleteStatus: this._autoCompleteStatus });
	}

	/**
	 * Change to the next selection mode (marquee/lasso).
	 */
	toggleSelectionMode(){
		this._selectionMode = this._selectionMode === (this._selectionModes.length - 1) ? 0 : this._selectionMode + 1;

		// Set button icon
		const $button = $('.yfiles-view-toggle-selection');
		if (!$button.length) return;
		for (let i = 0; i < this._selectionModes.length; i++){
			$button.children(`.${this._selectionModes[i]}`).css('display', i === this._selectionMode ? 'inline-block' : 'none');
		}

		// Change selection
		if (!this.graph) return;
		this.graph.setSelectionMode(this._selectionModes[this._selectionMode]);
	}

	/**
	 * Handle the event in which the user clicks the "Export to PDF" button.
	 * @param {MouseEvent} e
	 */
	onExportToPDF(e){
		let margin = this.pdfExportMarginEl.find('.pdf-margin');
		let marginVal = parseInt(margin[0].value);
		if (_.isNaN(marginVal)) marginVal = undefined;
		let fileNameInput = this.pdfExportFileNameEl.find('.pdf-file-name');
		let fileName = _.size(fileNameInput[0].value) ? fileNameInput[0].value : this.model.exportedPdfFileName;
		this.graph.exportToPdf(this.pdfExportPaperSize, fileName, this.pdfExportPageOrientation, marginVal);
		e.stopPropagation();
	}

	onExportToPNG(e){
		let margin = this.pdfExportMarginEl.find('.pdf-margin');
		let marginVal = parseInt(margin[0].value);
		if (_.isNaN(marginVal)) marginVal = undefined;
		let fileNameInput = this.pdfExportFileNameEl.find('.pdf-file-name');
		let fileName = _.size(fileNameInput[0].value) ? fileNameInput[0].value : this.model.exportedPNGFileName;
		this.graph.exportToPNG(fileName, marginVal);
		e.stopPropagation();
	}

	/** @override */
	createContextMenus(contextMenus) {
		ViewRenderer.prototype.createContextMenus.call(this, contextMenus);

		this.addContextMenuItem(
			"canvas",
			100,
			this.language.translate("Remove selected nodes from view"),
			() => {
				let nodes = this.graph.getSelectedNodes();

				this.requestUpdate({
					"_update.remove": {
						// Remove the connected edges to these nodes also
						relations: this.graph.getConnectedEdges(nodes),
						nodes,
					}
				});
			}
		);

		this.addContextMenuItem(
			"canvas",
			200,
			this.language.translate("Insert selected nodes"),
			() => {
				const graphs = this.registry.get(GraphRegistry);

				let nodes = [];
				_.forEach(graphs, graph => {
					if(graph !== this) {
						nodes = nodes.concat(graph.getSelectedNodes());
					}
				});
				nodes = _.map(nodes, node => ({...node, fixed: false})); // trigger layout

				this.requestUpdate({
					"_update.add": { nodes }
				});
			}
		);

		this.addContextMenuItem(
			"node",
			100,
			this.language.translate("Remove from view"),
			node => {
				this.requestUpdate({
					"_update.remove": {
						nodes: [node],
						// Remove the connected edges to this node also
						relations: this.graph.getConnectedEdges(node)
					}
				});
			}
		);
		this.addContextMenuItem(
			"relation",
			100,
			this.language.translate("Remove from view"),
			relation => {
				this.requestUpdate({
					"_update.remove": {
						relations: [relation]
					}
				});
			}
		);
	}
}
YFilesViewRenderer.viewType = 'YFilesView';

export default YFilesViewRenderer;
