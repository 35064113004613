require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/resizable');
require('jquery-ui/themes/base/resizable.css');

require('./jquery-helpers');

// necessary for css ordering (so other css can overwrite it instead of the other way around):
require('jqwidgets/styles/jqx.base.css');
require('jqwidgets/styles/jqx.bootstrap.css');
require('smart-webcomponents/source/styles/smart.default.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/css/v4-shims.min.css');
require('bootstrap/dist/css/bootstrap.css');
require('bootstrap-icons/font/bootstrap-icons.css');

require('./favicon.png');
require('./admin.css');

const GraphileonClient = require('client/src/client').default;
const panelContainer = require('./containers/container-content');
const modalContainer = require('./containers/container-modal').default;
const Log = require('core/src/log');

const log = Log.instance('client');

const client = new GraphileonClient({
	router: {
		enabled: true,
		rootToDefaultDashboard: true,
		baseRoute: '/dashboard'
	}
});
client.viewmanager.register(require('./user-view/user-view-renderer'));
client.factory.register(require('./user-view/user-view'));

$(document).ready(function() {
	const viewmanager = client.viewmanager;
	viewmanager.promptArea = 'content';
	viewmanager.defaultArea = 'content';
	viewmanager.setContainerPrototype('content', panelContainer);
	viewmanager.setContainerPrototype('modal', modalContainer);

	client.on(GraphileonClient.Event.ERROR_CONFIGURATION, function() {
		window.location = 'settings/index.html';
	});
	client.on(GraphileonClient.Event.SESSION_EXPIRED, () => {
		client.session.logOffUser();
		location.reload();
	});
	client.on(GraphileonClient.Event.AUTOLOGIN_FAILED, () => {
		client.executeLoginView('sidebar-left', 'login');
	});
	client.on(GraphileonClient.Event.LOGIN_REQUIRED, () => {
		client.closeDashboard();
		client.executeLoginView('sidebar-left', 'login');
	});
	client.on(GraphileonClient.Event.ROUTE_UNRESOLVED, () => {
		log.log('No route matched. Loading default dashboard.');
		client.loadDefaultDashboard();
	});
	client.on(GraphileonClient.Event.DASHBOARD_LOADED, () => {
		if(!client.session.isLoggedIn()) {
			return;
		}

		const user = client.session.getUser();
		let func = client.factory.createFunctionInstance("UserView");
		func.execute({
			area: 'sidebar-left',
			container: {
				id: 'login',
				index: -99,
				title: user.properties.name,
				headerVisible: false
			},
			user: user,
			dashboards: client.appInfo.dashboards,
			shortcuts: client.appInfo.shortcuts
		});
	});
	client.on(GraphileonClient.Event.LOADING_STARTED, () => {
		$('.spinner').show();
	});
	client.on(GraphileonClient.Event.LOADING_FINISHED, () => {
		$('.spinner').hide();
	})

	client.on(GraphileonClient.Event.LOGOUT, () => {location.reload()});

	client.start();
});
window.graphileonClient = client;
