var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "markdown-edit-view graphileon-viewrenderer-output" },
    [
      _c("div", { staticClass: "text-editor" }),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.markdown,
            expression: "markdown",
          },
        ],
        ref: "textarea",
        attrs: { id: _vm.textareaId, rows: "10" },
        domProps: { value: _vm.markdown },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.markdown = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }