
	import VueContainerBase from 'client/src/view-container/ContainerVueComponentBase.vue';
	import * as _ from 'lodash';

	export default {
		mixins: [VueContainerBase],
		data() {
			return {
				config: {
					x: undefined,
					y: undefined,
					background: 'rgba(0,0,0, 0.3)',
					popupBackground: undefined,
					closeButton: true,
					closeOnClick: false,
					closeOnNoHover: false,
					headerVisible: true
				},
				hovering: false
			}
		},
		computed: {
			fixedPosition() {
				return !_.isNil(this.config.x) || !_.isNil(this.config.y);
			},
			validWidth() {
				return this.validDimension(this.width);
			},
			validHeight() {
				return this.validDimension(this.height);
			},
			hasCloseButton() {
				return this.closable && this.config.closeButton;
			},
			backgroundStyle() {
				return {
					background: this.config.background,
					'pointer-events': !_.isNil(this.config.background) ? 'auto' : 'none',
					position: 'absolute',
					display: this.fixedPosition ? 'initial' : 'flex'
				}
			},
			containerStyle() {
				return {
					position: this.fixedPosition ? 'absolute' : 'auto',
					top: this.validDimension(this.config.y),
					left: this.validDimension(this.config.x),
					width: this.validWidth,
					height: this.validHeight,
					display: 'flex',
					flexDirection: 'column',
					background: this.config.popupBackground
				}
			},
			showHeader() {
				if (!this.config.headerVisible) {
					return false;
				}
				return (! _.isNil(this.title) || this.hasCloseButton)
			}
		},
		methods: {
			validDimension(dimension, defaultValue) {
				if(_.isString(dimension) && dimension.length > 0) return dimension;
				if(_.isNumber(dimension)) return dimension + 'px';
				return defaultValue;
			},
			onBackgroundClick(event) {
				if(event.target !== this.$refs.background) return;
				if(!this.closable) return;

				// Delay the closing to prevent clicking something under by mistake when double clicking
				setTimeout(() => this.close(), 500);
			},
			onContainerClick(event) {
				if (this.config.closeOnClick) {
					this.close();
				}
			},
			onMouseEnter() {
				if (this.timeoutToClose) {
					clearTimeout(this.timeoutToClose);
					this.timeoutToClose = null;
				}
			},
			onMouseLeave() {
				if (! this.config.closeOnNoHover) {
					return;
				}
				this.timeoutToClose = setTimeout(()=> this.close(), 1000);
			}
		},
		mounted() {
			// set focus on an element in the template
			setTimeout(() => {
				let focusElements = this.$el.querySelector('[autofocus]')

				if (! focusElements) return;

				focusElements.focus();
			});
		}
	}
