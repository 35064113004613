const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const {isjQuery} = require('client/src/utils/jquery');
const Router = require('client/src/router').default;
const ViewRenderer = require('client/src/view-renderer');
const UserView = require('./user-view');
const ApplicationInfo = require('core/src/application-info').default;
const ViewManager = require('client/src/view-manager');
const ISession = require('core/src/i-session').default;
const Graphileon = require('core/src/i-graphileon').default;
const Icon = require('client/src/utils/icon').default;
const Edition = require("core/src/edition").default;

require('./user-view.css');

let viewHTML = require('./user-view.html');
//user view until v3.5.0
let viewHTML350 = require('./user-view-350.html');

/* Inheritance and constructor */

const UserViewRenderer = function (dependencies) {
	ViewRenderer.call(this, dependencies);

	this.graphileon = dependencies.get(Graphileon);
	this.router = dependencies.get(Router);
	this.session = dependencies.get(ISession);
	this.viewmanager = dependencies.get(ViewManager);
	this.appInfo = dependencies.get(ApplicationInfo);
};
UserViewRenderer.viewType = 'UserView';
UserViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* METHODS */

/* Protected */

/**
 * Creates a button for the given shortcut
 * @param {object} shortcut
 * @param {string} section
 * @returns {*}
 * @private
 */
UserViewRenderer.prototype._renderShortcut = function (shortcut, section) {
	var self = this;

	shortcut = _.isObject(shortcut) ? shortcut : {};
	var check = _.validate({
		id: [shortcut.id, 'isStringOrNumber'],
		group: [shortcut.group, "isString", {default: "default", warn: shortcut.group !== undefined}],
		name: [shortcut.name, "isString"],
		icon: [shortcut.icon, _.isString(shortcut.icon) || shortcut.icon === undefined, "If defined, must be string.",
			{default: undefined, warn: shortcut.icon !== undefined}],
		style: [shortcut.style, "isString", {default: '', warn: shortcut.style !== undefined}],
		hoverStyle: [shortcut.hoverStyle, "isString", {default: '', warn: shortcut.hoverStyle !== undefined}],
		section: [section, 'isString']
	}, "Could not render shortcut.");
	if (!check.isValid()) return $('');
	var valid = check.getValue();

	var $button = $('<button>')
		.addClass('shortcut')
		.addClass('btn btn-secondary btn-sm m-1 ms-0 border-0');

	$button.attr('data-shortcut-name', valid.name);
	$button.attr('style', valid.style);
	$button.html(valid.name);
	$button.on('click', function () {
		self.triggerShortcut(valid.section, valid.id);
	});
	$button.hover(function () {
		$button.attr('style', valid.hoverStyle);
	}, function () {
		$button.attr('style', valid.style);
	});

	if (valid.icon !== undefined) {
		this._addIcon($button, valid.icon);
	}
	return $button;
};

UserViewRenderer.prototype._renderDashboardSelectorOptions = function (dashboardSelector, dashboards) {
	let check = _.validate({
		dashboardSelector: [dashboardSelector, isjQuery, "Must be jQuery element."],
		dashboards: [dashboards, 'isArray']
	}, "Could not render dashboard selector.");
	if (!check.isValid()) return;
	const valid = check.getValue();

	dashboardSelector.empty();

	_.forEach(valid.dashboards, (dashboard, i) => {
		let name = _.get(dashboard, 'properties.name', 'Dashboard' + dashboard.id);
		name = this.graphileon.translate(name); // translate if possible
		name = _.truncate(name, {length: 25});

		let $option = $('<option>');
		$option.val(i);
		$option.html(name);
		if (this.appInfo.dashboard && "" + this.appInfo.dashboard.id === "" + dashboard.id) {
			$option.attr('selected', true);
		}
		dashboardSelector.append($option);
	});
};

/* Public */

/**
 * Triggers a shortcut.
 * @param {int} id
 * @returns {jQuery.Deferred}
 */
UserViewRenderer.prototype.triggerShortcut = function (section, id) {
	this.event(UserView.Event.In.SHORTCUT, {
		id: id,
		section: section
	});
};

/**
 * Renders a group of shortcuts to the given element.
 */
UserViewRenderer.prototype.renderShortcuts = function (shortcuts, section, element) {
	var self = this;
	var check = _.validate({
		shortcuts: [shortcuts, 'isArray'],
		section: [section, 'isString'],
		element: [element, isjQuery, "Invalid jQuery element."]
	});
	if (!check.isValid()) return check.createError();
	var valid = check.getValue();

	_.forEach(valid.shortcuts, function (shortcut) {
		var renderedShortcut = self._renderShortcut(shortcut, valid.section);
		valid.element.append(renderedShortcut);
	});
	return true;
};

UserViewRenderer.prototype.setDashboard = function(dashboard) {
	const personal = _.get(dashboard, 'properties.personalShortcutsStatus')
	const team = _.get(dashboard, 'properties.teamShortcutsStatus')
	this.toggleShortcutsStatus('user', personal);
	this.toggleShortcutsStatus('team', team);

	if (personal === 'hidden' && team === 'hidden') {
		const $actions = this._$element.find(`.shortcut-actions`);
		$actions.toggleClass('d-none');
	}
};

UserViewRenderer.prototype.selectDashboard = function(dashboard) {
	let dashboardName = _.get(dashboard, 'properties.iaName');
	if (!dashboardName) {
		dashboardName = dashboard.id;
	}
	this.router.goToDashboard(dashboardName);
};

UserViewRenderer.prototype.toggleShortcutsStatus = function(section, status) {
	const $section = this._$element.find(`.shortcuts-${section}`);
	const $toggle = this._$element.find(`.shortcuts-toggle.${section}`);

	if($section.hasClass('d-none')) {
		// No point having a toggle if there's nothing to toggle
		status = 'hidden';
	}
	if(_.isNil(status)) {
		// Select next status based on current visibility
		status = $section.is(':visible') ? 'collapsed' : 'expanded';
	}
	$section.toggleClass('collapsed', status !== 'expanded');
	$toggle.toggleClass('collapsed', status !== 'expanded');
	$toggle.toggle(status !== 'hidden');
};

UserViewRenderer.prototype.doRender = function (renderData) {
	var self = this;

	const user = renderData.user;
	const settingsAllowed = _.get(user, 'admin') === true
		|| _.includes(_.get(user, 'permissions'), 'root')
		|| _.includes(_.get(user, 'permissions'), 'settings');


	// use v3.5.0 UserView
	let compatibility350 = ! (this.appInfo.userView.infoButton || this.appInfo.userView.userButton);
	let html = this._applyTemplateTranslations(
			compatibility350
		? viewHTML350
		: viewHTML  
	);

	// Load template
	var $div = $(html).addClass('UserView');
	self._$element = $div;

	if (! compatibility350) {
		// remove title
		$("#login").find("h2.title").remove();
	}
	else {
		var $logoff = self._$element.find('a.log-off');
		$logoff.click(() => {
			// Clear anything after the hash
			history.pushState(null, null, window.location.href.split('#')[0]);
			this.session.logOffUser();
		});

		var $settings = self._$element.find('a.settings');
		if (settingsAllowed) {
			$settings.click(function () {
				window.location = 'settings/index.html';
			});
			$settings.removeClass('hide');
		}

		var $licenseCheck = self._$element.find('a.licensecheck');
		if (settingsAllowed) {
			$licenseCheck.click(() => {
				this.graphileon.api.checkLicense()
					.done(result => {
						this.viewmanager.alert(result.message, {
							headerText: result.title,
							width: '500px',
							modalSize: 'modal-md'
						});
					})
					.fail(error => {
						console.error(error);
					});
			});
			$licenseCheck.removeClass('hide');
		}
	}


	const infoIcon = self._$element.find('.info-icon');
	const accountIcon = self._$element.find('.account-icon');
	const accountName = self._$element.find('.account-name');
	accountName.html(`${user.properties.name.slice(0, 1)}`);

	infoIcon.click(()=>{
		const userView = document.getElementById('login').getBoundingClientRect();
		this.viewmanager.fire('userViewInfoButton', {
			parentPosition: {
				left: userView.left,
				top: userView.top
			}
		});
	});

	accountIcon.click(()=>{
		const userView = document.getElementById('login').getBoundingClientRect();
		this.viewmanager.fire('userViewAccountButton', {
			parentPosition: {
				left: userView.left,
				top: userView.top
			}
		});
	});

	const languages = this.graphileon.getLanguages();
	if (_.keys(languages).length > 1 && Edition.hasFeature(Edition.Feature.MULTI_LINGUAL)) {
		 let languagesElem = self._$element.find('.languages');
		 let languagesMenu = self._$element.find('.languages-menu');

		 _.forEach(
		 	languages, 
		 	(val, key) => languagesMenu.append(`<li><a type="button" class="change-language dropdown-item" data-lang="${key}">${val}</a></li>`)
		 );

		 languagesElem.removeClass('d-none');
		 const $languageButtons = self._$element.find('a.change-language');
		 $languageButtons.click(function(){
		 	self.graphileon.changeLanguage($(this).data('lang'));
		 });
	}


	var dashboardArea = self._$element.find('.dashboards');
	var dashboardSelector = self._$element.find('.dashboard-selector');
	if (renderData.dashboards === undefined || renderData.dashboards.length <= 1) {
		dashboardArea.addClass('d-none');
	} else {
		self._renderDashboardSelectorOptions(dashboardSelector, renderData.dashboards);
	}
	dashboardSelector.on('change', () => {
		let idx = dashboardSelector.val();
		let dashboard = renderData.dashboards[idx];

		self.selectDashboard(dashboard);
	});

	_.forEach(renderData.shortcuts, function (shortcuts, section) {
		if (shortcuts.length > 0) {
			var div = self._$element.find('.shortcuts-' + section);
			div.removeClass('d-none');
			self.renderShortcuts(shortcuts, section, div);
		}
	});

	self._$element.find('.shortcut-actions > .user').click(() => {
		self.toggleShortcutsStatus('user');
	});
	self._$element.find('.shortcut-actions > .team').click(() => {
		self.toggleShortcutsStatus('team');
	});

	// Get full dashboard node (this.appInfo.dashboard only contains simplified data)
	const dashboard = _.find(this.appInfo.dashboards, {
		id: _.get(this.appInfo.dashboard, 'id')
	});
	this.setDashboard(dashboard);

	return $div;
};

UserViewRenderer.prototype._addIcon = function ($button, icon) {
	var $icon = $('<i>')
		.addClass(Icon.getClass(icon))
		.addClass('me-2');
	$button.prepend($icon);
};

UserViewRenderer.prototype._applyTemplateTranslations = function (template) {
	const regex = /translate\('([^']+)'\)/g;
	return template.replaceAll(regex, (fullMatch, group, index) => this.graphileon.translate(group));
};

module.exports = UserViewRenderer;
