var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "self", staticClass: "upload-field-container flexbox w-100" },
    [
      _vm.uploading
        ? _c(
            "div",
            {
              staticClass: "btn btn-danger",
              staticStyle: { "margin-right": "5px" },
              attrs: { title: "Stop the upload" },
              on: { click: _vm.stopUpload },
            },
            [_c("i", { staticClass: "fa fa-spinner" })]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.uploading
        ? _c("label", [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              ref: "upload",
              staticClass: "invisible",
              attrs: { type: "file" },
              on: { change: _vm.onFilesChange },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "visible",
        staticClass: "form-control flex",
        attrs: { type: "text", disabled: !_vm.schema.editable },
        domProps: { value: _vm.value },
        on: {
          change: _vm.onVisibleChange,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "btn btn-primary", attrs: { title: "Upload a file" } },
      [_c("i", { staticClass: "fa fa-upload" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }