const _ = require('core/src/utils/legacy');
const { ensureIsJQuery, isValidJQuerySelection } = require('client/src/utils/jquery');
const GraphSelector = require('core/src/graph/graph-selector');
const GraphStyles = require('core/src/graph/graph-styles').default;
const CodeMirror = require('client/libraries/code-mirror-interactor');
const addHooksTrait = require('core/src/hooks');
const D3Graph = require('client/src/graph/d3-graph');
const CodeEvaluator = require('core/src/code-evaluator');

var PREVENT_ERROR = true;

var STYLES_MANAGER_LAYOUT =
	'<div style="height: 100%; width: 100%" class="tab-content">' +
	'	<div class="style-list-manager">' +
	'		<label action="list-filter" style="display: none">' +
	'			<input type="checkbox" name="list-filtered"/>' +
	'			Filtered' +
	'		</label>' +
	'		<div class="style-list-n-buttons d-flex">' +
	'			<div class="style-list-container w-100">' +
	'				<div class="style-list"></div>' +
	'			</div>' +
	'			<div class="list-buttons ms-1">' +
	'			   	<button class="action-style-add btn btn-success mb-1 d-flex justify-content-center align-items-center"><i class="fa fa-plus"></i></button>' +
	'			   	<button class="action-style-remove btn btn-danger mb-1 d-flex justify-content-center align-items-center"><i class="fa fa-remove"></i></button>' +
	'			   	<button class="action-style-up btn btn-light mb-1 d-flex justify-content-center align-items-center"><i class="fa fa-arrow-up"></i></button>' +
	'			   	<button class="action-style-down btn btn-light d-flex justify-content-center align-items-center"><i class="fa fa-arrow-down"></i></button>' +
	'	  		</div>' +
	'		</div>' +
	'	</div>' +
	'	<div class="style-preview-container">' +
	'	</div>' +
	'	<div class="style-editor-container">' +
	'		<div class="mb-2">' +
	'			<label class="main-selector"><%= mainSelector %></label>' +
	'			<div class="d-flex">' +
	'				<input class="form-control form-control-sm type="text" name="style-selector">' +
	'   			<button class="action-apply btn btn-sm btn-success ms-1 d-inline-flex align-items-center"><i class="fa fa-check me-1"></i> Apply</button>' +
	'   			<button class="action-save btn btn-sm btn-success ms-1 d-inline-flex align-items-center"><i class="fa fa-edit me-1"></i> Save</button>' +
	'			</div>' +
	'		</div>' +
	'		<textarea id="code-editor-<%= id %>" class="style-editor code-editor"></textarea>' +
	'	</div>' +
	'</div>';

var STYLES_MANAGER_LAYOUT_TEMPLATE = _.template(STYLES_MANAGER_LAYOUT);

var NETWORK_STYLES_MANAGER_LAYOUT =
	'<div class="tabs network-styles-manager h-100">' +
	'	<ul class="nav nav-tabs border-bottom-0">' +
	'		<li class="tab main-tab nav-item border border-bottom-0 rounded-top ms-1 me-2"><a class="nav-link" href="#tab-list">List</a></li>' +
	'		<li class="tab main-tab nav-item border border-bottom-0 rounded-top"><a class="nav-link" href="#tab-edit">Edit</a></li>' +
	'	</ul>' +
	'	<div id="tab-list" class="border rounded">' +
	'		<div class="tabs h-100 p-4">' +
	'			<ul class="nav nav-tabs border-bottom-0">' +
	'				<li class="tab nav-item border border-bottom-0 rounded-top ms-1 me-2"><a class="nav-link" href="#tab-fenotypes">Computed</a></li>' +
	'				<li class="tab nav-item border border-bottom-0 rounded-top"><a class="nav-link" href="#tab-genotypes">Used</a></li>' +
	'			</ul>' +
	'			<div id="tab-fenotypes" class="border rounded">' +
	'				<div class="legend h-100 text-center">' +
	'					<div class="legend-nodes h-50">' +
	'   				 	<h2>Nodes</h2>' +
	'						<div class="node-fenotypes-container"></div>' +
	'					</div>' +
	'					<div class="legend-relations h-50">' +
	'   				 	<h2>Relationships</h2>' +
	'				  	  	<div class="relation-fenotypes-container"></div>' +
	'					</div>' +
	'				</div>' +
	'   		</div>' +
	'			<div id="tab-genotypes" class="border rounded">' +
	'				<div class="legend h-100 text-center">' +
	'					<div class="legend-nodes h-50">' +
	'   				 	<h2>Nodes</h2>' +
	'						<div class="node-genotypes-container"></div>' +
	'					</div>' +
	'					<div class="legend-relations h-50">' +
	'   				 	<h2>Relationships</h2>' +
	'				  	  	<div class="relation-genotypes-container"></div>' +
	'					</div>' +
	'				</div>' +
	'			 </div>' +
	'		</div>' +
	'	</div>' +
	'	<div id="tab-edit" class="border rounded">' +
	'		<div class="tabs h-100 p-4">' +
	'			<ul class="nav nav-tabs border-bottom-0">' +
	'				<li class="tab nav-item border border-bottom-0 rounded-top ms-1 me-2"><a class="nav-link" href="#tab-nodes">Nodes</a></li>' +
	'				<li class="tab nav-item border border-bottom-0 rounded-top"><a class="nav-link" href="#tab-relationships">Relationships</a></li>' +
	'			</ul>' +
	'			<div id="tab-nodes" class="border rounded p-4"></div>' +
	'			<div id="tab-relationships" class="border rounded p-4"></div>' +
	'		</div>' +
	'	</div>' +
	'</div>';


const StylesManager = function(dependencies, settings) {
	const objectProperties = {
		container: null,
		selectorsList: null,
		previewGraph: null,
		selectorInput: null,
		styleEditor: null,
		ok: false,
		stylesList: [],
		selectors: [], //visible list
		filteredList: null,
		isFiltered: false
	};

	this.settings = {
		container: null,
		mainSelector: null,
		styleableGraphs: [],
	};

	this.dependencies = dependencies;

	this.settings = _.extend(this.settings, settings);

	this.styles = this.settings.styles;

	_.extend(this, objectProperties);

	this.init();
};

StylesManager.prototype.init = function() {
	this.layout = this.initLayout(this.settings.container, this.settings.mainSelector);
	if ( !this.layout) {
		return false;
	}
	this.setChanged(false);

	this.selectorsList = this.createList(this.getListElement(), this.selectors);
	if ( !this.selectorsList) {
		return false;
	}

	this.previewGraph = this.createPreviewGraph(this.getPreviewGraphContainer());
	if ( !this.previewGraph.isOk()) {
		return _.withError('StylesManager.init(): graph preview did not initialize properly');
	}

	this.setEvents();

	this.setUpHooks(this.settings.hooks);

	this.ok = true;
};

StylesManager.prototype.isOk = function() {
	return this.ok;
};

StylesManager.prototype.initLayout = function(container, mainSelector) {
	container = ensureIsJQuery(container);

	if ( !container || !container.length) {
		return _.withError(['StylesManager.initLayout(): container is not valid', container]);
	}

	var layout = $(STYLES_MANAGER_LAYOUT_TEMPLATE({
		id: _.uniqueId(),
		mainSelector: mainSelector
	}));

	container.append(layout);

	var self = this;

	// Code editor
	setTimeout( function() {
		// Code editor
		container.find('.code-editor').each(function(element) {
			var id = $(this).attr('id');

			var editor = CodeMirror.fromTextArea(this, {
				mode: 'application/json',
				indentWithTabs: true,
				indentUnit: 4,
				smartIndent: true,
				matchBrackets: true,
				autoCloseBrackets: true,
				autofocus: false,
				theme: 'neo',
				viewportMargin: Infinity,
				lineWrapping: true,
				lineNumbers: true
			});
			editor.on('keyup', function() {
				self.executeHook('onChange');
			});

			self.styleEditor = editor;

			if (self['lastEditedStyle']) {
				self.setEditedStyle(self.lastEditedStyle);
			}
		});
	});

	return layout;
};

StylesManager.prototype.getLayout = function() {
	if (! this.layout) {
		return _.withError(['StylesManager.getLayout(): layout not initialized']);
	}
	return this.layout;
};

StylesManager.prototype.getListElement = function() {
	if (! this.selectorsListElement) {
		if (!this.getLayout()) {
			return false;
		}
		this.selectorsListElement = this.getLayout().find('.style-list');
	}

	if (!this.selectorsListElement.length) {
		return _.withError(['StylesManager.getListElement(): selectorsListElement could not be found in layout']);
	}

	return this.selectorsListElement;
};

StylesManager.prototype.createList = function(listElement, listSource) {
	listElement =ensureIsJQuery(listElement);

	if (!listElement || !listElement.length) {
		return _.withError(['StylesManager.initList(): table element invalid', listElement]);
	}

	var list = $(listElement);

	list.jqxListBox({
		source: listSource,
		width: '100%',
		height: '100%'
	});

	return list;
};

StylesManager.prototype.getList = function() {
	if (!this.selectorsList) {
		return _.withError('StylesManager.getList(): selectorsList is not initialized');
	}

	return this.selectorsList;
};

StylesManager.prototype.addSelector = function(selectorString) {
	if (!this.getList()) {
		return _.withError('StylesManager.addSelector(): list not initialized');
	}

	this.stylesList.push(selectorString);

	return this.getList().jqxListBox('addItem', selectorString);
};

StylesManager.prototype.insertSelector = function(selectorString, index) {
	if (!this.getList()) {
		return _.withError('StylesManager.insertSelector(): list not initialized');
	}

	if ( ! (index > -1)) {
		return _.withError(['StylesManager.insertSelector: index is not valid', index]);
	}

	return this.getList().jqxListBox('insertAt', selectorString, index);
};

StylesManager.prototype.deleteSelector = function(selectorString) {
	if (!this.getList()) {
		return _.withError('StylesManager.deleteSelector(): list not initialized');
	}

	return this.getList().jqxListBox('removeItem', selectorString);
};

StylesManager.prototype.setCurrentSelector = function(selectorString) {
	if (!this.getList()) {
		return _.withError('StylesManager.setCurrentSelector(): list not initialized');
	}

	if(!_.isString(selectorString)) {
		this.getList().jqxListBox('ensureVisible', 0);
		return this.getList().jqxListBox('selectIndex', 0);
	}

	var item = this.getList().jqxListBox('getItemByValue', selectorString);

	if (!item) {
		return _.withError(['StylesManager.setCurrentSelector: selector not found in list', selectorString]);
	}

	return this.getList().jqxListBox('selectItem', item);
};

StylesManager.prototype.getCurrentSelector = function() {
	var stylesList = this.getList();
	if ( ! stylesList) {
		return _.withError(['StylesManager.getCurrentSelector: styles list not initialized']);
	}

	var selectedSelectorItem = stylesList.jqxListBox('getSelectedItem');

	if ( ! selectedSelectorItem) {
		return _.withError('StylesManager.getCurrentSelector: selected item in jqxListBox not found');
	}

	return selectedSelectorItem.value;
};

StylesManager.prototype.clearCurrentSelector = function() {
	if (!this.getList()) {
		return _.withError('StylesManager.clearCurrentSelector(): list not initialized');
	}

	return this.getList().jqxListBox('clearSelection');
};

StylesManager.prototype.getAllSelectors = function() {
	if (!this.getList()) {
		return _.withError('StylesManager.getAllSelectors(): list not initialized');
	}

	var selectorItems = this.getList().jqxListBox('getItems');

	return _.map(selectorItems, 'value');
};

StylesManager.prototype.getPreviewGraphContainer = function() {
	if (!this.getLayout()) {
		return false;
	}

	return this.getLayout().find('.style-preview-container');
};

StylesManager.prototype.setChanged = function(changed) {
	let saveButton = this.getLayout().find('.action-save');

	if(changed) {
		saveButton.removeClass('btn-success');
		saveButton.addClass('btn-warning');
	} else {
		saveButton.removeClass('btn-warning');
		saveButton.addClass('btn-success');
	}
};

StylesManager.prototype.createPreviewGraph = function(container) {
	container =ensureIsJQuery(container);

	if (!isValidJQuerySelection(container)) {
		return _.withError(['StylesManager.createPreviewGraph(): container is not valid', container]);
	}

	var previewGraph = new D3Graph({
		container: container,
		autoLayout: false,
		autoZoomToFit: true,
		canZoom: false,
		maxZoomScale: 1,
		styles: this.styles,
		hooks: {
			onGraphResized: function() {
				this.centerGraph();
			}
		}
	});

	return previewGraph;
};

StylesManager.prototype.getPreviewGraph = function() {
	if (!this.previewGraph) {
		return _.withError('StylesManager.getPreviewGraph: preview graph is not initialized');
	}

	return this.previewGraph;
};

StylesManager.prototype.setEvents = function() {
	if (!this.getLayout()) {
		return false;
	}

	var layout = this.getLayout();
	var self = this;
	layout.on('click', '.action-style-add', function() {
		self.onAddStyle();
	});

	layout.on('click', '.action-style-remove', function() {
		self.onRemoveStyle();
	});

	layout.on('click', '.action-style-up', function() {
		self.onStyleUp();
	});

	layout.on('click', '.action-style-down', function() {
		self.onStyleDown();
	});

	layout.on('click', '.action-apply', function() {
		self.onApply();
	});

	layout.on('click', '.action-save', function() {
		self.onSave();
	});

	layout.on('change', 'input[name=list-filtered]', function() {
		self.onFilteredChanged($(this).is(':checked'));
	});

	var list = this.getListElement();

	if (!list) {
		return false;
	}

	list.on('select', function() {
		self.onSelectStyle();
	});
};

StylesManager.prototype.onAddStyle = function() {
	this.executeHook('onAddStyle');
};

StylesManager.prototype.onRemoveStyle = function() {
	var currentSelector = this.getCurrentSelector();

	if ( ! currentSelector){
		return false;
	}

	this.executeHook('onRemoveStyle', [currentSelector]);
};

StylesManager.prototype.onStyleUp = function() {
	var selectors = this.getAllSelectors();

	if ( ! selectors || _.isEmpty(selectors))  {
		return false;
	}

	var currentSelector = this.getCurrentSelector();

	if ( ! currentSelector)  {
		return false;
	}

	var indexOfCurrentSelector = _.indexOf(selectors, currentSelector);

	if ( ! (indexOfCurrentSelector > 0)) {
		return false;
	}

	selectors.splice(indexOfCurrentSelector, 1);
	selectors.splice(indexOfCurrentSelector - 1, 0, currentSelector);

	if (this.executeHook('onStyleUp', [selectors]) === false) {
		return false;
	}

	this.deleteSelector(currentSelector);
	this.insertSelector(currentSelector, indexOfCurrentSelector - 1);
	this.setCurrentSelector(currentSelector);
};

StylesManager.prototype.onStyleDown = function() {
	var selectors = this.getAllSelectors();

	if ( ! selectors || _.isEmpty(selectors))  {
		return false;
	}

	var currentSelector = this.getCurrentSelector();

	if ( ! currentSelector)  {
		return false;
	}

	var indexOfCurrentSelector = _.indexOf(selectors, currentSelector);

	if ( ! (indexOfCurrentSelector < (selectors.length - 1))) {
		return false;
	}

	selectors.splice(indexOfCurrentSelector, 1);
	selectors.splice(indexOfCurrentSelector + 1, 0, currentSelector);

	if (this.executeHook('onStyleDown', [selectors]) === false) {
		return false;
	}

	this.deleteSelector(currentSelector);
	this.insertSelector(currentSelector, indexOfCurrentSelector + 1);
	this.setCurrentSelector(currentSelector);
};

StylesManager.prototype.onApply = function() {
	this.executeHook('onApply');
};

StylesManager.prototype.onSave = function() {
	if (this.executeHook('onSave') == false) {
		return;
	}
	if (this.isFiltered) {
		return;
	}
};

StylesManager.prototype.onSelectStyle = function() {
	var currentSelector = this.getCurrentSelector();

	if (!currentSelector) {
		return false;
	}

	this.executeHook('onSelectStyle', [currentSelector]);
};

StylesManager.prototype.onFilteredChanged = function(isFiltered) {
	this.setFilteredState(isFiltered);
};

StylesManager.prototype.getSelectorInput = function() {
	if (! this.selectorInput) {
		if (! this.getLayout()) {
			return false;
		}

		var input = this.getLayout().find('input[name=style-selector]');
		if (!input.length) {
			return _.withError(['StylesManager.getSelectorInput(): could not find selector input in layout']);
		}

		this.selectorInput = input;
	}

	return this.selectorInput;
};

StylesManager.prototype.getStyleEditor = function(preventError) {
	if (! this.styleEditor) {
		if (preventError) {
			return false;
		}

		return _.withError(['StylesManager.getStyleInput(): style editor not initialized']);
	}

	return this.styleEditor;
};

StylesManager.prototype.getEditedSelector = function() {
	if (!this.getSelectorInput()) {
		return false;
	}

	return this.getSelectorInput().val();
};

StylesManager.prototype.setEditedSelector = function(selectorString) {
	if (!this.getSelectorInput()) {
		return false;
	}

	if ( ! _.isString(selectorString)) {
		return _.withError(['StylesManager.setEditedSelector: selector must be string', selectorString]);
	}

	this.getSelectorInput().val(selectorString);

	return true;
};

StylesManager.prototype.getEditedStyle = function() {
	if (! this.getStyleEditor()) {
		return false;
	}

	var styleString = this.getStyleEditor().getValue();
	var style = false;
	try {
		style = JSON.parse(styleString);
	}
	catch (exception) {
		alert('Edited style is not valid JSON!');
	}
	return style;
};

StylesManager.prototype.setEditedStyle = function(style) {

	if (! _.isString(style)) {
		style = JSON.stringify(style, null, '\t');
	}

	if (! this.getStyleEditor(PREVENT_ERROR)) {
		this.lastEditedStyle = style;
		return false;
	}
	
	this.getStyleEditor().setValue(style, -1); // -1 to set cursor to beginning and prevent selection

	// If the editor is not visible it will not be updated, so we defer this refresh in hope it becomes visible (ex: tab switch)
	_.defer(() => this.getStyleEditor().refresh());

	return true;
};

StylesManager.prototype.setStylesList = function(stringList) {
	var self = this;
	if (! _.isArray(stringList)) {
		return _.withError(['StylesManager.setStylesList: stringList must be array of selector strings', stringList]);
	}

	this.stylesList = _.clone(stringList);

	this.refreshStylesList(this.stylesList);
};

StylesManager.prototype.refreshStylesList = function(stringList) {
	var self = this;
	if (! _.isArray(stringList)) {
		return _.withError(['StylesManager.refreshStylesList: stringList must be array of selector strings', stringList]);
	}

	this.selectors.length = 0;

	_.forEach(stringList, function(style) {
		self.selectors.push(style);
	}, this);

	this.getList().jqxListBox('refresh');

	if (stringList.length) {
		var currentSelector = stringList[0];
		this.setCurrentSelector(currentSelector);
	}
};

StylesManager.prototype.showUpDownButtons = function(show) {
	var layout = this.getLayout();

	var upButton = layout.find('.action-style-up');
	var downButton = layout.find('.action-style-down');

	if (! show) {
		upButton.hide();
		downButton.hide();
		return true;
	}

	upButton.show();
	downButton.show();

	return true;
};

StylesManager.prototype.showFilteredCheckbox = function(show) {
	var layout = this.getLayout();

	var el = layout.find('[action=list-filter]');
	if (! show) {
		el.hide();
		return true;
	}

	el.show();
	return true;
};

StylesManager.prototype.setFilteredState = function (state) {
	var layout = this.getLayout();

	this.isFiltered = state;

	if (! state) {
		this.refreshStylesList(this.stylesList);
		layout.find('[name=list-filtered]').prop('checked', false);
		this.showUpDownButtons(true);
		return true;
	}

	this.refreshStylesList(this.filteredList);
	layout.find('[name=list-filtered]').prop('checked', true);
	this.showUpDownButtons(false);
	return true;
};


StylesManager.prototype.setFilteredList = function (stringList) {
	if (! _.isArray(stringList)) {
		stringList = null;
	}

	this.filteredList = stringList;
	var isFiltered = ! _.isEmpty(stringList);
	this.showFilteredCheckbox(isFiltered);
	this.setFilteredState(isFiltered);
};

addHooksTrait(StylesManager, 'StylesManager');



// --------------------------------------------- NetworkStylesManager ----------------------------------------------



const NetworkStylesManager = function(dependencies, settings) {
	const self = this;

	this.codeEvaluator = dependencies.get(CodeEvaluator);

	this.settings = {
		container: null,
		hooks: {},
		defaultNodeStyle: {
			"display": "circle",
			"width": 80,
			"fillColor": "#ddd"
		},
		defaultRelationStyle: {
			"fillColor": "#999",
			"width": 1
		},
		currentNodeFenotype: undefined,
		currentRelationFenotype: undefined
	};

	this.settings = _.extend({}, this.settings, settings);

	this.styles = this.settings.styles;

	this.layout = this.createLayout(this.settings.container);
	if ( !this.getLayout())  {
		return;
	}

	this.applyCurrentEditedNodeStyle = function () {
		var selectorString = 'node' + this.nodeStylesManager.getEditedSelector();
		var style = this.nodeStylesManager.getEditedStyle();

		var selector = new GraphSelector(selectorString);
		if (! selector.isNodeSelector() || ! selector.isOk()) {
			alert('Selector is not valid: ' + selectorString);
			return false;
		}

		if (! style) {
			return false;
		}

		if (self.getNodeStyle(selectorString)) {
			if (this.nodeStylesManager.getCurrentSelector() !== selectorString) {
				if (! confirm('Selector already exists! Overwrite style?')) {
					return false;
				}
			}
		}

		if (self.executeHook('onNodeStyleChange', [selectorString, style]) === false) {
			return false;
		}

		if (! self.setNodeStyle(selectorString, style)) {
			return _.withError(['nodeStylesManager.onApply(): could not set selector style', selectorString, style]);
		}
		this.nodeStylesManager.setCurrentSelector(selectorString);
		self.updateNodePreview(selector, style);
		self.executeHook('onNodeStyleChanged', [selectorString, style]);
	};

	this.nodeStylesManager = new StylesManager(dependencies, {
		container: this.getLayout().find('#tab-nodes'),
		mainSelector: 'node',
		styles: this.styles,
		hooks: {
			onApply: _.bind(self.applyCurrentEditedNodeStyle, self),
			onSave: function() {
				self.applyCurrentEditedNodeStyle();
				self.executeHook('onSaveStyles', []);
				self.setChanged(false);
			},
			onSelectStyle: function(selectorString) {
				var style = self.getNodeStyle(selectorString);

				var selector = new GraphSelector(selectorString);

				this.setEditedSelector(selector.getSelector());
				this.setEditedStyle(style);
				self.updateNodePreview(selector, style);

				self.resize();
			},
			onAddStyle: function() {
				this.setEditedSelector('');
				this.setEditedStyle(self.settings.defaultNodeStyle);
				var selector = new GraphSelector('node');
				self.updateNodePreview(selector, self.settings.defaultNodeStyle);
				this.clearCurrentSelector();

				self.getLayout().resize(); // make sure all parts update their size
			},
			onRemoveStyle: function(selectorString) {
				if ( ! confirm('Delete node style for ' + selectorString + ' ?')) {
					return false;
				}

				if (self.executeHook('onNodeStyleRemoved', [selectorString]) === false) {
					return false;
				}

				self.deleteNodeStyle(selectorString);
				this.setCurrentSelector();

				self.executeHook('onNodeStyleRemoved', [selectorString]);
			},
			onStyleUp: function(newSelectorsList) {
				if (self.executeHook('onNodeStyleOrderChange', [newSelectorsList]) === false) {
					return false;
				}
				var result = self.reorderNodeStyles(newSelectorsList);

				if (result === false) {
					return false;
				}

				self.executeHook('onNodeStyleOrderChanged', [self.getRelationStyles()]);
			},
			onStyleDown: function(newSelectorsList) {
				if (self.executeHook('onNodeStyleOrderChanged', [newSelectorsList]) === false) {
					return false;
				};
				var result = self.reorderNodeStyles(newSelectorsList);

				if (result === false) {
					return false;
				}

				self.executeHook('onNodeStyleOrderChange', [self.getRelationStyles()]);
			},
			onChange: function() {
				this.setChanged(true);
				self.executeHook('onChanged');
			}
		}
	});

	this.resize = function() {
		var layout = self.getLayout();
		layout.resize();
	};

	this.applyCurrentEditedRelationStyle = function() {
		var selectorString = 'rel' + this.relationStylesManager.getEditedSelector();
		var style = this.relationStylesManager.getEditedStyle();

		var selector = new GraphSelector(selectorString);
		if (!selector.isRelationSelector() || !selector.isOk()) {
			alert('Selector is not valid: ' + selectorString);
			return false;
		}

		if ( !style) {
			return false;
		}

		if (self.getRelationStyle(selectorString)) {
			if (this.relationStylesManager.getCurrentSelector() != selectorString) {
				if (!confirm('Selector already exists! Overwrite style?')) {
					return false;
				}
			}
		}

		if (self.executeHook('onRelationStyleChange', [selectorString, style]) === false) {
			return false;
		};

		if ( !self.setRelationStyle(selectorString, style)) {
			return _.withError(['relationStylesManager.onApply(): could not set selector style', selectorString, style]);
		}
		this.relationStylesManager.setCurrentSelector(selectorString);
		self.updateRelationPreview(selector, style);

		self.executeHook('onRelationStyleChanged', [selectorString, style]);
	};

	this.relationStylesManager = new StylesManager(dependencies, {
		container: this.getLayout().find('#tab-relationships'),
		mainSelector: 'rel',
		styles: this.styles,
		hooks: {
			onApply: _.bind(self.applyCurrentEditedRelationStyle, self),
			onSave: function() {
				self.applyCurrentEditedRelationStyle();
				self.executeHook('onSaveStyles', []);
				self.setChanged(false);
			},
			onSelectStyle: function(selectorString) {
				var style = self.getRelationStyle(selectorString);

				var selector = new GraphSelector(selectorString);

				this.setEditedSelector(selector.getSelector());
				this.setEditedStyle(style);
				self.updateRelationPreview(selector, style);

				self.getLayout().resize(); // make sure all parts update their size
			},
			onAddStyle: function() {
				this.setEditedSelector('');
				this.setEditedStyle(self.settings.defaultRelationStyle);
				var selector = new GraphSelector('rel');
				self.updateNodePreview(selector, self.settings.defaultRelationStyle);
				this.clearCurrentSelector();

				self.getLayout().resize(); // make sure all parts update their size
			},
			onRemoveStyle: function(selectorString) {
				if ( ! confirm('Delete relation style for ' + selectorString + ' ?')) {
					return false;
				}

				if (self.executeHook('onRelationStyleRemove', [selectorString]) === false) {
					return false;
				}

				self.deleteRelationStyle(selectorString);
				this.setCurrentSelector();

				self.executeHook('onRelationStyleRemoved', [selectorString]);
			},
			onStyleUp: function(newSelectorsList) {
				if (self.executeHook('onRelationStyleOrderChange', [newSelectorsList]) === false) {
					return false;
				};
				var result = self.reorderRelationStyles(newSelectorsList);

				if (result === false) {
					return false;
				}

				self.executeHook('onRelationStyleOrderChanged', [self.getNodeStyles()]);
			},
			onStyleDown: function(newSelectorsList) {
				if (self.executeHook('onRelationStyleOrderChange', [newSelectorsList]) === false) {
					return false;
				};
				var result = self.reorderRelationStyles(newSelectorsList);
				if (result === false) {
					return false;
				}

				self.executeHook('onRelationStyleOrderChanged', [self.getNodeStyles()]);
			},
			onChange: function() {
				this.setChanged(true);
				self.executeHook('onChanged');
			}
		}
	});

	this.nodeFenotypesGraph = new D3Graph({
		container: this.getLayout().find('.node-fenotypes-container'),
		autoLayout: false,
		autoZoomToFit: true,
		canZoom: true,
		styles: this.styles,
		hooks: {
			onNodeClicked: _.bind(self.showStylesForNodeFenotype, self),
			onNodeMouseOver: function(node, element, event) {
				var styles = self.getNodeUsedStyles(node);
				var content = '';

				_.forEach(_.keys(styles), function(selector) {
					content += '<li>' + selector + '</li>';
				}, self);

				content = '<ul class="no-decoration">' + content + '</ul>';

				if (self.popupWindow) {
					self.popupWindow.close();
					delete self.popupWindow;
				}

				self.popupWindow = new PopupWindow({
					htmlContent: content,
					x: event.mouseX,
					y: event.mouseY
				});

				self.popupWindow.show();

				var size = self.popupWindow.getSize();
				self.popupWindow.moveTo(event.mouseX - size.width / 2, event.mouseY - size.height - 20);
			},
			onNodeMouseOut: function() {
				self.popupWindow.close();
				delete self.popupWindow;
			}
		},
		overrideNodeStyle: function(style) {
			if(_.has(style, 'labelPosition') && (style.labelPosition === 'right' || style.labelPosition === 'left')) {
				style.labelPosition = 'bottom';
			}
		}
	});

	this.relationFenotypesGraph = new D3Graph({
		container: this.getLayout().find('.relation-fenotypes-container'),
		autoLayout: false,
		autoZoomToFit: true,
		canZoom: true,
		styles: this.styles,
		hooks: {
			onRelationClicked: _.bind(self.showStylesForRelationFenotype, self),
			onRelationMouseOver: function(relation, element, event) {
				var styles = self.getRelationUsedStyles(relation);
				var content = '';

				_.forEach(_.keys(styles), function(selector) {
					content += '<li>' + selector + '</li>';
				}, self);
				content = '<ul class="no-decoration">' + content + '</ul>';

				if (self.popupWindow) {
					self.popupWindow.close();
					delete self.popupWindow;
				}

				self.popupWindow = new PopupWindow({
					htmlContent: content,
					x: event.mouseX,
					y: event.mouseY
				});

				self.popupWindow.show();

				var size = self.popupWindow.getSize();
				self.popupWindow.moveTo(event.mouseX - size.width / 2, event.mouseY - size.height - 20);
			},
			onRelationMouseOut: function() {
				self.popupWindow.close();
				delete self.popupWindow;
			}
		}
	});

	this.nodeGenotypesGraph = new D3Graph({
		container: this.getLayout().find('.node-genotypes-container'),
		autoLayout: false,
		autoZoomToFit: true,
		canZoom: true,
		styles: this.styles,
		hooks: {
			onNodeClicked: function(node) {
				self.switchToEditNodesTab();
				self.setCurrentNodeStyle(node.properties.selector);
			}
		},
		overrideNodeStyle: function(style) {
			if(_.has(style, 'labelPosition') && (style.labelPosition === 'right' || style.labelPosition === 'left')) {
				style.labelPosition = 'bottom';
			}
		}
	});

	this.relationGenotypesGraph = new D3Graph({
		container: this.getLayout().find('.relation-genotypes-container'),
		autoLayout: false,
		autoZoomToFit: true,
		canZoom: true,
		styles: this.styles,
		hooks: {
			onRelationClicked: function(relation) {
				self.switchToEditRelationsTab();
				self.setCurrentRelationStyle(relation.properties.selector);
			}
		}
	});

	this.setUpHooks(this.settings.hooks);

	//UI needs to be rendered
	setTimeout(
		() => {
			if (this.settings['currentNodeFenotype']) {
				this.showStylesForNodeFenotype(this.settings.currentNodeFenotype);
			}

			if (this.settings['currentRelationFenotype']) {
				this.showStylesForRelationFenotype(this.settings.currentRelationFenotype);
			}
		}
	);


	this.ok = true;

};

NetworkStylesManager.prototype.isOk = function() {
	return this.ok;
};

NetworkStylesManager.prototype.createLayout = function(container) {
	container = ensureIsJQuery(container);
	if ( !container || !container.length) {
		return _.withError(['NetworkStylesManager.createLayout(): container is not valid', container]);
	}

	var layout = $(NETWORK_STYLES_MANAGER_LAYOUT);
	container.append(layout);

	var tabs = container.find('.tabs');
	var self = this;
	tabs.tabs({activate: function(event, ui) {
		var toNodes = ui.newPanel.selector === '#tab-nodes';
		var toRelations = ui.newPanel.selector === '#tab-relationships';

		self.updateNodeStylesList();
		self.updateRelationStylesList();

		self.nodeStylesManager.setFilteredList();
		self.relationStylesManager.setFilteredList();

		var styleEditor;
		if(toNodes) {
			styleEditor = self.nodeStylesManager.getStyleEditor();
			if(styleEditor) {
				styleEditor.setCursor(0,0);
			}
		}
		if(toRelations) {
			styleEditor = self.relationStylesManager.getStyleEditor();
			if(styleEditor) {
				styleEditor.setCursor(0,0);
			}
		}

		self.positionGraphNodesAsGrid(self.nodeFenotypesGraph);
		self.positionGraphNodesAsRelationList(self.relationFenotypesGraph);

		self.positionGraphNodesAsGrid(self.nodeGenotypesGraph);
		self.positionGraphNodesAsRelationList(self.relationGenotypesGraph);
	}});

	return layout;
};

NetworkStylesManager.prototype.getContainer = function() {
	return this.settings.container;
};

NetworkStylesManager.prototype.switchToEditTab = function() {
	this.getContainer().find('[href="#tab-edit"]').click();
};

NetworkStylesManager.prototype.switchToEditNodesTab = function() {
	this.switchToEditTab();
	this.getContainer().find('[href="#tab-nodes"]').click();
};

NetworkStylesManager.prototype.switchToEditRelationsTab = function() {
	this.switchToEditTab();
	this.getContainer().find('[href="#tab-relationships"]').click();
};

NetworkStylesManager.prototype.getLayout = function() {
	if (! this.layout) {
		return _.withError(['NetworkStylesManager.getLayout(): layout not initialized']);
	}
	return this.layout;
};

NetworkStylesManager.prototype.getNodeStyle = function(selectorString) {
	const nodeStyles = this.getNodeStyles();
	if (nodeStyles[selectorString]) {
		return nodeStyles[selectorString];
	}

	return false;
};

NetworkStylesManager.prototype.setNodeStyle = function(selectorString, style) {
	if (!isValidSelectorString(selectorString)) {
		return false;
	}

	if (!isValidStyle(style)) {
		return false;
	}

	let addSelector = true;

	if (this.getNodeStyle(selectorString)) {
		addSelector = false;
	}

	this.styles.setDefinition('node', selectorString, style);

	if (addSelector) {
		this.nodeStylesManager.addSelector(selectorString);
	}
	return true;
};

NetworkStylesManager.prototype.deleteNodeStyle = function(selectorString) {
	this.styles.removeDefinition('node', selectorString);

	this.nodeStylesManager.deleteSelector(selectorString);

	return true;
};

NetworkStylesManager.prototype.updateNodeStylesList = function() {
	if (!this.nodeStylesManager) {
		return _.withError(['NetworkStylesManager.updateNodeStylesList: nodeStylesManager not initialized']);
	}

	this.nodeStylesManager.setStylesList(this.styles.getOrder('node'));
};

NetworkStylesManager.prototype.reorderNodeStyles = function(newSelectorsList) {

	if ( ! _.isArray(newSelectorsList)) {
		return _.withError(['NetworkStylesManager.reorderNodeStyles: selectors list should be array', newSelectorsList]);
	}

	if (_.isEmpty(newSelectorsList)) {
		return _.withError(['NetworkStylesManager.reorderNodeStyles: selectors list is empty and it should not be', newSelectorsList]);
	}

	this.styles.setOrder('node', newSelectorsList);

	return true;
};

NetworkStylesManager.prototype.updateNodePreview = function(selector, style) {
	const graph = this.nodeStylesManager.getPreviewGraph();

	if (!graph) {
		return false;
	}

	var node = selector.createMatchingNode();

	if (!node) {
		return false;
	}

	node.x = 0;
	node.y = 0;
	node.fixed = 1;

	graph.empty();

	graph.addNodes(node);
	graph.centerGraph();

	graph.applyGraphStyles(); // renders the styling
	graph.updateGraph();
};

NetworkStylesManager.prototype.getNodeStyles = function() {
	return this.styles.getDefinitions('node');
};

NetworkStylesManager.prototype.getRelationStyle = function(selectorString) {
	const relationStyles = this.getRelationStyles();
	if (relationStyles[selectorString]) {
		return relationStyles[selectorString];
	}

	return false;
};

NetworkStylesManager.prototype.setRelationStyle = function(selectorString, style) {
	if (!isValidSelectorString(selectorString)) {
		return false;
	}

	if (!isValidStyle(style)) {
		return false;
	}

	let addSelector = true;

	if (this.getRelationStyle(selectorString)) {
		addSelector = false;
	}

	this.styles.setDefinition('rel', selectorString, style);

	if (addSelector) {
		this.relationStylesManager.addSelector(selectorString);
	}
	return true;
};

NetworkStylesManager.prototype.deleteRelationStyle = function(selectorString) {
	this.styles.removeDefinition('rel', selectorString);

	this.relationStylesManager.deleteSelector(selectorString);

	return true;
};

NetworkStylesManager.prototype.updateRelationStylesList = function() {
	if (!this.relationStylesManager) {
		return _.withError(['NetworkStylesManager.updateRelationStylesList: relationStylesManager not initialized']);
	}

	this.relationStylesManager.setStylesList(this.styles.getOrder('rel'));
};

NetworkStylesManager.prototype.reorderRelationStyles = function(newSelectorsList) {

	if ( ! _.isArray(newSelectorsList)) {
		return _.withError(['NetworkStylesManager.reorderRelationStyles: selectors list should be array', newSelectorsList]);
	}

	if (_.isEmpty(newSelectorsList)) {
		return _.withError(['NetworkStylesManager.reorderRelationStyles: selectors list is empty and it should not be', newSelectorsList]);
	}

	this.styles.setOrder('rel', newSelectorsList);

	return true;
};

NetworkStylesManager.prototype.setCurrentNodeStyle = function(selectorString) {
	this.nodeStylesManager.setCurrentSelector(selectorString);
};

NetworkStylesManager.prototype.setCurrentRelationStyle = function(selectorString) {
	this.relationStylesManager.setCurrentSelector(selectorString);
};

NetworkStylesManager.prototype.updateRelationPreview = function(selector, style) {
	var graph = this.relationStylesManager.getPreviewGraph();

	if (!graph) {
		return false;
	}

	var relation = selector.createMatchingRelation();

	if (!relation) {
		return ;
	}

	nodes = [
		graph.getNewNode({
			x: -100,
			y: 0,
			fixed: 1
		}),
		graph.getNewNode({
			x: 100,
			y: 0,
			fixed: 1
		})
	];

	relation.source = nodes[0].id;
	relation.target = nodes[1].id;

	graph.empty();
	graph.addItems({
		nodes: nodes,
		relations: [relation]
	});

	graph.centerGraph();

	graph.applyGraphStyles();
	graph.updateGraph();
};

NetworkStylesManager.prototype.getRelationStyles = function() {
	return this.styles.getDefinitions('rel');
};

NetworkStylesManager.prototype.getUniqueFenotypes = function(items, entityType) {
	items =_.ensureIsArray(items);
	const uniqueFenotypes = _.cloneDeep(
		_.uniqWith(items, (item1, item2) => {
			return _.isEqual(
				_.sortBy(_.get(item1, 'style.matchedSelectors')),
				_.sortBy(_.get(item2, 'style.matchedSelectors'))
			);
		})
	);
	_.forEach(uniqueFenotypes, node => {
		GraphStyles.resetEvaluatedStyles(node.style, entityType);
	});
	return uniqueFenotypes;
};

NetworkStylesManager.prototype.setFenotypeNodesLabel = function(nodes) {
	var self = this;
	nodes =_.ensureIsArray(nodes);
	_.forEach(nodes, function(node) {
		var lastUsedStyle = self.getLastNodeUsedStyle(node);
		node.style.label = "'" + _.first(_.keys(lastUsedStyle)) + "'";
	}, this);
};

NetworkStylesManager.prototype.getNodeUsedStyles = function(fenotypeNode) {
	var usedStyles = {};
	_.forEach(this.getNodeStyles(), function(style, selector) {
		if ( ! GraphSelector.matchNodeSelector(fenotypeNode, selector)) {
			return;
		}
		usedStyles[selector] = style;
	}, this);
	return usedStyles;
};

NetworkStylesManager.prototype.getLastNodeUsedStyle = function(fenotypeNode) {
	var lastUsedStyle = {};
	_.forEach(this.getNodeStyles(), function(style, selector) {
		if ( ! GraphSelector.matchNodeSelector(fenotypeNode, selector)) {
			return;
		}
		lastUsedStyle = {};
		lastUsedStyle[selector] = style;
	}, this);

	return lastUsedStyle;
};

NetworkStylesManager.prototype.setFenotypeRelationsLabel = function(relations) {
	var self = this;
	relations =_.ensureIsArray(relations);
	_.forEach(relations, function(relation) {
		var lastUsedStyle = self.getLastRelationUsedStyle(relation);
		relation.style.label = "'" + _.first(_.keys(lastUsedStyle)) + "'";
	}, this);
};

NetworkStylesManager.prototype.getRelationUsedStyles = function(fenotypeRelation) {
	var usedStyles = {};
	_.forEach(this.getRelationStyles(), function(style, selector) {
		if ( ! GraphSelector.matchRelSelector(fenotypeRelation, selector)) {
			return;
		}
		usedStyles[selector] = style;
	}, this);
	return usedStyles;
};

NetworkStylesManager.prototype.getLastRelationUsedStyle = function(fenotypeRelation) {
	var lastUsedStyle = {};
	_.forEach(this.getRelationStyles(), function(style, selector) {
		if ( ! GraphSelector.matchRelSelector(fenotypeRelation, selector)) {
			return;
		}
		lastUsedStyle = {};
		lastUsedStyle[selector] = style;
	}, this);

	return lastUsedStyle;
};

NetworkStylesManager.prototype.setNodeFenotypes = function(nodes) {
	this.nodeFenotypes = this.getUniqueFenotypes(nodes, 'node');
	this.setFenotypeNodesLabel(this.nodeFenotypes);
	this.nodeFenotypesGraph.empty();
	this.nodeFenotypesGraph.addNodes(this.nodeFenotypes);
	this.nodeFenotypesGraph.applyGraphStyles();
	this.nodeFenotypesGraph.updateGraph();
	this.positionGraphNodesAsGrid(this.nodeFenotypesGraph);

	this.nodeGenotypes = this.getNodeGenotypes(this.nodeFenotypes);
	this.nodeGenotypesGraph.empty();
	this.nodeGenotypesGraph.addNodes(this.nodeGenotypes);
	this.nodeGenotypesGraph.applyGraphStyles();
	this.nodeGenotypesGraph.updateGraph();
	this.positionGraphNodesAsGrid(this.nodeGenotypesGraph);
};

NetworkStylesManager.prototype.setRelationFenotypes = function(relations) {
	this.relationFenotypes = this.getUniqueFenotypes(relations, 'rel');
	this.setFenotypeRelationsLabel(this.relationFenotypes);
	var nodes = this.addNodesToRelations(this.relationFenotypes, this.relationFenotypesGraph);
	this.relationFenotypesGraph.loadItems({nodes: nodes, relations: this.relationFenotypes});
	this.relationFenotypesGraph.applyGraphStyles();
	this.relationFenotypesGraph.updateGraph();
	this.positionGraphNodesAsRelationList(this.relationFenotypesGraph);

	this.relationGenotypes = this.getRelationGenotypes(this.relationFenotypes);
	this.relationGenotypesGraph.empty();
	var nodes = this.addNodesToRelations(this.relationGenotypes, this.relationGenotypesGraph);
	this.relationGenotypesGraph.loadItems({nodes: nodes, relations: this.relationGenotypes});
	this.relationGenotypesGraph.applyGraphStyles();
	this.relationGenotypesGraph.updateGraph();
	this.positionGraphNodesAsRelationList(this.relationGenotypesGraph);
};

NetworkStylesManager.prototype.addNodesToRelations = function(relations, graph) {
	var nodes = [];
	_.forEach(relations, function(relation) {
		var source = graph.getNewNode();
		var target = graph.getNewNode();

		relation.source = source.id;
		relation.target = target.id;

		nodes.push(source, target);
	}, this);

	return nodes;
};

NetworkStylesManager.prototype.getNodeGenotypes = function(fenotypeNodes) {
	if (!this.nodeGenotypesGraph || ! this.nodeGenotypesGraph.isOk()) {
		return _.withError('NetworkStylesManager.getNodeGenotypes: nodeGenotypesGraph is not initialized properly');
	}

	var graph = this.nodeGenotypesGraph;
	var nodeGenotypes = [];

	fenotypeNodes =_.ensureIsArray(fenotypeNodes);

	var nodeStyles = this.getNodeStyles();

	if (!nodeStyles || _.isEmpty(nodeStyles)) {
		return [];
	}

	_.forEach(nodeStyles, function(style, selector) {
		var styleIsUsed = false;
		var expandedSelector = GraphSelector.expandSelector(selector);

		_.forEach(fenotypeNodes, function(fenotypeNode) {
			if (GraphSelector.matchNodeSelector(fenotypeNode, expandedSelector)) {
				styleIsUsed = true;
				return false;
			}
		}, this);

		if (styleIsUsed) {
			var graphSelector = new GraphSelector(selector);

			if ( ! graphSelector.isOk()) {
				_.withError('NetworkStylesManager.getNodeGenotypes: could not create GraphSelector from selector', selector);
			}

			var node = graphSelector.createMatchingNode();
			node.properties.selector = selector;
			var newStyle = _.extend({}, graph.settings.defaultNodeStyle, style);
			newStyle.label = "'" + selector + "'";
			graph.setNodeStyle(node, newStyle);
			nodeGenotypes.push(node);
		}
	}, this);

	return nodeGenotypes;
};

NetworkStylesManager.prototype.getRelationGenotypes = function(fenotypeRelations) {
	if (!this.relationGenotypesGraph || ! this.relationGenotypesGraph.isOk()) {
		return _.withError('NetworkStylesManager.getRelationGenotypes: relationGenotypesGraph is not initialized properly');
	}

	var graph = this.relationGenotypesGraph;

	fenotypeRelations =_.ensureIsArray(fenotypeRelations);

	var genotypeStyles = [];
	var relationStyles = this.getRelationStyles();
	var relationGenotypes = [];

	if (!relationStyles || _.isEmpty(relationStyles)) {
		return [];
	}

	_.forEach(relationStyles, function(style, selector) {
		var styleIsUsed = false;
		var expandedSelector = GraphSelector.expandSelector(selector);

		_.forEach(fenotypeRelations, function(fenotypeRelation) {
			if (GraphSelector.matchRelSelector(fenotypeRelation, expandedSelector)) {
				styleIsUsed = true;
				return false;
			}
		}, this);

		if (styleIsUsed) {
			var newStyle = _.extend({}, graph.settings.defaultRelationStyle, style);
			var relation = graph.getNewRelation({style: newStyle, type: selector});
			relation.properties.selector = selector;
			relationGenotypes.push(relation);
		}
	}, this);

	return relationGenotypes;
};

NetworkStylesManager.prototype.positionGraphNodesAsGrid = function(graph) {

	const GRID_ELEMENT_MARGIN = 20; //px

	if (!(graph instanceof D3Graph)) {
		return _.withError(['NetworkStylesManager.positionGraphNodesAsGrid: graph is not D3Graph object', graph]);
	}

	if (! graph.isVisible()) {
		return;
	}


	if ( ! graph || ! graph.isOk()) {
		return _.withError('NetworkStylesManager.positionGraphNodesAsGrid: graph is not valid', graph);
	}
	var nodes = graph.getAllNodes();
	var graphSize = graph.getGraphSize();
	if (!graphSize) {
		return _.withError('NetworkStylesManager.positionGraphNodesAsGrid: could not get graph size', graph);
	}

	var currentRow = {
		x: 0,
		y: 0,
		width: 0,
		height: 0
	};

	var rowIsEmpty = true;
	_.forEach(nodes, function(node) {
		graph.updateNodeLabel(node);
		var nodeSize = graph.getNodeSize(node);
		if (! nodeSize) {
			return;
		}
		if (( ! rowIsEmpty) && (graphSize.width < currentRow.x + nodeSize.width)) {
			currentRow = {
				x: 0,
				y: currentRow.y + currentRow.height,
				width: 0,
				height: 0
			};
			rowIsEmpty = true;
		}

		node.fixed = true;
		node.x = node.px = currentRow.x + nodeSize.width / 2 + GRID_ELEMENT_MARGIN;
		node.y = node.py = currentRow.y + nodeSize.height / 2 + GRID_ELEMENT_MARGIN;

		currentRow.x += nodeSize.width + GRID_ELEMENT_MARGIN;

		if (currentRow.height < nodeSize.height + GRID_ELEMENT_MARGIN) {
			currentRow.height = nodeSize.height + GRID_ELEMENT_MARGIN;
		}

		rowIsEmpty = false;

	}, this);

	var newGraphHeight = Math.max(200, currentRow.y + currentRow.height);

	// if (graphSize.height !=  newGraphHeight) {
	// 	graph.resize(undefined, newGraphHeight, true);
	// }

	graph.tick();
	graph.centerGraph();
	graph.updateGraph();
	return true;
};

NetworkStylesManager.prototype.setChanged = function(changed) {
	this._changed = changed;
	this.nodeStylesManager.setChanged(changed);
	this.relationStylesManager.setChanged(changed);
};

NetworkStylesManager.prototype.isChanged = function() {
	return this._changed;
};

NetworkStylesManager.prototype.positionGraphNodesAsRelationList = function(graph) {
	const GRID_ELEMENT_MARGIN = 20; //px

	if (!(graph instanceof D3Graph)) {
		return _.withError(['NetworkStylesManager.positionGraphNodesAsRelationList: graph is not D3Graph object', graph]);
	}

	if (! graph.isVisible()) {
		return;
	}


	if ( ! graph || ! graph.isOk()) {
		return _.withError('NetworkStylesManager.positionGraphNodesAsRelationList: graph is not valid', graph);
	}

	var graphSize = graph.getGraphSize();
	if (!graphSize) {
		return _.withError('NetworkStylesManager.positionGraphNodesAsRelationList: could not get graph size', graph);
	}
	var relations = graph.getAllRelationsInternal();

	if (_.isEmpty(relations)) {
		return;
	}

	var grid = {
		x: 0,
		y: 0,
		width: graphSize.width,
		height: 0
	};

	_.forEach(relations, function(relation) {

		var sourceSize = graph.getNodeSize(relation.source);
		var targetSize = graph.getNodeSize(relation.target);

		if (! sourceSize || ! targetSize) {
			return;
		}

		var rowHeight = Math.max(sourceSize.height, targetSize.height) + GRID_ELEMENT_MARGIN;

		graph.updateNodeLabel(relation.source);
		graph.updateNodeLabel(relation.target);

		relation.source.fixed = true;
		relation.source.x = relation.source.px = GRID_ELEMENT_MARGIN + sourceSize.width / 2;
		relation.source.y = relation.source.py = grid.height + rowHeight / 2;

		relation.target.fixed = true;
		relation.target.x = relation.target.px = graphSize.width - GRID_ELEMENT_MARGIN - targetSize.width / 2;
		relation.target.y = relation.target.py = grid.height + rowHeight / 2;

		grid.height += rowHeight;

	}, this);

	var newGraphHeight = Math.max(200, grid.height);

	if (graphSize.height != newGraphHeight) {
		graph.resize(undefined, newGraphHeight, true);
	}

	graph.tick();
	graph.centerGraph();
	graph.updateGraph();
	return true;
};

NetworkStylesManager.prototype.showStylesForNodeFenotype = function(node) {
	var styleSelectors = _.keys(this.getNodeUsedStyles(node));
	this.switchToEditNodesTab();
	this.nodeStylesManager.setFilteredList(styleSelectors);

	if (styleSelectors.length < 2) {
		// Set up creation of a new style
		this.nodeStylesManager.setEditedSelector(GraphSelector.selectorFromNode(node));
		this.nodeStylesManager.clearCurrentSelector();
		return;
	}

	// Select most important style (last one)
	this.nodeStylesManager.setCurrentSelector(_.last(styleSelectors));
};

NetworkStylesManager.prototype.showStylesForRelationFenotype = function(relation) {
	var styleSelectors = _.keys(this.getRelationUsedStyles(relation));
	this.switchToEditRelationsTab();
	this.relationStylesManager.setFilteredList(styleSelectors);

	if (styleSelectors.length < 2) {
		//Setup new style creation
		this.relationStylesManager.setEditedSelector(GraphSelector.selectorFromRelation(relation));
		this.relationStylesManager.clearCurrentSelector();
		return;
	}

	// Select most important style (last one)
	this.relationStylesManager.setCurrentSelector(_.last(styleSelectors));
};

addHooksTrait(NetworkStylesManager, 'NetworkStylesManager');

function isValidSelectorString(selectorString) {
	if ( !_.isString(selectorString)) {
		return false;
	}

	if ( _.isEmpty(selectorString)) {
		return false;
	}

	return true;
}

function isValidStyle(style) {
	if ( ! _.isPlainObject(style)) {
		return false;
	}

	return true;
}


//--------------------------------------------------- Hover window -------------------------------

var POPUP_WINDOW_TENPLATE = _.template(
	'<div class="popup-window">' +
	'	<%= htmlContent %>' +
	'</div>');

var PopupWindow = function(settings) {
	this.settings = _.extend({}, PopupWindow.prototype.settings, settings);
	this.content = $(POPUP_WINDOW_TENPLATE({htmlContent: this.settings.htmlContent}));
	this.moveTo(this.settings.x, this.settings.y);
	this.isInserted = false;
	this.ok = true;
};

PopupWindow.prototype.getContent = function() {
	return this.content;
};

PopupWindow.prototype.moveTo = function(x, y) {
	this.settings.x = x;
	this.settings.y = y;
	this.getContent().css('left', x);
	this.getContent().css('top', y);
	return true;
};

PopupWindow.prototype.show = function() {
	if ( ! this.isInserted) {
		$('body').append(this.getContent());
		this.isInserted = true;
	}

	return true;
};

PopupWindow.prototype.close = function() {
	if (this.isInserted) {
		this.getContent().remove();
		this.isInserted = true;
	}

	return true;
};

PopupWindow.prototype.getSize = function() {
	if ( ! this.isInserted ) {
		return {
			width: 0,
			height: 0
		};
	}

	return {
		width: this.getContent().width(),
		height: this.getContent().height()
	};
};

PopupWindow.prototype.settings = {
	template: '',
	htmlCotent: '',
	x: 0,
	y: 0
};

module.exports = NetworkStylesManager;
