const {isArray, isObject, isString, debounce, assign} = require('lodash');
const def = require('utils/src/validation').def;
const CodeMirror = require('client/libraries/code-mirror-interactor');
const EventInterface = require('core/src/event-interface');
const log = require('core/src/log').instance("client/ui/editor");
const AlternativeEditorAbstract = require('./alternative-editor-abstract').default;

// Loading this at start fixes an issue with YFiles when creating relations when SearchView is not started and after creating a node with properties using NodeFormView
// It seems that Antlr4 modifies the core js classes (Object, Map, Set, ...) and it needs to be loaded before YFiles.
require('./modes/cypher.js');

const $ = require('jquery');

require('./editor.scss');

const Editor = function(dependencies, textarea, modes, lineSeparator = null) {
	EventInterface.extend(this);

	this.dependencies = dependencies;

	this._$textarea = $(textarea);

	// Wrap textarea in div
	this._$container = $('<div class="text-editor">');
	this._$textarea.replaceWith(this._$container);
	this._$container.append(this._$textarea);

	this._codeMirror = CodeMirror.fromTextArea(this._$textarea[0], {
		mode: 'application/javascript',
		lineSeparator,
		indentWithTabs: true,
		smartIndent: true,
		matchBrackets : true,
		autofocus: false,
		theme: 'neo',
		viewportMargin: 10,
		lineWrapping: true
	});
	const wrapperClasses = _.get(this._codeMirror, 'display.wrapper.classList');
	wrapperClasses.add("form-control");

	this._codeMirror.on('blur', () => {
		this.toTextArea();
	});
	this._$textarea.on('blur', () => {
		this.fromTextArea();
	});
	setTimeout(() => {
		this._codeMirror.refresh();
	});

	this._$codeMirror = this._$container.find('.CodeMirror');

	this._codeMirror.setValue(this._$textarea.val());

	this._$textarea.on('change', () => {
		this._codeMirror.setValue(this._$textarea.val());
		this.fire('change', this._$textarea.val());
	});

	this._modes = {
		default: {
			options: {
				mode: null,
				lineNumbers: null,
				smartIndent: true,
				extraKeys: {}
			}
		}
	};

	this._context = {};

	this._setupModes(modes);
};
Editor.Mode = {
	DEFAULT: 'default'
};

Editor.prototype._$textarea = null;
Editor.prototype._$container = null;
Editor.prototype._$codeMirror = null;
Editor.prototype._$switchModeControl = null;

Editor.prototype._codeMirror = null;
Editor.prototype._modes = null;
Editor.prototype._modeSettings = null;
Editor.prototype._componentLoader = null;

Editor.prototype._alternativeEditor = null;
Editor.prototype._alternativeEditorKey = null;

Editor.prototype._setupModes = function(modes) {
	if(def(modes) && !isArray(modes)) {
		log.error("Modes argument must be an array.");
		return;
	}

	for(var i in modes) {
		try {
			var mode = require('./modes/' + modes[i] + '.js');
			if(!isObject(mode)) {
				log.error("Unvalid Editor mode '" + modes[i] + "'.");
			} else {
				this._modes[modes[i]] = mode;
			}
		}
		catch(e) {
			log.error("Error in Editor mode '" + modes[i] + "':", e);
		}
	}
};

Editor.prototype.setValue = function(value) {
	if(this._alternativeEditor !== null) {
		this._alternativeEditor.setValue(value);
	} else {
		this._codeMirror.setValue(value);
	}
	this._$textarea.val(value);
};

Editor.prototype.getValue = function() {
	if(this._alternativeEditor !== null) {
		return this._alternativeEditor.getValue();
	} else {
		return this._codeMirror.getValue();
	}
};

Editor.prototype.toTextArea = function() {
	this._$textarea.val(this.getValue());
};

Editor.prototype.fromTextArea = function() {
	this.setValue(this._$textarea.val());
};

Editor.prototype.setMode = function(mode) {
	const self = this;
	if(mode === this._mode) {
		return; // no change
	}

	if (!this._modes.hasOwnProperty(mode)) {
		log.warn(`'${mode}' editor is not available. Default editor applied.`);
		mode = 'default';
	}

	this._$container.removeClass('mode-' + this._mode);
	this._$container.addClass('mode-' + mode);

	this._mode = mode;

	let modeSettings = this._modes[mode];
	this._modeSettings = modeSettings;

	this.setModeSettings(modeSettings);

	if(isObject(modeSettings.alternativeEditors)) {
		// Create mode switcher
		if(Object.keys(modeSettings.alternativeEditors).length > 1) {
			this._$switchParent = $('<div class="switch-menu"></div>')
			this._$switchModeControl = $('<select class="switch-mode form-select">');
			for(let i in modeSettings.alternativeEditors) {
				let alternative = modeSettings.alternativeEditors[i];
				let $option = $('<option>').attr('value', i);
				let label = isObject(alternative) ? alternative.label : alternative;
				$option.html(label);
				if(this._alternativeEditorKey === i) {
					$option.attr('selected', true);
				}

				this._$switchModeControl.append($option);
			}
			this._$switchModeControl.on('change', function() {
				let mode = $(this).val();
				self.setAlternativeEditor(mode);
			});

			this._$switchParent.append(this._$switchModeControl);
			this._$switchParent.append('<i class="fa fa-bars"></i>');
			this._$container.append(this._$switchParent);
		}

		// Select first Alternative Editor
		this.nextAlternativeEditor();
	} else {
		if(this._$switchModeControl != null) {
			this._$switchModeControl.remove();
		}
	}
};

Editor.prototype.setAlternativeEditor = function(name) {
	if(!(name in this._modeSettings.alternativeEditors)) {
		log.error("No alternative editor by name '" + name + "'.");
		return;
	}

	let alternative = this._modeSettings.alternativeEditors[name];

	// Switch editors
	
	if(isObject(alternative.Editor) && alternative.Editor.prototype instanceof AlternativeEditorAbstract) {

		// Timeout is used in order to make sure that container is created and prevent css issues
		setTimeout(() => {
			let value = this._codeMirror.getValue();

			this._alternativeEditor = new alternative.Editor(this.dependencies, this._$container[0]);
			this._alternativeEditor.setValue(value);
			this._alternativeEditor.on('change', (value) => {
				this._$textarea.val(value);
				this.fire('change', value);
			});
	
			this._alternativeEditor.on('blur', () => {
				this.toTextArea();
			});
		}, 0);

		this._$codeMirror.hide();
	} else {
		let value = '';
		if(def(this._alternativeEditor)) {
			value = this._alternativeEditor.getValue();
			this._alternativeEditor.destroy();
			this._alternativeEditor = null;
		}
		this._$codeMirror.show();
		this._codeMirror.setValue(value);
		this._codeMirror.on('change', debounce((instance, changeObj) => {
			this.fire('change', instance.getValue());
		}, 400));
	}

	if(this._$switchModeControl) {
		this._$switchModeControl.val(name);
	}

	this._$container.removeClass('alternative-' + this._alternativeEditorKey);
	this._$container.addClass('alternative-' + name);

	this._alternativeEditorKey = name;
};

Editor.prototype.nextAlternativeEditor = function () {
	let alternativeKeys = Object.keys(this._modeSettings.alternativeEditors);

	let idx = Object.keys(this._modeSettings.alternativeEditors).indexOf(this._alternativeEditorKey);
	if(idx < 0) {
		idx = 0;
	} else {
		idx = (idx+1) % alternativeKeys.length;
	}

	this.setAlternativeEditor(alternativeKeys[idx]);
};

Editor.prototype.getEditorModeFromKey = function(key) {
	const defaultMode = Editor.Mode.DEFAULT;

	if(!isString(key)) return defaultMode;

	var compare = key.replace('$', '').toLowerCase();
	for(var mode in this._modeTypes) {
		for(var i in this._modeTypes[mode]) {
			var find = this._modeTypes[mode][i];
			if(compare === find) {
				return mode;
			}
		}
	}

	return defaultMode;
};

Editor.prototype.setOption = function(option, value) {
	if (!this._codeMirror) return;
	this._codeMirror.setOption(option, value);
};

Editor.prototype.refresh = function() {
	if (!this._codeMirror) return;
	this._codeMirror.refresh();
};

Editor.prototype.setModeSettings = function(modeSettings) {
	let options = assign({ }, this._modes.default.options, modeSettings.options);

	for (let opt in options) {
		this._codeMirror.setOption(opt, options[opt]);
	}
};

Editor.prototype.setContext = function(mode, property, value) {
	this._context[mode] = {...this._context[mode], [property]: value};
	this._updateAlternativeEditorContext();
};

Editor.prototype._updateAlternativeEditorContext = function() {
	if (!this._alternativeEditor) return;
	const mode = this._alternativeEditorKey;
	this._alternativeEditor.setContext(this._context[mode]);
};

module.exports = Editor;
