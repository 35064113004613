const Function = require('core/src/function');
const View = require('core/src/functions/view');
const { isReadOnly, writable } = require('core/src/utils/read-only');

const LeafletMapView = Function.extend(View, "LeafletMapView");

/**
 * @override
 * @returns {undefined}
 */
LeafletMapView.prototype.onInit = function() {
	this.setParameters({
		'$autoFit': true,
		'$bounds': undefined,
		'$center' : {lat: 0, long: 0},
		'$controls': {
			drawing: {
				position: 'bottomleft',
				draw: {
					circlemarker: false,
				},
				edit: false				
			},
			measure: {
				primaryLengthUnit: 'meters',
				secondaryLengthUnit: 'kilometers',
				activeColor: '#c71010',
				completedColor: '#f50404',
				position: 'bottomright',
				primaryAreaUnit: 'sqkilometers',
				secondaryAreaUnit: 'sqmeters'
			}
		},
		'$geoJSON': {
			type: 'FeatureCollection',
			features: []
		},
		'$geoJSONStyle': {},
		'$geoJSONSelectedStyle': {stroke: true, color: '#000000', weight: 3, opacity: 1},
		'$layers': {
			'OpenStreetMap': {
				type: 'tileLayer',
				index: 12,
				active: true,

				url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
				attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				maxZoom: 19
			}
		},
		'$layerControl': {},
		'$markerCluster': {
			enabled: false,
			options: {}
		},
		'$options': {},
		'$projections': {},
		'#markers': undefined,
		'$state': {
			selected: {
				markers: [],
				features: [],
			}
		},
		'$toImage': false,
		'$zoom': 0,
	});
};

LeafletMapView.prototype.onExecute = function() {
	this.processMarkers();
	this.processGeoJSON();
	return View.prototype.onExecute.call(this);
};

LeafletMapView.prototype.onUpdate = function(changes) {
	if (! _.isNil(changes.markers)) {
		this.processMarkers();
	}

	if (! _.isNil(changes.geoJSON) || ! _.isNil(changes['geoJSON.features'])) {
		this.processGeoJSON();
	}
}

LeafletMapView.prototype.processMarkers = function() {
	let markers = this.readModel('markers');

	if (isReadOnly(markers)) markers = writable(markers);

	let update = false;
	let parsedMarkers = _.map(
		markers,
		(marker) => {
			if (_.isNil(marker.id)) {
				marker.id = _.uniqueId('marker-');
				update = true;
			}
			return marker;
		}
	)

	if (update) {
		this.updateModel('markers', markers);
	}
}


LeafletMapView.prototype.processGeoJSON = function() {
	let geoJSON = this.readModel('geoJSON');

	if (isReadOnly(geoJSON)) geoJSON = writable(geoJSON);

	let update = false;

	if (_.isEmpty(geoJSON)) {
		return;
	}

	// Always store a FeatureCollection
	if (geoJSON.type === 'Feature') {
		geoJSON = {
			type: 'FeatureCollection',
			features: [geoJSON]
		}

		update = true;
	}

	if (geoJSON.type !== 'FeatureCollection') {
		throw new Error('LeafletMapView: geoJSON is expected as FeatureCollection');
	}

	geoJSON.features = _.map(
		geoJSON.features,
		(feature) => {
			if (_.isNil(feature.id)) {
				feature.id = _.uniqueId('feature-');
				update = true;
			}
			return feature;
		}
	)

	if (update) {
		this.updateModel('geoJSON', geoJSON);
	}
}

module.exports = LeafletMapView;
