var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "login-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit()
            },
          },
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error",
                  },
                ],
                staticClass: "error login-error",
              },
              [_vm._v(_vm._s(_vm.t(_vm.error)))]
            ),
          ]),
          _vm._v(" "),
          !_vm.tfaEnabled
            ? _c("div", { staticClass: "input-section w-100" }, [
                _c("label", [_vm._v(_vm._s(_vm.t("Name")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.username,
                      expression: "username",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "username",
                    autofocus: "",
                    placeholder: _vm.t("Enter your Graphileon username"),
                  },
                  domProps: { value: _vm.username },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.username = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.tfaEnabled
            ? _c("div", { staticClass: "input-section w-100" }, [
                _c("label", [_vm._v(_vm._s(_vm.t("Password")) + " ")]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: _vm.t("Enter your Graphileon password"),
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "togglePassword btn btn-light ms-1",
                    attrs: { type: "button" },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.tfaEnabled
            ? _c("div", { staticClass: "input-section w-100" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.t("Two-Factor Authentication")) + " "),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tfaToken,
                      expression: "tfaToken",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "tfaToken",
                    placeholder: _vm.t("Enter the 6 digit code"),
                  },
                  domProps: { value: _vm.tfaToken },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.tfaToken = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary w-100 mt-2",
                attrs: { type: "submit" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.t(_vm.submitButtonText)))])]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.accountRecovery == true
        ? _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("password-recover", {})
                },
              },
            },
            [_vm._v(_vm._s(_vm.t("Forgot password?")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loginProviders.length > 0 ? _c("div", [_c("p"), _c("hr")]) : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.loginProviders, function (provider) {
        return _c("div", { key: provider.type, staticClass: "mb-2" }, [
          _c(
            "button",
            {
              class:
                "btn btn-secondary w-100 d-flex " + "provider-" + provider.type,
              on: {
                click: function ($event) {
                  return _vm.open(provider.url)
                },
              },
            },
            [
              _c("div", [
                provider.type == "facebook"
                  ? _c("i", { staticClass: "fa fa-facebook-square fa-2xl" })
                  : _vm._e(),
                _vm._v(" "),
                provider.type == "google"
                  ? _c("i", { staticClass: "fa fa-google fa-2xl" })
                  : _vm._e(),
                _vm._v(" "),
                provider.type == "twitter"
                  ? _c("i", { staticClass: "fa-brands fa-x-twitter fa-2xl" })
                  : _vm._e(),
                _vm._v(" "),
                provider.type == "okta"
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          font: "18px Moderne Sans, arial, sans-serif",
                        },
                      },
                      [_vm._v("{"), _c("b", [_vm._v("o")]), _vm._v("}")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(provider.caption) + "\n\t\t\t"),
              ]),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }