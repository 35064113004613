
	import Vue from 'vue';
	import _ from 'lodash';

	export default Vue.extend({
		name: "action-button-renderer",
		data: function () {
			return {
				action: {},
			};
		},
		mounted() {
			this.action = this.columnAction();
		},
		computed: {
			buttonClass() {
				if (this.action.style) return 'btn btn-sm btn-' + this.action.style;
				return 'btn btn-sm';
			},
			iconClass() {
				if (this.action.icon) return 'fa fa-' + this.action.icon;
				return null;
			},
			label() {
				if (this.action.label) return this.action.label;
				return this.action.icon ? null : this.action.name;
			},
			show() {
				return _.isFunction(this.action.show) 
						? this.action.show(this.params.data) 
						: _.get(this.action, 'show' ,true);
			}
		},
		methods: {
			/**
             * https://www.ag-grid.com/javascript-grid-cell-rendering-components/#vuejs-cell-rendering
			 * The cells value is made available implicitly via a data field called params.
			 */
			columnAction() {
				const actionName = _.get(this.params, 'name', this.params.colDef.field);
				return _.extend({}, this.params, {name: actionName});
			},
			invokeTrigger() {
				if ( _.get(this.params, 'context.componentParent') && _.isFunction(this.params.context.componentParent.trigger)) {
					this.params.context.componentParent.preventNextEvents(['cellClicked', 'rowClicked']);
					this.params.context.componentParent.trigger({
						type: 'action',
						name: this.action.name,
						target: {
							data: this.params.context.componentParent.data.find(d => d.id === this.params.data.id),
							rowIndex: this.params.rowIndex
						}
					});
				}
				if ( _.isFunction(this.params.onClick) )
					this.params.onClick.call(this, {data: this.params.data, rowIndex: this.params.rowIndex});
			},
		},
	});
