var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "properties-view-entity d-flex m-0 flex-wrap" },
    [
      _vm.entity
        ? _c("div", { key: _vm.processedEntity }, [
            _vm.entityType === "node"
              ? _c("div", [
                  _vm._v("ID: "),
                  _c("b", { attrs: { title: _vm.entity.id } }, [
                    _vm._v(_vm._s(_vm.shortenedID)),
                  ]),
                  _vm._v(" Store: "),
                  _c("b", { attrs: { title: "Store" } }, [
                    _vm._v(_vm._s(_vm.entity.meta.store)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.entityType === "relation"
              ? _c("div", [
                  _vm._v("ID: "),
                  _c("b", { attrs: { title: _vm.entity.id } }, [
                    _vm._v(_vm._s(_vm.shortenedID)),
                  ]),
                  _vm._v(" Type: "),
                  _c("b", [_vm._v(_vm._s(_vm.entity.type))]),
                  _vm._v(" Store: "),
                  _c("b", { attrs: { title: "Store" } }, [
                    _vm._v(_vm._s(_vm.entity.meta.store)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.entity.labels
              ? _c(
                  "ul",
                  { staticClass: "labels p-0 m-0" },
                  _vm._l(_vm.entity.labels, function (label) {
                    return _c("li", { key: _vm.entity.id + label }, [
                      _vm._v("\n\t\t\t\t" + _vm._s(label) + "\n\t\t\t"),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.entity.properties
              ? _c(
                  "table",
                  { key: _vm.entity.id, staticClass: "w-100" },
                  _vm._l(_vm.entity.properties, function (val, key) {
                    return _c("tr", [
                      _c("th", { attrs: { valign: "top", title: key } }, [
                        _vm._v(_vm._s(key)),
                      ]),
                      _vm._v(" "),
                      key === "css" || key === "$css"
                        ? _c("td", [
                            _c("pre", { staticClass: "css" }, [
                              _vm._v(_vm._s(val)),
                            ]),
                          ])
                        : key === "cypher" || key === "$cypher"
                        ? _c("td", [
                            _c("pre", { staticClass: "cypher" }, [
                              _vm._v(_vm._s(val)),
                            ]),
                          ])
                        : key === "template" || key === "$template"
                        ? _c("td", [_c("pre", [_vm._v(_vm._s(val))])])
                        : _vm.isUploadedFile(val)
                        ? _c("td", [
                            _c(
                              "a",
                              { attrs: { href: val, target: "_blank" } },
                              [_vm._v(_vm._s(_vm.getFileBasename(val)))]
                            ),
                          ])
                        : _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "urlToHtmlLink",
                                  rawName: "v-urlToHtmlLink",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" + _vm._s(val) + "\n\t\t\t\t"
                              ),
                            ]
                          ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }