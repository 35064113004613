import { render, staticRenderFns } from "./action-button-renderer.vue?vue&type=template&id=2b55d987&scoped=true"
import script from "./action-button-renderer.vue?vue&type=script&lang=js"
export * from "./action-button-renderer.vue?vue&type=script&lang=js"
import style0 from "./action-button-renderer.vue?vue&type=style&index=0&id=2b55d987&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b55d987",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/graphileon/graphileon/release/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b55d987')) {
      api.createRecord('2b55d987', component.options)
    } else {
      api.reload('2b55d987', component.options)
    }
    module.hot.accept("./action-button-renderer.vue?vue&type=template&id=2b55d987&scoped=true", function () {
      api.rerender('2b55d987', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/renderers/action-button-renderer/action-button-renderer.vue"
export default component.exports