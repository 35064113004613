
	import EventInterface from "utils/src/event-interface";
	import {checkType, isStringOrNumber} from "utils/src/validation";
	import _, {clone, filter, isObject, values, isEqual, isFunction, forEach} from 'lodash';
	import Log from "utils/src/log";
	import IGraphileon from "core/src/i-graphileon";
	import VueView from "client/src/vue-view";
	const ScopedPolyfill = require('client/libraries/style-scoped-polyfill');

	const log = Log.instance("client/vue-view/component");

	export default {
		name: "vue-view-component",
		data() {
			return {
				model: {
					container: {},
					area: '',
					css: '',
					disableContextMenu: {}
				},
				hasStyles: false,
				contextMenus: {},
				currentContextTarget: undefined,
				currentContextMenu: undefined,
				batchTriggers: [],
				errors: {},
				messages: {},
				modelChanges: {},
				closed: false
			}
		},
		computed: {
			validContainer() {
				const container = _.cloneDeep(this.check(this.model.container, 'object', 'container',
					given => isStringOrNumber(given) ? {id: given} : {}
				));
				if(!('title' in container)) {
					container.title = this.model.name;
				}

				// Copy $css to $container.css if that is missing to be used in container creation
				if (_.isString(this.model.css)) {
					container.css = container.css || this.model.css;
				}

				return container;
			},
			validArea() {
				return this.check(this.model.area, 'string', 'area', '');
			},
			visibleBatchTriggers() {
				let batchTriggers = _.map(this.batchTriggers, (trigger) => {
					return _.extend(
						_.clone(trigger), 
						{enable: this.evaluate(_.get(trigger, 'enable', true))}
					);
				})

				return filter(batchTriggers, trigger => {
					return ((this.evaluate(_.get(trigger, 'condition', true))) 
						&& (this.evaluate(_.get(trigger, 'show', true))));
				});
			}
		},
		watch: {
			validContainer: {
				deep: true,
				handler(container) {
					this.fire(VueView.Event.Out.CONTAINER_CHANGED, container);
				}
			}
		},
		methods: {
			/* For Override */
			getParameters() {
			},
			resize() {
			},
			ready() {
			},
			onForceUpdate() {
			},
			onSetDependencies(dependencies) {
			},
			onClose() {
			},
			/* End For Override */

			getViewCommonConfig() {
				return {
					batchTriggers: this.visibleBatchTriggers,
					contextMenus: this.contextMenus,
					disableContextMenu: this.model.disableContextMenu,
					messages: this.messages,
					onBatchTriggerClick: this.onBatchTriggerClick,
					onContextMenuClick: this.onContextMenuClick,
					onContextMenuClose: this.onContextMenuClose,
					onCloseMessage: this.onCloseMessage
				}
			},
			forceUpdate() {
				this.$forceUpdate();
				this.onForceUpdate();
			},
			getAreaSpecs() {
				return this.validArea;
			},
			getContainerSpecs() {
				return this.validContainer;
			},
			check(value, type, name, defaultValue, warn) {
				// We check the type, but catch the error so we can store it
				try {
					const checked = checkType(value, type, name, defaultValue, warn);
					this.clearError(name);
					return checked;
				} catch(e) {
					this.setError(name, e.message, 3000);
					if(isFunction(defaultValue)) {
						defaultValue = defaultValue(value);
					}
					return defaultValue;
				}
			},
			defineContextMenu(name, ref) {
				this.contextMenus[name] = {
					items: [],
					target: undefined,
					filter: (item) => {
						return !item.condition || this.evaluate(item.condition, {
							target: this.currentContextTarget
						})
					}
				};
			},
			openContextMenu(menu, event, target) {
				checkType(menu, 'string', 'name');
				checkType(event, Event, "event");

				if(!(menu in this.contextMenus)) {
					log.error(`Undefined context menu '${menu}'.`);
					return;
				}

				if(!('common' in this.$refs)) {
					log.error("No 'common' component found. Cannot open context menu.");
					return;
				}

				this.closeContextMenu();

				if(this.$refs.common.openContextMenu(menu, event, target)) {
					this.currentContextTarget = target;
					this.currentContextMenu = menu;
				}
			},
			closeContextMenu() {
				this.currentContextTarget = null;

				if(!('common' in this.$refs)) {
					log.error("No 'common' component found. Cannot open context menu.");
					return;
				}

				this.$refs.common.closeContextMenu(this.currentContextMenu);
			},
			/**
			 *
			 * @param {string} name		Unique error name.
			 * @param {string} message	Error message.
			 * @param {number} visualTimeout	Timeout after which error should be hidden.
			 * @param {boolean} clearErrorAfterTimeout	If set to true, will also clear the error itself after timeout. Otherwise will only hide.
			 */
			setError(name, message, visualTimeout = undefined, clearErrorAfterTimeout = false) {
				if(name in this.errors) return; // no need to set the same error twice

				// Using $set to change reactively
				this.$set(this.errors, name, message);
				this.showMessage(name, 'danger', message, visualTimeout);

				if(clearErrorAfterTimeout && visualTimeout) {
					setTimeout(()=>{
						this.clearError(name);
					}, visualTimeout);
				}
			},
			clearError(name) {
				this.reactiveDelete('errors', name);
				this.clearMessage(name);
			},
			showMessage(name, type, message, timeout = 5000) {
				if(type === 'danger') {
					log.error(message);
				}
				if(type === 'warning') {
					log.warn(message);
				}
				this.$set(this.messages, name, {text: message, name, type});
				if(timeout) {
					setTimeout(()=>{
						this.clearMessage(name);
					}, timeout);
				}
			},
			clearMessage(name) {
				this.reactiveDelete('messages', name);
			},
			reactiveDelete(dataKey, deleteKey) {
				const obj = this[dataKey];
				if(!isObject(obj) || !(deleteKey in obj)) return;

				const newObject = clone(obj);
				delete newObject[deleteKey];
				this[dataKey] = newObject;
			},
			render() {
				this.$rootElement = document.createElement('div');
				this.$rootElement.className = 'vue-view';

				const mountElement = document.createElement('div');
				this.$rootElement.append(mountElement);
				this.$mount(mountElement);

				return this.$rootElement;
			},
			/**
			 * Tells the Function to close.
			 *
			 * Closure cycle:
			 * Container.close -> VueViewComponent.close -> VueView.close
			 * VueViewComponent.closeComponent -> Container.destroy
			 */
			close() {
				this.closed = true;
				this.$function.close();
				this.$destroy();
			},
			/**
			 * Notifies any listeners of the component (i.e. Container) of the close event.
			 */
			closeComponent() {
				this.fire(VueView.Event.Out.COMPONENT_CLOSED);
				this.onClose();
			},
			on(event, callback) {
				this.$eventInterface.on(event, callback);
			},
			fire(event, data) {
				this.$eventInterface.fire(event, data);
			},
			removeListener(event, callback) {
				this.$eventInterface.off(event, callback);
			},
			setFunction(func) {
				this.$function = func;
			},
			setDependencies(dependencies) {
				this.$dependencies = dependencies;
				this.$graphileon = dependencies.get(IGraphileon);

				this.onSetDependencies(dependencies);
			},
			setModel(model) {
				this.model = model;
			},
			setModelValue(pathString, value, notify = true) {
				const changed = this.set(this.model, pathString, value);
				if(notify && changed && !this.closed) {
					this.notifyModelChange(pathString, value);
				}
				return changed;
			},
			set(obj, pathString, value) {
				const path = pathString.split('.');
				if(path.length === 0) {
					console.error(`Invalid path '${pathString}'.`);
					return;
				}
				const step = path[0];

				// Recursion
				if(path.length > 1) {
					// Create objects along the path if necessary
					if(!isObject(obj[step])) {
						obj[step] = {};
					}
					path.shift();
					return this.set(obj[step], path.join('.'), value);
				}

				// Set final step
				const changed = !isEqual(obj[step], value);
				this.$set(obj, step, value);
				return changed;
			},
			notifyModelChange(path, newValue) {
				// Collect change
				this.$set(this.modelChanges, path, newValue);

				// Next tick, send all collected changes in Trigger event
				this.$nextTick(()=>{
					if(Object.keys(this.modelChanges).length) {
						this.trigger({
							type: 'functionUpdated',
							data: this.modelChanges,
							changes: Object.keys(this.modelChanges)
						});
					}
					// Empty changes
					this.modelChanges = {};
				});
			},
			setContextMenuItems(menus) {
				forEach(menus, (menu, name) => {
					if(!(name in this.contextMenus)) {
						log.error(`Undefined context menu '${name}'.`);
						return;
					}
					this.contextMenus[name].items = values(menu);
				});
			},
			setBatchTriggers(batchTriggers) {
				this.batchTriggers = batchTriggers;
			},
			setContainerState(property, value) {
				this.setModelValue(`container.${property}`, value);
			},
			evaluate(value, context = {}) {
				return this.$function.evaluate(value, context);
			},
			setDeepModelUpdateHandler(path, callback) {
				return this.$function.setDeepModelUpdateHandler(path, callback);
			},
			getFunctionID() {
				return this.$function.getId();
			},
			getInstanceID() {
				return this.$function.getInstanceID();
			},
			translate(string, options) {
				return this.$graphileon.translate(string, options);
			},
			t(string, options) {
				return this.translate(string, options);
			},
			trigger(event) {
				this.$function.executeTriggers(event);
			},
			triggerById(id, data) {
				this.$function.executeTriggerById(id, data);
			},
			completeTriggerData(event) {
				const completed = clone(event); // to make completeTriggerData a pure function
				this.$function.alterTriggerData(completed);
				return completed;
			},
			onContextMenuClick(target, id) {
				this.triggerById(id, {type: 'context', target});
				this.closeContextMenu();
			},
			onContextMenuClose() {
				this.currentContextMenu = undefined;
				this.currentContextTarget = undefined;
			},
			onBatchTriggerClick(id) {
				this.triggerById(id);
			},
			onCloseMessage(name) {
				this.clearMessage(name);
			}
		},
		created() {
			this.$function = null;
			this.$dependencies = null;
			this.$eventInterface = new EventInterface();
		},
		mounted() {
			this.$graphileon.on('languageLoaded', () => {
				this.forceUpdate();
			});
			this.$graphileon.on('languageChanged', () => {
				this.forceUpdate();
			});
		}
	}
