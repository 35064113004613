export default function registerDefaultRenderers(viewmanager) {
	viewmanager.register(require('client/src/renderers/table-view-renderer'));
	viewmanager.register(require('client/src/renderers/network-view-renderer'));
	viewmanager.register(require('client/src/renderers/html-view-renderer'));
	viewmanager.register(require('client/src/renderers/properties-view-renderer'));
	viewmanager.register(require('client/src/renderers/search-view-renderer'));
	viewmanager.register(require('client/src/renderers/neighbours-view-renderer'));
	viewmanager.register(require('client/src/renderers/node-form-view-renderer'));
	viewmanager.register(require('client/src/renderers/relation-form-view-renderer'));
	viewmanager.register(require('client/src/renderers/error-renderer'));
	viewmanager.register(require('client/src/renderers/network-styles-view-renderer'));
	viewmanager.register(require('client/src/renderers/prompt-renderer'));
	viewmanager.register(require('client/src/renderers/grid-view-renderer'));
	viewmanager.register(require('client/src/renderers/login-view-renderer'));
	viewmanager.register(require('client/src/renderers/permission-view-renderer'));
	viewmanager.register(require('client/src/renderers/markdown-view-renderer'));
	viewmanager.register(require('client/src/renderers/diff-view-renderer'));
	viewmanager.register(require('client/src/renderers/ag-grid-view-renderer'));
	viewmanager.register(require('client/src/renderers/yfiles-view-renderer').default);
	viewmanager.register(require('client/src/renderers/yfiles-isometric-renderer/yfiles-isometric-renderer').default);
	viewmanager.register(require('client/src/renderers/team-edit-view-renderer'));
	viewmanager.register(require('client/src/renderers/set-password-view-renderer'));
	viewmanager.register(require('client/src/renderers/markdown-edit-view-renderer'));
	viewmanager.register(require('client/src/renderers/ifc-3d-view-renderer').default);
	viewmanager.register(require('client/src/renderers/layout-view-renderer'));
	viewmanager.register(require('client/src/renderers/image-map-view-renderer'));
	viewmanager.register(require('client/src/views/calendar-view/calendar-view-renderer'));
	viewmanager.register(require('client/src/views/chart-view/chart-view-renderer'));
	viewmanager.register(require('client/src/views/chartjs-view/chartjs-view-renderer'));
	viewmanager.register(require('client/src/views/form-view/renderer/form-view-renderer'));
	require('client/src/views/map-view/map-view.css');
	viewmanager.register(require('client/src/views/map-view/map-view-renderer'));
	viewmanager.register(require('client/src/views/leaflet-map-view/leaflet-map-view-renderer'));
	viewmanager.register(require('client/src/views/timeline-view/timeline-view-renderer'));
	viewmanager.register(require('client/src/views/tree-view/tree-view-renderer'));

}