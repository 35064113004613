const ViewRenderer = require('../view-renderer');
const Language = require('core/src/language').default;
const ISession = require("core/src/i-session").default;

const _ = require('core/src/utils/legacy');

/* Inheritance and constructor */

const ErrorRenderer = function(dependencies) {
	ViewRenderer.call(this, dependencies);
	this.language = dependencies.get(Language);
	this.session = dependencies.get(ISession);
};
ErrorRenderer.viewType = 'Error';
ErrorRenderer.prototype = Object.create(ViewRenderer.prototype);

require('../css/error-renderer.css');

/* Methods */

ErrorRenderer.prototype._renderError = function(error, level) {
	var check = _.validate({
		error: [error, error instanceof _.Error, "Invalid error"],
		level: [level, 'isNumber', {default: 0, warn: _.def(level)}]
	});
	if(!check.isValid()) return 'Invalid error.';
	const valid = check.getValue();

	var $errorDiv = $('<div>').addClass('error-level border-start ps-2 error-level-' + valid.level);

	// Message
	var $errorMessage = $('<div>').addClass('error-message mb-1').html(valid.error.message);
	$errorDiv.append($errorMessage);

	// Error map
	if(_.isObject(valid.error.errorMap)) {
		var $errorMap = $('<div>').addClass('error-map error-original ps-2');
		var $errorTable = $('<table cellspacing="0" cellpadding="0">').addClass('error-table');
		$errorMap.append($errorTable);

		var __addRow = function(key, value) {
			var $row = $('<tr>');
			var $keyCell = $('<td>').html(key).addClass('error-key');
			var $msgCell = $('<td>').html(value).addClass('error-message mb-1');
			$row.append($keyCell);
			$row.append($msgCell);
			$errorTable.append($row);
		};

		var count = 0;
		var max = valid.error.code === 'validation-array' ? 3 : Infinity;
		for(var key in valid.error.errorMap) {
			__addRow(key + ": ", this._renderError(valid.error.errorMap[key], valid.level + 1));
			if(++count >= max) {
				__addRow('', '...');
				break;
			}
		}

		$errorDiv.append($errorMap);
	}

	// Render the original error
	if(valid.error.originalError instanceof _.Error) {
		this._renderOriginalError(valid.error.originalError, valid.level + 1, $errorDiv);
	}

	return $errorDiv;
};

ErrorRenderer.prototype._renderOriginalError = function(error, level, $errorDiv){
	let $original = $('<div>').addClass('error-original ps-2');
	var $subErr = this._renderError(error, level);
	$original.append($subErr);

	$errorDiv.append($original);
}

ErrorRenderer.prototype.doRender = function(data) {
	var check = _.validateObject("Render Data", data, {
		error: [_.instanceOf(_.Error), "Invalid error.", {default: new _.Error("An error occurred.")}]
	}, "Could not render error.");
	if(!check.isValid()) return false;
	var valid = check.getValue();

	var $error = $('<div>').addClass('ErrorView error').show();
	$error.show();

	let errorToRender = valid.error;
	const userPermissions = _.get(this.session, 'user.permissions');
	if (! _.includes(userPermissions, 'root') && ! _.includes(userPermissions, 'debug')) {
		errorToRender = valid.error.getPublicInfo();
	}
	var $errorRender = this._renderError(errorToRender);
	if($errorRender === '') { // if the error was not public, display default error
		$errorRender = this.language.translate('An error occurred.');
	}
	$error.append($errorRender);

	return $error;
};

module.exports = ErrorRenderer;
