
	import _ from 'lodash';
	import 'cleave.js';
	import DeepStruct from "core/src/utils/deep-struct";
	import FieldButton from "./fieldButton.vue";
	import FieldCodeEditor from "./fieldCodeEditor.vue";
	import FieldFileRead from "./fieldFileRead.vue";
	import FieldFileUpload from "./fieldFileUpload.vue";
	import FieldJsonViewer from "./fieldJsonViewer.vue";
	import FieldSeparator from "./fieldSeparator.vue";
	import FieldTreeSelect from "./fieldTreeSelect.vue";
	import 'ion-rangeslider';
	import 'ion-rangeslider/css/ion.rangeSlider.css';
	import Log from "utils/src/log";
	import "spectrum-colorpicker";
	import "spectrum-colorpicker/spectrum.css";
	import Vue from 'vue';
	import VueFormGenerator from 'vue-form-generator';
	import VueViewComponent from "client/src/vue-view/vue-view-component.vue";
	import ViewCommon from "client/src/vue-view/view-common.vue";
	import "./input-view.css"

	const log = Log.instance("client/view/input");

	Vue.component("fieldButton", FieldButton);
	Vue.component("fieldCodeEditor", FieldCodeEditor);
	Vue.component("fieldFileRead", FieldFileRead);
	Vue.component("fieldFileUpload", FieldFileUpload);
	Vue.component("fieldJsonViewer", FieldJsonViewer);
	Vue.component("fieldSeparator", FieldSeparator);
	Vue.component("fieldTreeSelect", FieldTreeSelect);
  
	const FIELD_TYPES = [
		'button',
		'checkbox',
		'checklist',
		'input',
		'label',
		'radios',
		'select',
		'submit',
		'textArea',
		'cleave',
		'selectEx',
		'rangeSlider',
		'spectrum',
		'switch',
		'treeSelect',
		'fileUpload',
		'fileRead',
		'separator',
		'codeEditor',
		'jsonViewer'
	];


	export default {
		name: "input-view",
		mixins: [VueViewComponent],
		components: {
			"vue-form-generator": VueFormGenerator.component,
			"view-common": ViewCommon
		},

		data() {
			return {
				parsedSchema: {},
				isValid: null
			}
		},

		watch: {
			'model.updateDelay': function (value) {
				this.setTriggerChangeEventDebounced();
			},

			'model.data': {
				deep: true,
				handler: function (value) {
					// Set default values specified in each field
					this.model.data = _.defaultsDeep(
						_.isObjectLike(this.model.data) ? this.model.data : {},
						this.getDefaultDataFromSchema(this.model.schema)
					);

					if (this.validateAfterChanged()) {
						_.defer(() => this.validate());
					}
				},
			},

			'model.schema': {
				deep: true,
				handler: function(schema) {
					// All the fields are regenerated/re-rendered when we do this. Some internal state of VueFormGenerator will be invalid (ex: errors)
					this.parsedSchema = this.parseSchema(schema);

					// KNOWN ISSUE: if validateAfterChanged is `true` but validateAfterLoad is `false` when we update the schema all errors will be cleared. 
					// This means that if user edited some fields and left them with errors when the schema is updated the errors will be cleared and it might be a bit confusing. 
					// This should happen very rarely in normal use. Users should have the goal to fill the form correctly.
					// Form will be validated on submit so all the errors will re-appear then, if there are any.
					// This issue can be fixed by getting current errors here and triggering re-validation only on the fields that have errors. 
					// The problem is finding those fields inside vfgInstance.It requires digging inside the state of vfgInstance and this is not nice.

					// Resync errors 
					if (this.validateAfterLoad()) {
						_.defer(() => this.validate());
					}
				}
			}
		},

		methods: {
			getParameters() {
				return {
					data: null,
					schema: {},
					options: {},
					updateDelay: 500 //ms
				};
			},

			ready() {
				// Initial or previous state of the data model for diff purposes on change event
				this.lastData = this.getDataClone();
				this.changes = {};
				this.isValid = null;
				this.errors = [];
				this.setTriggerChangeEventDebounced();

				// We don't want to trigger `change` events with outside updates (data updated by trigger)
				// Monitor outside data changes and update the lastData state 
				this.setDeepModelUpdateHandler('data', (path, changes) => {
					// we want to get the model state after `watch` has done it's job
					_.defer(() => this.lastData = this.getDataClone());
				});

			},

			setTriggerChangeEventDebounced() {
				this.triggerChangeEventDebounced = _.debounce(this.triggerChangeEvent, this.model.updateDelay);
			},

			getDataClone() {
				// we want the actual values, not proxies
				return _.cloneDeep(this.model.data);
			},

			async validate() {
				if (! _.get(this, '$refs.vfgInstance.validate')) {
					return [];
				}

				await this.$refs.vfgInstance.validate()
				let result = this.$refs.vfgInstance.errors;
				return result;
			},

			validateAfterLoad() {
				return _.get(this, 'model.options.validateAfterLoad') === true;
			},

			validateAfterChanged() {
				return _.get(this, 'model.options.validateAfterChanged') === true;
			},

			// called debounced by triggerChangeEventDebounced
			async triggerChangeEvent() {
				let currentModel = this.getDataClone();
				let changes = this.changes;
				this.changes = {};

				let errors = await this.validate();
				// Call `change` for each changed key
				_.forEach(changes, (value, key) => {
					// Prevent firing the event for NON changes (sometimes it happens)
					if (value === _.get(this.lastData, key)) {
						return
					}

					// Possible issue: If multiple fields use the same model key this will only return the first field found
					let fieldPath = DeepStruct.findKeyDeep(this.model.schema, _.matches({model: key}));

					this.trigger({
						type: 'change',
						value,
						key,
						fieldPath: _.concat(['schema'], fieldPath),
						fieldKey: _.last(fieldPath),
						isValid: this.isValid,
						oldValue: _.get(this.lastData, key),
						data: currentModel
					});
				});

				this.lastData = currentModel;
			},

			// This is a stub, it is set/updated by setTriggerChangeEventDebounced (on ready and when updateDelay changes)
			triggerChangeEventDebounced() {},

			onModelUpdated(value, key){
				this.changes[key] = value;
				this.triggerChangeEventDebounced();
			},

			onValidated(isValid, errors) {
				this.isValid = isValid;
				this.errors = _.cloneDeep(errors);
				this.lastValidation = _.now();
			},

			// called when submit buttons are clicked
			async triggerSubmit(action, field, event) {
				event.preventDefault();
				let errors = await this.validate();

				this.trigger({
						type: 'submit',
						action,
						field: _.get(this.model.schema, field._fieldPath, field),
						fieldPath: _.concat(['schema'], field._fieldPath),
						fieldKey: _.last(field._fieldPath),
						data: this.getDataClone(),
						isValid: _.isEmpty(errors),
						errors: errors
					});
			},

			// called when a button is clicked
			triggerButton(action, button) {
				this.trigger({
						type: 'button',
						action,
						button,
						data: this.getDataClone(),
						isValid: this.isValid,
						errors: this.errors
					});
			},

			// called when searching an ASYNC treeSelect
			triggerTreeSearch(searchString, field) {
				this.trigger({
						type: 'treeSearch',
						search: searchString,
						field,
						fieldPath: _.concat(['schema'], field._fieldPath),
						fieldKey: _.last(field._fieldPath),
						data: this.getDataClone(),
						isValid: this.isValid,
						errors: this.errors
					});
			},			

			// returns a data structure with default values for fields declared in schema
			getDefaultDataFromSchema(schema, data = {}) {
				return DeepStruct.reduceDeep(
					schema,
					(result, value, path) => {
						if (_.has(value, 'model') && _.has(value, 'default')) {
							return _.set(result, value.model, value.default);
						}
						return result;
					},
					{}
				);
			},

			parseSchema(schema) {
	
				let updateSchema = (path, value) => {
					path = _.isString(path) ? _.toPath(path) : path;
					let fullPath = _.join(_.concat(['schema'], path), '.');
					this.setModelValue(fullPath, value);
				}

				let isValidField = (field, key) => {
					if (! _.includes(FIELD_TYPES, field.type)) {
						log.error(`Field "${key}" does not have a valid type`, {'Field schema': field, 'Valid field types': FIELD_TYPES});
						return false;
					}

					return true;
				}

				// Return only valid fields as array (what vue-form-generator expects)
				let processFields = (fields, path) => {
					if (_.last(path) !== 'fields') {
						return fields;
					}

					if (_.isArray(fields)) {
						fields = _.map(
							fields, 
							(field, key) => _.set(field, '_fieldPath', _.concat(path, key))
						)
					}
					else if (_.isObjectLike(fields)) {
						fields = _.mapValues(
							fields, 
							(field, key) => _.set(field, '_fieldPath', _.concat(path, key))
						)
					}

					const validFields = _.filter(fields, isValidField);

					/* 
					/**	Add bootstrap classes to default elements.
					/**	field.attributes.input.class replaces all classes on the "input" (input mensioned here is not standart html input element, it may refer to a select, radio, checkbox, etc. element according to type field in the schema).
					/**	fieldClasses is not a field mentioned in documentation. Refer to library code for each component
						on how it applies for that field. This approach is useful because for some components (exp. radios)
						library adds unintented attributes or class="[object Object]" to wrapper of that "input"
					*/
					_.forEach(validFields, (field) => {
						if (!_.isNil(_.get(field, 'buttons'))) {
							_.forEach(field.buttons, (button) => {
								if (!_.isNil(_.get(button, 'classes'))) {
									return;
								}

								button.classes = 'btn btn-light';
							})
						}

						if (!_.isNil(_.get(field, 'attributes.class')) || !_.isNil(_.get(field, 'attributes.input.class'))) {
							return;
						}

						if (field.type == 'checkbox') {
							if (_.isNil(_.get(field, 'attributes.input'))) {
								_.set(field, 'attributes.input', { class: "form-check-input"});
							} else {
								_.set(field, 'attributes.input.class', "form-check-input");
							}
						}
						if (field.type == 'radios') {
							// setting attributes.input.class as done in other field does not work for radios. refer to library code for what exactly fieldClasses set on radios.
							field.fieldClasses = "form-check-input me-2"
						}
						if (field.type == 'select') {
							if (_.isNil(_.get(field, 'attributes.input'))) {
								_.set(field, 'attributes.input', { class: "form-select"});
							} else {
								_.set(field, 'attributes.input.class', "form-select");
							}
						}
						if (field.type == 'submit') {
							if (_.isNil(_.get(field, 'attributes.input'))) {
								_.set(field, 'attributes.input', { class: "btn btn-primary"});
							} else {
								_.set(field, 'attributes.input.class', "btn btn-primary");
							}
						}

						
					})

					//return only valid fields as array (we accept objects too but vue-form-generator expects only array)
					return validFields;
				}

				// if field is type submit then add onSubmit method that triggers Graphileon event
				let processSubmit = (field, path) => {
					if (_.get(field, 'type') !== 'submit') {
						return field;
					}

					let _field = _.extend({}, field, {
						onSubmit: (formData, fld, event) => {
							this.triggerSubmit(field.action, _field, event);
							if (_.isNumber(field.throttle) && field.throttle > 0) {
								let oldValue = false;
								if (field.disabled) {
									oldValue = field.disabled;
								}
								updateSchema(_.concat(path, 'disabled'), true);
								setTimeout(
									() => {
										updateSchema(_.concat(path, 'disabled'), oldValue);
									},
									field.throttle
								)
							}

							if (field.disableOnClick) {
								updateSchema(_.concat(path, 'disabled'), true);								
							}
						}
					});

					return _field;
				}

				// add to buttons onclick method that triggers Graphileon event
				let processButtons = (buttons, path) => {
					if (_.last(path) !== 'buttons') {
						return buttons;
					}

					// Also transforms `buttons` from object to array
					return _.map(
						buttons, 
						(button) => _.set(button, 'onclick', () => this.triggerButton(button.action, button))
					);
				}

				let processValidator = (validator, path) => {
					if (_.last(path) !== 'validator') {
						return validator;
					}

					// Process each item in array as validator
					if (_.isArray(validator)) {
						return _.map(validator, (validator) => processValidator(validator, ['validator']));
					}

					// Allow customization of validation error messages with {validator: 'validator-name', messages: {...}}
					if (_.isPlainObject(validator) && _.has(validator, 'validator') && _.has(validator, 'messages')) {
						let validatorLocale = _.get(
							VueFormGenerator.validators, 
							[validator.validator, 'locale'],
							() => {throw new Error(`${validator.validator} is not a valid validator`)}
						);

						return validatorLocale(validator.messages);
					}

					return validator;
				}

				let processTreeSelect = (field, path) => {
					if (_.get(field, 'type') !== 'treeSelect') {
						return field;
					}

					field.onSearchChange = _.debounce(
						(searchString) => {
							this.triggerTreeSearch(searchString, field);
						}, 
						this.model.updateDelay
					);

					return field;
				}

				let processButton = (field, path) => {
					if (_.get(field, 'type') !== 'button') {
						return field;
					}

					field.onButtonClick = () => {
						this.triggerButton(field.action, field);
					}

					return field;
				}

				let parsedSchema =  DeepStruct.mapDeep(
					schema, 
					(value, path, struct) => {
						// ignore simple values
						if (! _.isObjectLike(value)) return value;
						return _.reduce(
							[processFields, processSubmit, processButtons, processButton, processValidator, processTreeSelect],
							(result, func) => func(result, path),
							value
						)
					},
					// to keep the original paths (to values) in the callback (because the callbacks transform some objects to arrays (fields for example))
					DeepStruct.LEAVES_FIRST
				);

				return parsedSchema;
			}
		}
	}
