var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    class: _vm.schema.buttonClasses,
    attrs: { type: "button", disabled: _vm.disabled },
    domProps: { innerHTML: _vm._s(_vm.schema.caption) },
    on: { click: _vm.onClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }