const ensure = require('utils/src/validation').ensure;
const FullCalendar = require('@fullcalendar/core');
const dayGridPlugin = require('@fullcalendar/daygrid').default;
const timeGridPlugin = require('@fullcalendar/timegrid').default;
const bootstrap5Plugin = require('@fullcalendar/bootstrap5').default;
const interactionPlugin = require('@fullcalendar/interaction').default;

const ViewRenderer  = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/calendarviewrenderer");

const CalendarViewRenderer = function () {
	ViewRenderer.call(this);
};
CalendarViewRenderer.viewType = 'CalendarView';
CalendarViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* Properties */

CalendarViewRenderer.prototype.calendar = null;

/* Methods */

/**
 * Creates a 'clean' copy of an event, to pass between Functions.
 * @param {object} event
 * @returns {object}
 */
CalendarViewRenderer.prototype._cleanEvent = function (event) {
	const cleanEvent = {
		id: event.id,
		title: event.title,
		start: event.startStr,
		end: event.endStr,
		allDay: event.allDay,
		classNames: event.classNames
	};

	return cleanEvent;
};

/**
 * @override
 * @param {object} data
 * @returns {jQuery}
 */
CalendarViewRenderer.prototype.doRender = function (data) {
	var self = this;

	var events = ensure(data.events, _.isArray, {});

	// Initialize Calendar
	this.calendarEl = document.createElement("div");
	this.calendarEl.classList.add('calendarview');

	this.calendar = new FullCalendar.Calendar(this.calendarEl, {
		plugins: [ dayGridPlugin, timeGridPlugin, bootstrap5Plugin, interactionPlugin ],
		themeSystem: 'bootstrap5',
		initialView: 'dayGridMonth',
		headerToolbar: {
			left: 'prev,next, today',
			center: 'title',
			right: 'dayGridMonth,timeGridWeek,timeGridDay'
		},
		editable: false,
		events: events,
		eventClick: function(info) {
			self.userEvent({
				type: 'eventClick',
				event: self._cleanEvent(info.event)
			});
		},
		eventDidMount: function(info) {
			info.el.addEventListener("mousedown", (e) => {
				if (e.button != 2) {
					return;
				}
				self.openContextMenu("event", self._cleanEvent(info.event), e.clientX, e.clientY);
			});
		},
		dateClick: function(info) {
			self.userEvent({
				type: 'dayClick',
				date: info.dateStr
			});
		},
		dayCellDidMount: function(info) {
			info.el.addEventListener("mousedown", (e) => {
				if (e.button != 2) {
					return;
				}
				if (_.includes(e.target.classList.value, 'fc-event')) {
					return;
				}
				const year = info.date.getFullYear();
				const month = _.padStart(info.date.getMonth() + 1, 2, '0');
				const day = _.padStart(info.date.getDate(), 2, '0');
				const date = `${year}-${month}-${day}`;
				self.openContextMenu("day", date, e.clientX, e.clientY);
			});
		}
	});
	
	// Disable browser context menu
	this.calendarEl.addEventListener("contextmenu", (e) => {
		e.preventDefault();
	});

	this.calendar.render();
	return this.calendarEl;
};

CalendarViewRenderer.prototype.doResize = function (width, height) {
	const self = this;
	if (!this.calendar) {
		return;
	}
	setTimeout(function () {
		self.calendar.updateSize();
	});
	return;
};

CalendarViewRenderer.prototype.onReady = function () {
	// Fullcalendar doing hit testing against our invisible wrapper. Make wrapper have size so hit tests can work.
	const viewrendererOutput = this.calendarEl.closest('.graphileon-viewrenderer-output');
	viewrendererOutput.style.display = 'block';
	viewrendererOutput.style.height = '100%';
	viewrendererOutput.style.width = '100%';
	return;
};

module.exports = CalendarViewRenderer;
