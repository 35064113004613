var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-messages" },
    [
      _c(
        "transition-group",
        { attrs: { name: "list", tag: "div" } },
        _vm._l(_vm.messages, function (message) {
          return _c(
            "div",
            {
              key: message.name,
              class: `alert alert-${message.type}`,
              attrs: { role: "alert" },
            },
            [
              _vm._v("\n\t\t\t" + _vm._s(message.text) + "\n\t\t\t"),
              _c(
                "button",
                {
                  staticClass: "close btn btn-sm btn-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onClick(message.name)
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }