const ViewRenderer = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/mapviewrenderer");
const {checkType} = require('utils/src/validation');
const FTI = require('core/src/fti');

const _ = require('core/src/utils/legacy');

const MapViewRenderer = function(dependencies) {
	ViewRenderer.call(this);
	this.fti = dependencies && dependencies.get(FTI);


	this._markers = [];
	this._geoFeatures = [];
	this._markerData = new Map();
	this._markerCallbacks = [];
	this._callbackID = undefined;
	this._markerAnimation = 'DROP';
	this._googleMapsKey = _.get(this.fti, 'appInfo.googleMapsKey');
};
MapViewRenderer.viewType = 'MapView';
MapViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* PROPERTIES */

MapViewRenderer.prototype._markerAnimation = undefined;
MapViewRenderer.prototype._autoFit = undefined;
MapViewRenderer.prototype._map = undefined;
MapViewRenderer.prototype._markers = [];
MapViewRenderer.prototype._geoFeatures = undefined;
MapViewRenderer.prototype._markerData = undefined;
MapViewRenderer.prototype._markerCallbacks = undefined;
MapViewRenderer.prototype._markerSpiderfier = undefined;
MapViewRenderer.prototype._spiderfierConfig = undefined;

/* METHODS */

/* Protected */

MapViewRenderer.prototype._checkMarker = function(marker) {
	let valid = true;
	if(!_.isObject(marker)) {
		log.warn("Marker is not an object.", marker);
		valid = false;
	}
	if( !_.has(marker, 'id') || !(_.isInteger(marker.id) || _.isString(marker.id)) ) {
		log.warn("Invalid marker id.", marker.id);
		valid = false;
	}
	if(!$.isNumeric(_.get(marker, 'lat'))) {
		log.warn("Invalid marker latitude (lat).", marker.lat);
		valid = false;
	}
	if(!$.isNumeric(_.get(marker, 'long'))) {
		log.warn('Invalid marker longitude (long).', marker.long);
		valid = false;
	}
	if(!(_.isString(marker.title) || $.isNumeric(marker.title) || marker.title === undefined)) {
		log.warn('Invalid marker title.', marker.info);
		valid = false;
	}
	if(!(_.isString(marker.info) || $.isNumeric(marker.info) || marker.info === undefined)) {
		log.warn('Invalid marker info.', marker.info);
		valid = false;
	}
	return valid;
};

/* Public */

MapViewRenderer._markerCallbacks = {};

/**
 *
 */
MapViewRenderer.markerCallback = function(callbackID, markerID) {
	let func = MapViewRenderer._markerCallbacks[callbackID];
	if(!_.isFunction(func)) {
		log.error("Marker onClick function not registered.", callbackID);
		return;
	}
	func(markerID);
};

/**
 * Registers a callback statically, so it can be accessed from a function in the
 * page, e.g. an onClick event of a DOM element.
 * @param {function} callback
 * @returns {string}
 */
MapViewRenderer.prototype._registerCallback = function(callback) {
	let callbackID = _.uniqueId();

	// Register globally
	MapViewRenderer._markerCallbacks[callbackID] = callback;
	// Register the ID locally
	this._markerCallbacks.push(callbackID);

	return callbackID;
};

MapViewRenderer.prototype.addGeoJson = function (geoJson, style) {
	const self = this;
	let type = geoJson.type;

	if(type === 'Feature') {
		geoJson = {
			"type":"FeatureCollection",
			"features":[geoJson]
		}
	}

	if(!_.isArray(geoJson.features)) {
		if(type === 'FeatureCollection' && geoJson.features !== undefined) {
			log.error("FeatureCollection should be an array.");
		}
		geoJson.features = [];
	}
	_.forEach(geoJson.features, feature => {
		let id = feature.id;
		if (_.isNil(id)) {
			id = _.uniqueId('feature-');
			_.set(feature, 'id', id);
		}
		// Set id property inside of properties in order to let 
		// the map object handle it
		_.set(feature, 'properties.id', id);
		let mapFeature = self._map.data.addGeoJson(feature, { idPropertyName: 'id' });
		let featureStyle = _.clone(style);
		self._map.data.overrideStyle(mapFeature[0], _.merge(featureStyle, _.get(feature,"style")));
		self._geoFeatures.push(mapFeature[0])
	})
}

MapViewRenderer.prototype.removeFeature = function(feature) {
	let geoFeature = this._geoFeatures.find(f => f.getId() === feature.id)
	if (!geoFeature) return;
	this._map.data.remove(geoFeature);
}

/**
 * Add a marker to the map.
 * @param {object} marker Marker data.
 * @returns {undefined}
 */
MapViewRenderer.prototype.addMarker = function(marker) {
	let self = this;
	if (!this._checkMarker(marker)) {
		log.warn("Could not add marker.");
		return;
	}

	let markerData = this.prepareMarkerData(marker);
	let gmapMarker = new google.maps.Marker(markerData);
	this._markers.push(gmapMarker);
	marker.markerIndex = this._markers.length - 1;
	this._markerData.set(marker.id, marker);

	if (this._markerSpiderfier) {
		this._markerSpiderfier.addMarker(gmapMarker);
	}
	else {
		gmapMarker.addListener('click', function(event) {
			self.onClick(gmapMarker, event);
		});
	}

	// Add marker context menu
	google.maps.event.addListener(gmapMarker, 'rightclick', function(point) {
		self.openContextMenu('marker', marker, point.x, point.y);
		self.trigger({
			type: 'markerRightClick',
			marker: marker
		});
	});
};

/**
 * Update a marker information.
 * @param {object} marker Marker data.
 * @param {object} existing Marker data of existing to update.
 * @returns {undefined}
 */
 MapViewRenderer.prototype.updateMarker = function(marker, existing) {
	if (!this._checkMarker(marker)) {
		log.warn("Invalid marker object, see previous warnings.", marker);
		return;
	}

	marker = _.extend({}, existing, marker);

	let markerData = this.prepareMarkerData(marker);
	let existingMarker = this._markers[existing.markerIndex];
	
	this._markerData.set(marker.id, marker);
	existingMarker.setOptions(markerData);
};

MapViewRenderer.prototype.prepareMarkerData = function(marker) {
	let loc = new google.maps.LatLng(marker.lat, marker.long);
	const markerAnimation = _.get(marker, 'animation', this._markerAnimation);
	const animation = _.isString(markerAnimation) ? google.maps.Animation[markerAnimation] : null;
	let markerData = {
		id: marker.id,
		animation,
		position: loc,
		map: this._map,
		title: marker.title,
		icon: marker['icon'],
		spiderfiedIcon: marker['spiderfiedIcon'] || marker['icon'],
		spiderfiableIcon: marker['spiderfiableIcon'],
		unspiderfiableIcon: marker['unspiderfiableIcon'] || marker['icon']
	};
	return markerData;
}

/**
 * Get the map markers.
 * @returns {array} An array of google.map.Marker objects.
 */
MapViewRenderer.prototype.getMarkers = function() {
	return this._markers;
};

/**
 * Autofits the map around the markers.
 * @returns {undefined}
 */
MapViewRenderer.prototype.autoFit = function() {
	let self = this;

	if (! this._autoFit) {
		return;
	}

	if (_.isEmpty(this._markers) && _.isEmpty(this._geoFeatures)) {
		return;
	}

	let bounds = new google.maps.LatLngBounds();
	for(let i in this._markers) {
		let marker = this._markers[i];
		let lat = marker.getPosition().lat();
		let long = marker.getPosition().lng();
		let point = new google.maps.LatLng(lat, long);
		bounds.extend(point);
	}

	let deepForEach = (array, f) => {
		if (! _.isFunction(_.get(array, 'getArray'))) 
			return f(array);

		array.getArray().forEach((item) => deepForEach(item, f));
	}

	let extendBoundsForPoint = (point) => {
		if (_.isNil(point)) {
			return;
		}

		if ((! point.lat || ! point.lng) && (!point.g.lat || !point.g.lng)) {
			return;
		}

		if (point.g && point.g.lat && point.g.lng) {
			point = new google.maps.LatLng(point.g.lat(), point.g.lng());
		}

		bounds.extend(point);
	}

	_.forEach(this._geoFeatures, feature => {
		if(_.isFunction(_.get(feature, 'getGeometry'))) {
			deepForEach(feature.getGeometry(), extendBoundsForPoint);
		}
		else if (_.isFunction(_.get(feature, 'getPosition'))) {
			let point = new google.maps.LatLng(feature.getPosition().lat(), feature.getPosition().lng());
			bounds.extend(point);
		}
	});

	// Don't zoom in too far on only one marker
	if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
		let extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 1.0, bounds.getNorthEast().lng() + 1.0);
		let extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 1.0, bounds.getNorthEast().lng() - 1.0);
		bounds.extend(extendPoint1);
		bounds.extend(extendPoint2);
	}
	// If the padding is NOT set to 1 (or something small) sometimes the zoom-out is to much (if most of the world is inside the bounds)
	this._map.fitBounds(bounds, 1);
};

/**
 * @override
 */
MapViewRenderer.prototype.doRender = function(data) {
	let div = $('<div>');
	div.addClass('mapview-renderer');
	div.css('width', '100%');
	div.css('height', '100%');

	// Check if Google Maps loaded
	if(window.google === undefined) {
		div.html("Google Maps not loaded.");
		return div;
	}

	if (!this._googleMapsKey) {
		div.html(`The MapView Function renders a Map with locations and is based on Google Maps. It requires a Google Maps API key. To create a new API Key please see <a href="https://developers.google.com/maps/documentation/embed/get-api-key" target="_blank">this page</a>. You can set the Google Maps Api key on the Graphileon Settings page > Licenses & API keys > Google Maps API key.`);
		div.css('color', 'red');
		return div;
	}

	// Input
	this._renderData = data;

	// Map options
	let lat = _.get(data, 'center.lat') || 0;
	let long = _.get(data, 'center.long') || 0;
	let center = new google.maps.LatLng(lat, long);
	let mapOptions = {
		zoom: data.zoom || 0,
		center: center,
		draggableCursor: 'default'
	};
	this._map = new google.maps.Map(div.get()[0], mapOptions);
	this._autoFit = _.get(data ,'autoFit', true);
	this._markerAnimation = _.get(data ,'markerAnimation');
	this._spiderfierConfig = data.spiderfier;
	this._addressSearch = _.get(data , 'addressSearch');

	// Info window and marker clicking
	this._setupInteraction();

	let markerData = checkType(data.markers, "array", "markers", []);

	// Add markers
	for(let i in markerData) {
		let marker = markerData[i];
		marker.markerIndex = i;
		this.addMarker(marker);
	}

	let geoJSON = data.geoJSON || data.geoJson;
	if(geoJSON) {
		this.addGeoJson(geoJSON, data.geoJSONStyle || null);
	}

	return div;
};

/**
 * Fits the map to the markers
 * @returns {undefined}
 */
MapViewRenderer.prototype.onReady = function() {
	if(window.google === undefined) {
		return;
	}

	if (_.get(this._spiderfierConfig, 'active') === true) {
		this.addSpiderfier(this._spiderfierConfig);
	}

	if (_.get(this._addressSearch, 'enabled')) {
		this.addSearchBox();
	}

	google.maps.event.trigger(this._map, "resize");
	this.autoFit();
};

MapViewRenderer.prototype.addSpiderfier = function(data) {
	require('client/libraries/map-spiderfier.min');

	// Spiderfier options
	let defaultSpiderfier = {
		markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied:  true,
		spiderfiedIcon: undefined, //'./images/marker-highlight.svg',
		spiderfiableIcon: undefined, //'./images/marker-plus.svg',
		unspiderfiableIcon: undefined, //'./images/marker.svg',
		formatMarkerWithStatus: (marker, status) => {
			marker.setIcon(
				status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED	 ? marker['spiderfiedIcon'] || this.spiderfiedIcon :
					status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE   ? marker['spiderfiableIcon'] || this.spiderfiableIcon :
						status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE ? marker['unspiderfiableIcon'] || this.unspiderfiableIcon :
							null
			);

			if (status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) {
				let nearbyMarkers = this._markerSpiderfier.markersNearMarker(marker);

				let markerGroupCount = nearbyMarkers.length + 1;
				let markerLabel = markerGroupCount < 99 ? markerGroupCount.toString() : '\u221e';
				marker.setLabel(markerLabel);
				marker.setZIndex(google.maps.Marker.MAX_ZINDEX - Math.round(marker.getPosition().lat() * 100000));
			}
			else {
				marker.setLabel('');
			}
		}
	};

	const spiderfier = _.extend({}, defaultSpiderfier, data.spiderfier);

	// Prevent error 'Must wait for 'idle' event on map before calling markersNearAnyOtherMarker'
	this._map.addListener('idle', ()=>{
		this._markerSpiderfier = new OverlappingMarkerSpiderfier(this._map, spiderfier);
	});
};

/**
 * @override
 * @returns {Boolean}
 */
MapViewRenderer.prototype.doResize = function() {
	if(window.google === undefined || this._map === undefined) {
		return;
	}

	// Store current center
	let center = this._map.getCenter();

	// Resize map
	google.maps.event.trigger(this._map, "resize");

	// Reset center after resize
	this._map.setCenter(center);

	this._map.setZoom(this._map.getZoom());
	return true;
};

MapViewRenderer.prototype.doUpdate = function(data, changes, updateId) {
	let self = this;

	if(_.isEmpty(changes) || _.isEmpty(this._map)) {
		// Nothing to update
		return;
	}

	const centerLat = _.get(data, 'center.lat');
	const centerLong = _.get(data, 'center.long');
	const currentCenter = (this._map && this._map.getCenter().toJSON())  || {};
	if (!_.isNil(centerLat) && !_.isNil(centerLong) && (currentCenter.lat != centerLat || currentCenter.lng != centerLong)) {
		const center = new google.maps.LatLng(centerLat, centerLong);
		this._map.setCenter(center);
	}

	let currentZoom = self._map.getZoom();
	if (!_.isNil(data.zoom) && (data.zoom != currentZoom)) {
		this._map.setZoom(data.zoom);
	}

	let markerData = data.markers;
	if(!_.isArray(markerData)) {
		if(markerData !== undefined) {
			log.error("Markers should be an array.");
		}
		markerData = [];
	}

	// Add marker click callback
	if (this._markerSpiderfier) {
		this._markerSpiderfier.clearListeners('click');
		this._markerSpiderfier.addListener('click', this.onClick);
	}

	// Add markers
	for(let i in markerData) {
		let marker = markerData[i];
		let existing = this._markerData.get(marker.id);
		if (existing && ! _.isEqual(_.omit(existing, 'markerIndex'), marker)) {
			this.updateMarker(marker, existing);
		} else if (!existing) {
			this.addMarker(marker);
		}
	}

	// Remove deleted markers
	if (changes.markers){
		let toRemove  =_.differenceBy(changes.markers.old, changes.markers.new, 'id')
		if (toRemove.length > 0) {
			for (let remove of toRemove) {			
				this.removeMarker(remove);
			}
		}
	}

	let geoJsonChanges = this.getGeoJsonChanges(changes);

	if(geoJsonChanges) {
		
		// Remove deleted features
		let oldFeatures = _.get(changes, ['geoJSON.features', 'old']) || _.get(changes, 'geoJSON.old.features', []);
		let newFeatures = geoJsonChanges.features;
		let toRemove  =_.differenceBy(oldFeatures, newFeatures, 'id')
		if (toRemove.length > 0) {
			for (let remove of toRemove) {			
				this.removeFeature(remove);
			}
		}
		
		this._geoFeatures = [];
		let geoJSON = data.geoJSON;

		this.addGeoJson(geoJSON, data.geoJSONStyle || null);
	}

	this._autoFit = _.get(data, 'autoFit', this._autoFit);
	this.autoFit();

	return true;
};

/**
 * @override
 */
MapViewRenderer.prototype.onClose = function() {
	this._map = undefined;
	this._markers = [];
	this._markerData.clear();

	for(let i in this._markerCallbacks) {
		delete MapViewRenderer._markerCallbacks[this._markerCallbacks[i]];
	}
	this._markerCallbacks = [];
};

MapViewRenderer.prototype._setupInteraction = function() {
	let self = this;

	// Create callback for title click
	this._callbackID = this._registerCallback(function(markerID) {
		let markerData = self._markerData.get(markerID);
		self.trigger({
			type: 'markerTitleClick',
			marker: markerData
		});
	});
	this._map.addListener('bounds_changed', _.debounce(function() {
		let mapCenterPoint = self._map.getCenter().toJSON();
		let zoom = self._map.getZoom();
		let bounds = self._map.getBounds().toJSON();
		let center = {
			lat: mapCenterPoint.lat,
			long: mapCenterPoint.lng
		};
		self.requestUpdate({
			autoFit: false,
			center: _.clone(center),
			bounds,
			zoom
		}).then(response => {
			self.trigger({
				type: 'boundsChanged',
				bounds,
				center,
				zoom
			})
		});
	}, 500));

	// Add info window
	let infoWindow = new google.maps.InfoWindow({
		content: ''
	});

	this._map.data.addListener('click', function({latLng, feature}) {
		feature.toGeoJson(geoJson => self.trigger({
			type: 'featureClick',
			feature: geoJson,
			location: latLng.toJSON()
		}));
	});

	this._map.data.addListener('contextmenu', function({domEvent, latLng, feature}) {
		feature.toGeoJson(geoJson => {
			self.trigger({
				type: 'featureRightClick',
				feature: geoJson,
				location: latLng.toJSON()
			})
			self.openContextMenu('geojson', {feature: geoJson, location: latLng.toJSON()}, domEvent.x, domEvent.y);
		});

	});

	this._map.data.addListener('mouseover', function(event) {
		const feature = event.feature;
		const title = feature.getProperty('title');
		const info = feature.getProperty('info');

		if(title !== undefined || info !== undefined) {
			let markerContent = "";
			if (title !== undefined) {
				markerContent += "<h1>" + title + "</h1>";
			}
			if (info !== undefined) {
				markerContent += '<div class="info map-view-info">' + info + '</div>';
			}
			infoWindow.setContent(markerContent);
			if(infoWindow !== undefined) {
				infoWindow.setPosition(event.latLng);
				infoWindow.open(self._map);
			}
		}
		self._map.data.overrideStyle(event.feature, {strokeWeight: 8});
	});
	this._map.data.addListener('mouseout', function(event) {
		infoWindow.close();
		self._map.data.overrideStyle(event.feature, {strokeWeight: 2});
	});


	this.onClick = function(marker, event) {
		let markerData = self._markerData.get(marker.id);
		if(markerData.title !== undefined || markerData.info !== undefined) {
			let markerContent = "";
			if(markerData.title !== undefined) {
				markerContent += "<h1><a href=\"javascript:MapViewRendererMarkerCallback("+self._callbackID+", "+marker.id+")\">" + markerData.title + "</a></h1>";
			}
			if(markerData.info !== undefined) {
				markerContent += '<div class="info map-view-info">' + markerData.info + '</div>';
			}
			infoWindow.setContent(markerContent);
		}
		if(infoWindow !== undefined) {
			infoWindow.open(self._map, marker);
		}
		self.trigger({
			type: 'markerClick',
			marker: markerData
		});
	};

	// Add marker click callback
	if (this._markerSpiderfier) {
		this._markerSpiderfier.addListener('click', this.onClick);
	}

	this._map.addListener('contextmenu', function ({domEvent, latLng}) {
		self.trigger({
			type: 'mapRightClick',
			location: latLng.toJSON(),
			x: domEvent.x,
			y: domEvent.y
		});

		self.openContextMenu('map', {location: latLng.toJSON()}, domEvent.x, domEvent.y);
	})
	// Debounce for click and move
	const clickHandler = _.debounce(async function ({domEvent, latLng}) {
		const location = latLng.toJSON();

		const triggerData = {
			type: 'mapClick',
			location: location,
			x: domEvent.x,
			y: domEvent.y
		};

		if (self._addressSearch.enabled) {
			const geocoder = new google.maps.Geocoder();
			const response = await geocoder.geocode({ location });
			if (response.results.length > 0) {
				triggerData.addresses = _.map(response.results, x => _.omit(x, ['types', 'geometry']));
			}
		}

		self.trigger(triggerData);
	}, 50);
	
	this._map.addListener('click', clickHandler)
	
};

MapViewRenderer.prototype.clearMarkers = function() {
	if (this._markers.length === 0) return;
	for (let i = 0; i < this._markers.length; i++) {
		this._markers[i].setMap(null);
	}
	this._markers = [];
	this._markerData.clear();
};

MapViewRenderer.prototype.removeMarker = function(marker) {
	let markerData = this._markerData.get(marker.id);
	let markerObj = this._markers[markerData.markerIndex];
	markerObj.setMap(null);
	this._markerData.delete(marker.id);
}

MapViewRenderer.prototype.getGeoJsonChanges = function(changes){
	let geoJSON = _.get(changes, 'geoJSON.new') || _.get(changes, 'geoJson.new');

	if(geoJSON) {
		return geoJSON;
	}

	let features = _.get(changes, ['geoJSON.features', 'new']) || _.get(changes, ['geoJson.features', 'new']);
	
	if(features) {
		return {
			type: 'FeatureCollection', 
			features
		};
	}

	return null;
};

MapViewRenderer.prototype.addSearchBox = function() {
	let self = this;

	const input = document.createElement("input");
	input.setAttribute("placeholder", self._addressSearch.placeholder);
	input.classList.add('form-control');
	input.classList.add('search-box');
	if (self._addressSearch.text) {
		input.value = self._addressSearch.text;
	}
	const searchBox = new google.maps.places.SearchBox(input);

	self._map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

	// Bias the SearchBox results towards current map's viewport.
	self._map.addListener("bounds_changed", () => {
		searchBox.setBounds(self._map.getBounds());
	});

	// Listen for the event fired when the user selects a prediction and retrieve
	// more details for that place.
	searchBox.addListener('places_changed', function() {
		var places = searchBox.getPlaces();

		if (places.length == 0) {
			return;
		}

		// For each place, get the icon, name and location.
		var bounds = new google.maps.LatLngBounds();
		places.forEach(function(place) {
			if (!place.geometry) {
				return;
			}

			if (place.geometry.viewport) {
				// Only geocodes have viewport.
				bounds.union(place.geometry.viewport);
			} else {
				bounds.extend(place.geometry.location);
			}

			const filteredPlace = {
				address_components: _.get(place, 'address_components'),
				plusCode: _.get(place, 'plus_code'),
				formatted_address: _.get(place, 'formatted_address')
			};

			self.trigger({
				type: 'addressSelected',
				location: {
					lat: place.geometry.location.lat(),
					long: place.geometry.location.lng(),
				},
				info: place.name,
				place: filteredPlace,
				zoom: self.getZoomByBounds(self._map, bounds)
			});
		});
	});
};

MapViewRenderer.prototype.getZoomByBounds = function(map, bounds) {
	var MAX_ZOOM = map.mapTypes.get( map.getMapTypeId() ).maxZoom || 21 ;
	var MIN_ZOOM = map.mapTypes.get( map.getMapTypeId() ).minZoom || 0 ;

	var ne= map.getProjection().fromLatLngToPoint( bounds.getNorthEast() );
	var sw= map.getProjection().fromLatLngToPoint( bounds.getSouthWest() );

	var worldCoordWidth = Math.abs(ne.x-sw.x);
	var worldCoordHeight = Math.abs(ne.y-sw.y);

	//Fit padding in pixels
	var FIT_PAD = 40;

	for( var zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom ){
		if( worldCoordWidth*(1<<zoom)+2*FIT_PAD < $(map.getDiv()).width() &&
				worldCoordHeight*(1<<zoom)+2*FIT_PAD < $(map.getDiv()).height() )
			return zoom;
	}
	return 0;
};

window.MapViewRendererMarkerCallback = MapViewRenderer.markerCallback;

module.exports = MapViewRenderer;

