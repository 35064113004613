const _ = require('core/src/utils/legacy');
const deferredPromise = require('core/src/utils/deferred-promise');
const { isjQuery } = require('client/src/utils/jquery');
const md5 = require('md5');
const $ = require('jquery');
const { checkType, checkMethods } = require('utils/src/validation');
const { isFalse } = require('core/src/utils/validation');
const log = require('core/src/log').instance("client/ui/viewmanager");
const { t } = require('core/src/language');

const FTI = require('core/src/fti');
const View = require('core/src/functions/view');
const VueView = require('client/src/vue-view').default;
const ViewContainer = require('client/src/view-container');
const Function = require('core/src/function');
const Trigger = require('core/src/trigger');
const ViewRenderer = require('client/src/view-renderer');
const EventInterface = require('core/src/event-interface');
let bootstrap;

try {
	bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');
} catch(e) {
	log.error("Could not load Bootstrap:", e);
}

/**
 * Manages view rendering on the client side, based on RenderEvents from the FTS.
 * @returns {ViewManager}
 */
const ViewManager = function (dependencies) {
	this._containerPrototypes = {};
	this._defaultContainerPrototype = ViewContainer;
	this._viewrenderers = {};
	this._activeViews = {};
	this._activeRenderers = {};
	this._activeContainers = {};
	this._activePrompts = {};
	this._eventHandlers = [];
	this._devMode = false;

	this._dependencies = dependencies;

	this.fti = dependencies.get(FTI);

	EventInterface.extend(this);
};

/* STATICS */

ViewManager.Interfaces = {
	Html: {
		methods: ["append"]
	}
};

/* PROPERTIES */

ViewManager.prototype.defaultArea = 'default';
ViewManager.prototype.defaultContainer = '_new';
ViewManager.prototype.promptArea = undefined;

ViewManager.prototype._logging = false;
// List of container prototypes for each area
ViewManager.prototype._containerPrototypes = undefined;
ViewManager.prototype._defaultContainerPrototype = undefined;
// List of ViewRenderers that can be employed for each viewtype
ViewManager.prototype._viewrenderers = undefined;
// List of active Views instanceIDs and the container they are displayed in
ViewManager.prototype._activeViews = undefined;
// List of active ViewRenderers, indexed by the instanceID of the View they represent.
ViewManager.prototype._activeRenderers = undefined;
// List of active Containers
ViewManager.prototype._activeContainers = undefined;
// List of event handlers
ViewManager.prototype._eventHandlers = undefined;
// FTI
ViewManager.prototype.fti = undefined;

// Prefixes for generated IDs
ViewManager.prototype.areaRole = 'view-area';
ViewManager.prototype.containerPrefix = 'graphileon-container-';
ViewManager.prototype.areaPrefix = undefined;

/* Factory */

ViewManager.prototype.factory = {
	extendViewRenderer: function (parent) {
		var CustomRenderer = function () {
			parent.call(this);
		};
		CustomRenderer.prototype = Object.create(parent.prototype);
		return CustomRenderer;
	}
};

/* METHODS */

/**
 * Enable/disable logging. Is disabled by default.
 * @param {bool} enabled Defaults to <true>
 * @returns {undefined}
 */
ViewManager.prototype.setLogging = function (enabled) {
	if (enabled === undefined) {
		enabled = true;
	}
	this._logging = enabled;
};

/**
 *
 * @param {boolean} enabled
 */
ViewManager.prototype.setDevMode = function (enabled) {
	this._devMode = enabled !== false;
};

/**
 * Connects the viewmanager to the given FTI.
 * @param {FTI} fti
 */
ViewManager.prototype.connectFTI = function (fti) {
	var self = this;
	if (_.isFunction(fti.on)) {
		fti.on(View.Event.Out.VIEW_RENDER, function (event, origin) {
			event.instanceID = origin.instanceID;
			event.functionID = origin.functionID;
			self.render(event);
		});
		fti.on(VueView.Event.Out.RENDER_COMPONENT, function(event, origin) {
			self.renderComponent(event);
		});
		fti.on(Function.Event.Out.CLOSE, function (event, origin) {
			// Close renderers
			if (origin.instanceID in self._activeRenderers) {
				self._activeRenderers[origin.instanceID].close('function');
			}
			self.closePrompts(origin.instanceID);
		});
		fti.on(Function.Event.Out.FUNCTION_PROMPT, function (event, origin) {
			if (self._logging) {
				log.log("Received FunctionPrompt event.", event, " | Origin:", origin);
			}

			origin = origin || {};
			event = event || {};
			var check = _.validate({
				instanceID: [origin.instanceID, _.isStringOrNumber(origin.instanceID), "Origin instanceID must be string or number."],
				id: [event.id, _.isStringOrNumber, "Must be string or number."],
				name: [event.name, "isString", "Event name must be string."],
				parameters: [event.parameters, "isObject", "Event parameters must be object."]
			}, "Invalid event and/or origin. Cannot prompt for parameters.");
			if (!check.isValid()) return;
			var valid = check.getValue();

			self.promptFunctionParameters(valid.instanceID, valid.id, valid.name, valid.parameters);
		});
		fti.on(Function.Event.Out.UPDATE, function (event, origin) {
			if (self._logging) {
				log.log("Received UpdateEvent", event);
			}
			self.updateView(_.get(origin, 'instanceID'), event.model, event.changes, event.updateID, event.updatedRenderData);
		});
		fti.on(Function.Event.Out.ERROR, function (data, origin) {
			var renderEvent = {
				viewtype: 'Error',
				input: {
					error: data.error,
					container: {
						height: 300,
						width: 600,
						closable: true
					}
				},
				functionID: origin.functionID,
				instanceID: origin.instanceID
			};
			self.render(renderEvent);
		});
		fti.on(Trigger.Event.Out.ERROR, function (data, origin) {
			var renderEvent = {
				viewtype: 'Error',
				input: data,
				triggerID: origin.triggerID
			};
			self.render(renderEvent);
		});
	}
	this.fti = fti;
};

ViewManager.prototype.closePrompts = function (instanceID) {
	if (instanceID in this._activePrompts) {
		for (var promptID in this._activePrompts[instanceID]) {
			this._activePrompts[instanceID][promptID].close();
		}
		delete this._activePrompts[instanceID];
	}
};

ViewManager.prototype.updateView = function (instanceID, model, changes, updateID, updatedRenderData) {
	var check = _.validate("Update event", {
		instanceID: [instanceID, _.isStringOrNumber(instanceID), "InstanceID must be string or number."],
		model: [model, "isObject"],
		changes: [changes, "isObject", {default: {}, warn: !_.isNil(changes)}]
	});
	if (!check.isValid()) return false;
	var valid = check.getValue();

	// Fetch renderer
	var renderer = this.getActiveRenderer(valid.instanceID);
	if (renderer === undefined) {
		return false; // no renderer for this Function
	}

	renderer.update(valid.model, valid.changes, updateID);

	// Update container title
	let containerInfo = model.container || {};
	const container = this.getViewContainer(valid.instanceID);
	
	container.update(
		_.defaults(
			containerInfo,
			{
				title: renderer.getTitle()
			}
		)
	);

	// Render batch buttons and context menus and update toolbar
	renderer.updateCommon(updatedRenderData);

	return true;
};

/**
 * Prompts the user for parameter input.
 * @param {Number} instanceID    The instanceID of the target Function.
 * @param {Number} promptID        The ID of the prompt.
 * @param {string} title        The name of the prompt (will be displayed at the top).
 * @param {array} parameters    Array of objects containing `path` and `meta` keys.
 * @returns {ViewContainer}
 */
ViewManager.prototype.promptFunctionParameters = function (instanceID, promptID, title, parameters) {
	var self = this;

	if (!_.isArray(parameters)) {
		log.warn("ViewManager.promptFunctionParameters: parameters is not an array.");
		return null;
	}

	var container = this.newContainer(this.promptArea || this.defaultArea);
	container.setTitle(null);

	if (!_.isObject(this._activePrompts[instanceID])) {
		this._activePrompts[instanceID] = {};
	}
	this._activePrompts[instanceID][promptID] = container;

	var $form = $('<form class="parameter-prompt form-material">').attr('name', 'graphileon-prompt');

	parameters.sort(function (a, b) {
		return _.compareIndex(_.get(a, 'meta.index'), _.get(b, 'meta.index'));
	});

	for (var i in parameters) {
		var path = _.get(parameters[i], 'path');
		var label = _.get(parameters[i], 'meta.prompt') || path;
		var defaultValue = _.get(parameters[i], 'meta.promptDefault');

		var $group = $('<div class="form-group">');
		var $input = $('<input>').attr('type', 'text').attr('name', path).addClass('form-control form-control-line');
		if (defaultValue !== undefined) {
			$input.val(defaultValue);
		}

		var $label = $('<label>');
		$label.html(label);

		var $value = $('<div class="value">');
		$value.append($input);

		$group.append($label);
		$group.append($value);
		$form.append($group);
	}

	var cancel = function () {
		self.fti.eventIn(instanceID, Function.Event.In.PROMPT_CANCEL, {
			id: promptID
		});
	};

	// Submission
	$form.submit(function (e) {
		var data = $form.serializeArray();
		var parameters = {};
		for (var i in data) {
			parameters[data[i].name] = data[i].value;
		}
		self.fti.eventIn(instanceID, Function.Event.In.PROMPT_ANSWER, {
			id: promptID,
			data: parameters
		});
		container.removeListener('close', cancel);
		container.close();
		e.preventDefault();
	});
	var submit = $('<button>').addClass('submit btn btn-primary').attr('type', 'button').html('Execute');
	submit.on('click', function (e) {
		$form.submit();
	});
	$form.append(submit);

	container.setContent($form);
	var firstInput = $form.find('input[type=text]').first();
	firstInput.focus();

	container.on('close', cancel, true);

	return container;
};

/**
 * Finds a area element on the page. Override for specific ViewManagers.
 * @param {string} areaID The area identifier.
 * @returns {jQuery} The area element, as a jQuery object
 */
ViewManager.prototype.findAreaElement = function (areaID) {
	if(this.areaPrefix) areaID = this.areaPrefix + areaID;

	let areaElement = $(`#${areaID}[role="${this.areaRole}"]`);

	// Backward compatibility
	if(areaElement.length === 0){
		areaElement = $(`#graphileon-area-${areaID}`);
	}

	return areaElement;
};

/**
 * Find the previous View in the trigger history.
 * @param {array} history The history of Functions, as received from the FTS.
 * @returns {int} Instance ID of the previous View.
 */
ViewManager.prototype.findPreviousView = function (history) {
	if (!_.isArray(history)) {
		log.error("History should be array.");
		return null;
	}
	for (var i in history) {
		var item = history[i];
		if (!_.isString(item.type) || !_.isStringOrNumber(item.instance)) {
			log.warn("History item as invalid structure.", item);
			continue;
		}
		// If instance was found in view registry, return item
		if (_.has(this._activeViews, item.instance)) {
			return item;
		}
	}
	log.warn("No previous view found.");
	return null;
};

/**
 * Finds an area on the page by its ID
 * @param {string} areaID
 * @returns {jQuery} The area element, as a jQuery object
 */
ViewManager.prototype.findArea = function (areaID) {
	var check = _.validate({
		areaID: [areaID, _.isStringOrNumber(areaID), "Must be string or number."]
	}, "Could not find area.");
	if (!check.isValid()) return $('');

	if(areaID === 'modal-area') areaID = 'modal'; // backward compatibility

	var found = this.findAreaElement(areaID);

	if (!isjQuery(found)) {
		log.error("Area finder did not return a jQuery object.");
		return $('');
	}
	if (found.length === 0 && this._logging) {
		log.warn("Could not find area " + areaID);
	}

	if (this._logging) {
		log.log("Rendering to area '" + areaID + "'", found);
	}

	return found;
};

/**
 * Give the ID that should be assigned to the next container. Will auto-increment.
 * @returns {Number}
 */
ViewManager.prototype.nextContainerID = function () {
	return _.uniqueId(this.containerPrefix);
};

/**
 * Register a ViewRenderer with the ViewManager for a specific viewtype. This
 * ViewRenderer will then always be used for that ViewRender events with that
 * viewtype.
 * @param {typeof ViewRenderer} ViewRendererClass
 * @param {string} viewtype
 */
ViewManager.prototype.register = function (ViewRendererClass, viewtype) {
	// By default, use the renderer's viewType
	if (!viewtype) viewtype = ViewRendererClass.viewType;

	if (!_.isString(viewtype)) {
		log.warn("Viewtype parameter should be a string.", viewtype);
		return false;
	}
	if (!_.isFunction(ViewRendererClass)) {
		log.warn("Invalid ViewRenderer: not a prototype.", ViewRendererClass);
		return false;
	}
	if (!(ViewRendererClass.prototype instanceof ViewRenderer || ViewRendererClass === ViewRenderer)) {
		log.warn("Invalid ViewRenderer: does not inherit from ViewRenderer.", ViewRendererClass);
		return false;
	}

	// Valid renderer
	this._viewrenderers[viewtype] = ViewRendererClass;
};

/**
 * Get the ViewRenderer currently associated with the given viewtype.
 * @param {string} viewtype
 * @returns {ViewRenderer}
 */
ViewManager.prototype.getViewRenderer = function (viewtype) {
	if (!_.isString(viewtype)) {
		log.warn("Viewtype should be a string, " + viewtype + "given.");
		return undefined;
	}
	return this._viewrenderers[viewtype];
};

/**
 * Overrides the default areaFinder
 * @param {function} func A function that takes an area identifier (string) as
 * argument and returns an html element (either jQuery or DOM).
 */
ViewManager.prototype.setAreaFinder = function (func) {
	if (!_.isFunction(func)) {
		log.warn("AreaSelector must be a function.");
	} else {
		this.findAreaElement = func;
	}
};

ViewManager.prototype.createContainerPrototype = function (settings) {
	// Create new Container prototype
	function CustomContainer(id, data) {
		ViewContainer.call(this, id, data);
	}

	CustomContainer.prototype = Object.create(ViewContainer.prototype);

	// Override properties and functions
	if (_.isObject(settings)) {
		// Override functions
		var functionOverrides = {
			'build': '_build',
			'attachTo': '_attachTo',
			'isAvailable': '_isAvailable',
			'setContent': '_setContent',
			'setTitle': '_setTitle',
			'close': '_close',
			'setSize': '_setSize',
			'getInnerSize': '_getInnerSize',
			'setState': '_setState',
			'focus': '_focus',
			'setOriginFunctionCallback': '_setOriginFunctionCallback',
			'moveOnTop': '_moveOnTop',
			'moveOnBottom': '_moveOnBottom'
		};
		_.mapProperties(settings, CustomContainer.prototype, functionOverrides, function (value) {
			return _.isFunction(value);
		});
	}

	return CustomContainer;
};

/**
 * Overrides the container prototype for a specific area.
 * @param {string} area The area for which this container builder will be used.
 * Set to <undefined> to override the default container prototype.
 * @param {object} prototype An object to override functions of the Container.
 */
ViewManager.prototype.setContainerPrototype = function (area, prototype) {
	let CustomContainer = prototype;
	if (_.isPlainObject(prototype)) {
		CustomContainer = this.createContainerPrototype(prototype);
	}
	// Override default container prototype
	if (area === undefined) {
		this._defaultContainerPrototype = CustomContainer;
		// Override container prototype for specific area
	} else {
		this._containerPrototypes[area] = CustomContainer;
	}
	return CustomContainer;
};

/**
 * Selects the Container prototype to use for this area.
 * @param {string} area Area identifier.
 * @returns {ViewContainer}
 * as arguments and returns an html object.
 */
ViewManager.prototype.selectContainerPrototype = function (area) {
	if(area === 'modal-area') area = 'modal'; // backward compatibility
	if (_.has(this._containerPrototypes, area)) {
		return this._containerPrototypes[area];
	} else {
		return this._defaultContainerPrototype;
	}
};

/**
 * Returns the direct parent ViewContainer of a ViewContainer (if any)
 * @param {ViewContainer} container to find the parent of
 * @returns {ViewContainer|null}
 */

ViewManager.prototype.getContainerParentContainer = function(container) {
	// Try to find a container in _activeContainers that matches any parent elements of the container.
	let parentContainer = null;
	_.forEach(
		container.$element.parents(),
		(el) => {
			parentContainer = _.find(
				this._activeContainers, 
				(activeContainer) => (activeContainer.$element[0] === el)
			);

			// If we found one stop the search
			if (parentContainer) return false;
		}
	)

	return parentContainer;
}

/**
 * Builds a new container for the specified area.
 * @param {string} [containerID]
 * @param {object} [containerData]
 * @returns {ViewContainer}
 */
ViewManager.prototype.newContainer = function (areaID, containerData) {
	var containerID = _.get(containerData, 'id');
	var index = _.get(containerData, 'index');
	if (containerID === undefined) {
		containerID = "" + this.nextContainerID();
	}
	// Create container
	var prototype = this.selectContainerPrototype(areaID);
	var idString = containerID;
	var container = new prototype(idString, containerData);
	container.setIndex(index);

	// Attach container to area
	var area = this.findArea(areaID);
	container.attachTo(area);

	this.observeContainer(area[0], container);

	// Register container
	this._activeContainers[containerID] = container;

	// Add this container as child of his parent container
	let parentContainer = this.getContainerParentContainer(container);
	if (parentContainer) {
		parentContainer.childrenContainerNames.push(container.id);
	}

	return container;
};

/**
 * Will close the container if the container is removed from the DOM.
 * @param area
 * @param container
 */
ViewManager.prototype.observeContainer = function(area, container) {
	if(typeof(MutationObserver) === 'undefined') {
		log.warn("Client does not support MutationObserver. View will not be closed if container is removed from DOM.");
		return;
	}
	const observer = new MutationObserver(function() {
		const element = isjQuery(container.element) ? container.element[0] : container.element;
		if(!area.contains(element)) {
			container.close('dom');
		}
	});
	observer.observe(area, {childList: true, subtree: true});
};

/**
 * Get an active container by its ID.
 * @param {string} containerID
 * @returns {ViewContainer}
 */
ViewManager.prototype.getContainer = function (containerID) {
	return this._activeContainers[containerID];
};

/**
 * Get the active renderer that represents the given View by instanceID.
 * @param {string} instanceID The represented View's instanceID.
 * @returns {undefined}
 */
ViewManager.prototype.getActiveRenderer = function (instanceID) {
	return this._activeRenderers[instanceID];
};

/**
 * Get the active container for a given View by instanceID.
 * @param {string} instanceID
 * @returns {undefined}
 */
ViewManager.prototype.getViewContainer = function (instanceID) {
	return this._activeViews[instanceID];
};

/**
 *
 * @param viewtype
 * @param functionID
 * @param instanceID
 * @returns {*}
 */
ViewManager.prototype.createViewRenderer = function (viewtype, functionID, instanceID) {
	const Renderer = this.getViewRenderer(viewtype);
	if (Renderer === undefined) {
		log.error("No ViewRenderer registered for viewtype {" + viewtype + "}");
		return undefined;
	}
	const renderer = new Renderer(this._dependencies);
	renderer.setInstanceID(instanceID);
	renderer.setFunctionID(functionID);
	renderer.setViewType(viewtype);

	return renderer;
};

/**
 * Handles events coming from ViewRenderers.
 * @param {string} instanceID
 * @param {string} event
 * @param {object} data
 * @returns {undefined}
 */
ViewManager.prototype.handleRendererEvent = function (instanceID, event, data) {
	if (this._logging) {
		log.log("'" + event + "' event (instance " + instanceID + "):", data);
	}
	if (this.fti instanceof FTI) {
		this.fti.eventIn(instanceID, event, data);
	}
};

ViewManager.prototype._closeInstance = function (instanceID, origin) {
	delete this._activeRenderers[instanceID];
	delete this._activeViews[instanceID];
	if (this.fti instanceof FTI) {
		this.fti.closeFunctionInstance(instanceID, origin);
	}
};

ViewManager.prototype.closeOrphanRenderers = function () {
	_.forEach(this._activeRenderers, (renderer, functionInstanceID) => {
		let functionInstance = this.fti.getFunctionInstance(functionInstanceID);
		if (!functionInstance) {
			renderer.close();
		}
	});
};

ViewManager.prototype.removeContainer = function(container) {
	_.forEach(
		this._activeContainers,
		(parentContainer) => {
			parentContainer.childrenContainerNames = _.without(parentContainer.childrenContainerNames, container.id);
		}
	)

	delete this._activeContainers[container.id];
}

ViewManager.prototype.getOrCreateContainer = function(areaSpecs, containerSpecs, history) {
	if (!_.isPlainObject(containerSpecs)) {
		// assume only ID was given
		if (_.isNil(containerSpecs)) {
			containerSpecs = this.defaultContainer;
		}
		containerSpecs = {id: containerSpecs};
	}
	if (!_.isPlainObject(areaSpecs)) {
		// assume only ID was given
		if (_.isNil(areaSpecs)) {
			areaSpecs = this.defaultArea;
		}
		areaSpecs = {id: areaSpecs};
	}
	const areaID = areaSpecs.id || this.defaultArea;

	// Try to find existing container
	let container = undefined;
	// Same container
	if (containerSpecs.id === "_self") {
		const previousView = this.findPreviousView(history);
		// Previous View exists and is still active on page
		if (previousView !== null && _.has(this._activeViews, previousView.instance)) {
			container = this._activeViews[previousView.instance];
		}
	}
	// Specific container
	else if (containerSpecs.id !== '_new') {
		container = this._activeContainers[containerSpecs.id];
	}
	// If container is not on the page (anymore), pretend it was not found
	if (container && !container.isAvailable()) {
		container = undefined;
	}
	// '_new' and '_self' are not the final container IDs
	if (_.includes(['_new', '_self'], containerSpecs.id)) {
		containerSpecs.id = undefined;
	}

	// No container found, create one
	if (!(container instanceof ViewContainer)) {
		container = this.newContainer(areaID, containerSpecs);
		container.on('close', () => {
			this.removeContainer(container);
		}, true); // remove listener after first fire

		// Attach resize event to child views
		container.on('resize', ({ width, height }) => {
			_.forEach(
				container.childrenContainerNames,
				(containerName) => {
					this._activeContainers[containerName].resize(width, height);
				}
			);
		})
		container.isNew = true;
	} else {
		container.isNew = false;
	}

	return container;
};

/**
 * Creates a container for a component and mounts it.
 * @param event
 */
ViewManager.prototype.renderComponent = function(event) {
	checkType(event, 'object', 'event');
	checkMethods(event.renderer, ['render', 'close', 'getFunctionID', 'on'], 'renderer');

	const containerSpecs = checkType(event.container, 'object', 'container');
	const renderer = event.renderer;
	const areaSpecs = event.area;
	const history = event.history;
	const viewtype = event.viewtype;

	const container = this.getOrCreateContainer(areaSpecs, containerSpecs, history);
	const oldRenderer = container.detachRenderer();
	if(oldRenderer) oldRenderer.close('replaced');

	const rendered = renderer.render();
	container.setContent(rendered, renderer);

	if (container.isNew || isFalse(_.get(containerSpecs, 'preserveSizeOnReuse', true))) {
		container.setSize(containerSpecs.width, containerSpecs.height);
	}
	else {
		container.resize();
	}

	container.focus();
	container.setTitle(containerSpecs.title);

	// Recycled containers should keep their state unless explicitly defined
	if(container.isNew || (isFalse(_.get(containerSpecs, 'preserveSizeOnReuse', true)) && containerSpecs.state)) {
		container.setState(containerSpecs.state, true);
	}

	container.ready();

	const functionID = renderer.getFunctionID();
	if (this._devMode && _.isStringOrNumber(functionID)) {
		container.setOriginFunctionCallback(() => {
			this.fire('getOriginFunction', {
				id: functionID,
				functionType: viewtype,
				mouse: {x: window.event.clientX, y: window.event.clientY}
			});
		});
	}

	// Execute container events
	container.setConfig(containerSpecs);
};

/**
 * Renders a View, based on a RenderEvent.
 * @param {object} renderEvent The RenderEvent
 * @returns {boolean}
 */
ViewManager.prototype.render = function (renderEvent) {
	var self = this;
	if (this._logging) {
		log.log("RenderEvent:", renderEvent);
	}

	var event = renderEvent || {};

	var check = _.validate("ViewManager.render", {
		functionID: [renderEvent.functionID, _.isStringOrNumber, "Must be string or number.",
			{default: undefined, warn: renderEvent.functionID !== undefined}],
		instanceID: [renderEvent.instanceID, _.isStringOrNumber, "Must be string or number.",
			{default: undefined}],
		viewtype: [event.viewtype, "isString"],
		input: [event.input, "isObject", {default: {}, warn: _.def}],
		history: [event.history, "isArray", {default: [], warn: _.def}],
		contextMenus: [event.contextMenus, "isObject", {default: {}, warn: _.def}],
		batchTriggers: [event.batchTriggers, "isArray", {default: [], warn: _.def}],
		properties: [event.properties, "isObject", {default: {}, warn: _.def}]
	}, "Could not render.");
	if (!check.isValid()) return false;
	const valid = check.getValue();

	// Fetch data from event
	var instanceID = valid.instanceID;
	var viewtype = valid.viewtype;
	var input = valid.input;
	var history = valid.history;
	var functionID = valid.functionID;

	// Area and container
	var containerData = _.extend(
		// We need the $css parameter in container definition
		// if $container.css is defined it will be used instead of $css
		{css: _.get(input, 'css')},
		_.get(input, 'container', {})
	)

	var areaData = _.get(input, 'area');

	if(!_.isPlainObject(containerData)) {
		containerData = {id: containerData};
	}

	const container = this.getOrCreateContainer(areaData, containerData, history);

	let viewrenderer = null;
	if(!container.isNew) {
		log.log("Recycling container for function " + functionID + '.');
		var oldRenderer = container.detachRenderer(); //will be reattached if it is recycled
		// Recycle if possible: the same function reuses the container
		if (_.def(container.getCurrentFunctionID()) && container.getCurrentFunctionID() === functionID) {
			oldRenderer.setInstanceID(instanceID);
			viewrenderer = oldRenderer;
		} else {
			oldRenderer.close('replaced'); // send close signal to renderer (and therefore instance)
		}
	}
	container.focus();

	// Setup ViewRenderer (if not recycled)
	if (viewrenderer === null) {
		viewrenderer = this.createViewRenderer(viewtype, functionID, instanceID);
		if (!(viewrenderer instanceof ViewRenderer)) {
			log.error("Could not create renderer for view type '" + viewtype + "'.");
			return false;
		}
	}
	if (!(viewrenderer instanceof ViewRenderer)) {
		log.error("Error preparing ViewRenderer.");
		return false;
	}
	viewrenderer.onEvent(function (data, viewRendererEvent) {
		self.handleRendererEvent(viewrenderer.getInstanceID(), viewRendererEvent, data);
	});

	if (this._logging) {
		log.log("Renderer:", viewrenderer);
	}

	// Render the View
	const content = viewrenderer.render(valid);
	content.attr('data-function-id', functionID);

	// Set container content
	container.setContent(content, viewrenderer);
	// setContent can replace the innerHTML of an element with areas inside so we want to close the functions inside those areas
	this.closeRemovedContainers();
	
	var title = '';
	if ('title' in containerData) {
		title = containerData.title;
	} else {
		title = viewrenderer.getTitle();
	}
	container.setTitle(title);

	if (container.isNew || isFalse(_.get(containerData, 'preserveSizeOnReuse', true))) {
		container.setSize(containerData.width, containerData.height);
	}
	else {
		container.resize();
	}

	if (this._devMode && _.isStringOrNumber(functionID)) {
		container.setOriginFunctionCallback(() => {
			this.fire('getOriginFunction', {
				id: functionID,
				functionType: viewtype,
				mouse: {x: window.event.clientX, y: window.event.clientY}
			});
		});
	}

	if (this._logging) {
		log.log("Container (area " + areaID + "):", container);
	}

	// Register View with its container
	if (_.has(this._activeViews, instanceID)) {
		log.warn("RenderEvent for View instance " + instanceID + " sent twice. Ignored.");
	} else {
		// Register and listen to close to unregister
		this._activeViews[instanceID] = container;
		container.on('close', function () {
			delete self._activeViews[instanceID];
		}, true); // remove listener after first fire
	}

	// Register ViewRenderer with ViewManager
	if (_.has(this._activeRenderers, instanceID)) {
		log.warn("A renderer for instanceID " + instanceID + " was already registered.");
	} else {
		// Register and listen for close to unregister
		this._activeRenderers[instanceID] = viewrenderer;
		viewrenderer.after('Close', function (origin) {
			self._closeInstance(instanceID, origin);
		}, true); // remove listener after first fire
	}


	// Set container state
	// Recycled containers should keep their state unless explicitly defined
	if(container.isNew || containerData.state) {
		container.setState(containerData.state, true);
	}
	container.ready();
	// Execute container events
	container.setConfig(containerData);

	return true;
};

ViewManager.prototype.closeRemovedContainers = function() {
	let removedContainers = _.filter(this._activeContainers, (container) => ! container.isInDOM());
	_.forEach(removedContainers, _.method('close'));
};

/**
 *
 * @param {function} onSubmit   A function that takes <name> and <pass> as parameters (both strings).
 *                              Is called when submit
 * @returns {*|jQuery}
 */
ViewManager.prototype.loginForm = function (onSubmit) {
	var $form = $(require('client/src/templates/login.html'));
	$form.submit(function (e) {
		e.preventDefault();

		var data = _.formToObject($form);
		var name = data.username;
		var pass = data.password;

		onSubmit(name, pass);
	});

	return $form;
};

/**
 *
 * @param {string} area
 * @param {string} container
 * @param {function}    loginFunc           A function that takes a name and password to request login. Should return a Deferred object.
 *          {string}    loginFunc.name      The name entered by the user.
 *          {string}    loginFunc.pass      The password entered by the user.
 */
ViewManager.prototype.showLogin = function (area, container, loginFunc) {
	var deferred = new $.Deferred();

	if (!_.isObject(container)) {
		container = {id: container};
	}
	var containerID = container.id;
	var index = container.index;

	container = this.newContainer(area, {id: containerID, index: index});

	// TODO: Make login a View
	var form = this.loginForm(function (name, pass) {
		var loggingIn = loginFunc(name, pass);
		var check = _.validate({
			deferred: [deferred, _.isPromise, "loginFunc did not return a Promise."]
		}, "Invalid loginFunc. Cannot handle errors.");
		if (!check.isValid()) return;

		loggingIn.done(function (user) {
			deferred.resolve(user);
		});
		loggingIn.fail(__loginFailed);
	});
	var __loginFailed = function (response) {
		var errorArea = form.find('.login-error');
		var message = _.get(response, 'message');
		errorArea.html(message);
		errorArea.slideDown();
	};

	container.setContent(form);
	container.setTitle("Login");

	return deferred.promise();
};

ViewManager.prototype.modalAlert = function (options) {
	let self = this;
	var deferredObject = $.Deferred();
	var defaults = {
		type: "alert", //alert, prompt,confirm
		modalSize: 'modal-sm', //modal-sm, modal-md, modal-lg
		okButtonText: 'Ok',
		cancelButtonText: 'Cancel',
		okButtonClass: '',
		cancelButtonClass: '',
		headerText: 'Attention',
		messageText: 'Message',
		alertType: 'default', //default, primary, success, info, warning, danger
		inputFieldType: 'text', //could ask for number,email,etc
		height: 'auto',
		width: 'auto',
		defaultValue: '',
		onShow: (container) => {
		},
		onClose: (container) => {
		}
	};
	$.extend(defaults, options);

	const hideModal = function(container) {
		const modal = bootstrap.Modal.getInstance(container);
		modal.hide();
	};

	var _show = function(){
		return deferredPromise(new Promise((resolve, reject) => {

        	if ( $(`#graphileonModal${defaults.type}`).length ) {
				const modal = bootstrap.Modal.getInstance($(`#graphileonModal${defaults.type}`));
				modal.hide();
        	}
			var container = $(`<div id="graphileonModal${defaults.type}" class="modal fade"/>`);
			let dialog = $(`<div id="GraphileonAlerts" class="modal-dialog modal-dialog-scrollable ${defaults.modalSize}"/>`);
			let content = $(`<div id="GraphileonAlerts-content" class="modal-content" style="width: ${defaults.width}; height: ${defaults.height}"/>`);
			let header = $(`<div id="GraphileonAlerts-header" class="modal-header alert alert-${defaults.alertType}" />`);
			let title = $(`<h4 class="modal-title">${defaults.headerText}</h4>`);
			let close = $('<button id="close-button" type="button" class="btn btn-close" data-dismiss="modal"></button>');
			let body = $('<div id="GraphileonAlerts-body" class="modal-body"/>');
			let message = $('<div id="GraphileonAlerts-message" class="message"/>');
			let form = $('<div id="GraphileonAlerts-form" class="form"/>');
			let footer = $('<div id="GraphileonAlerts-footer" class="modal-footer actions"/>');

			let okBtnHtml = `<button id="ok-btn" class="btn ${defaults.okButtonClass}">${defaults.okButtonText}</button>`;
			let cancelBtnHtml = `<button id="cancel-btn" class="btn ${defaults.cancelButtonClass}">${defaults.cancelButtonText}</button>`;

			message.html(`<p>${defaults.messageText}</p>`);

			let userResponse = '';

			let returnBack = (e) => {
				if ( userResponse === 'closed' ) {
					reject(userResponse);
					return;
				}
				resolve(userResponse);
			};

			var keyb = "false", backd = "static";
			switch (defaults.type) {
				case 'alert':
					keyb = "true";
					footer.html(okBtnHtml).on('click', "#ok-btn", function () {
						userResponse = true;
						hideModal(container);
					});
					break;
				case 'confirm':
					let btnHtml = cancelBtnHtml + okBtnHtml;
					footer.html(btnHtml).on('click', 'button', function (e) {
						if (e.target.id === 'ok-btn') {
							userResponse = true;
							hideModal(container);
						} else if (e.target.id === 'cancel-btn') {
							userResponse = false;
							hideModal(container);
						}
					});
					break;
				case 'prompt':
					form.html(`<div class="form-group"><input type="${defaults.inputFieldType}" class="form-control" id="Graphileonprompt" value="${defaults.defaultValue}"/></div>`);
					footer.html(okBtnHtml).on('click', "#ok-btn", function () {
						userResponse = $('#Graphileonprompt').val();
						hideModal(container);
					});
					break;
			}
			body.append(message);
			body.append(form);
			header.append(title);
			header.append(close);

			header.on('click', 'button', function(e) {
				e.preventDefault();
				if (e.target.id === 'close-button') {
					userResponse = 'closed';
				}
				hideModal(container);
			});

			content.append(header);
			content.append(body);
			content.append(footer);
			dialog.append(content);
			container.append(dialog);
			$('body').append(container);

			const modal = new bootstrap.Modal(container, {
				"backdrop"  : "static",
				"keyboard"  : true,
				"focus"		: true
			});

			const containerEl = document.getElementById(`graphileonModal${defaults.type}`);
			containerEl.addEventListener('hidden.bs.modal', function() {
				container.remove();
				defaults.onClose.call(this, container);
			});
			containerEl.addEventListener('hide.bs.modal', function() {
				returnBack();
			});
			containerEl.addEventListener('shown.bs.modal', function() {
				self.fire('ShowPrompt', {viewtype: defaults.type, respond: (response) => {
					userResponse = response;
					hideModal(container);
				}});
				defaults.onShow.call(this, container);
			});

			modal.show();
		}));
	};
	return _show();
};

/**
 * same as window.alert
 * @param  {String} message		Message to show user as prompt
 * @param  {object} options    	options for alert window like height etc
 * @return {String}             User filled value
 */
ViewManager.prototype.alert = function (message, options) {
	let defaults = {
		type: "alert",
		messageText: message,
		alertType: "default",
	};
	if (options) {
		delete options['type'];
		$.extend(defaults, options);
	}
	return this.modalAlert(defaults);
};

/**
 * same as window.propmt
 * @param  {String} message			Message to show user as prompt
 * @param  {*} defaultValue 		Default value for prompt
 * @param  {object} options    		options for prompt window like height etc
 * @return {String}              	User filled value
 */
ViewManager.prototype.prompt = function (message, defaultValue, options) {
	let defaults = {
		type: "prompt",
		messageText: message,
		defaultValue,
		alertType: "default"
	};
	if (options) {
		delete options['type'];
		delete options['alertType'];
		$.extend(defaults, options);
	}
	return this.modalAlert(defaults);
};

/**
 * same as window.confirm
 * @param  {String} message		Message to show user as prompt
 * @param  {object} options    	options for confirm window like height etc
 * @return {Boolean}            User answer
 */
ViewManager.prototype.confirm = function (message, options) {
	let defaults = {
		type: "confirm",
		messageText: message,
		alertType: "info",
		okButtonText: t("Yes"),
		okButtonClass: "btn-primary",
		cancelButtonText: t("No"),
		cancelButtonClass: "",
	};
	if (options) {
		delete options['type'];
		$.extend(defaults, options);
	}

	return deferredPromise(new Promise(async (resolve, reject) => {
		try {
			resolve(await this.modalAlert(defaults));
		} catch(e) {
			if(e === 'closed') {
				// Also closing is considered cancellation
				resolve(false);
			}
			reject(e);
		}
	}));
};

module.exports = ViewManager;
