
	import _ from "lodash";
	import { abstractField } from "vue-form-generator";

	export default {
		mixins: [ abstractField ],

		watch: {
			schema: {
				immediate: true,
				deep: true,
				handler: function(schema) {
					this.schema = _.defaultsDeep(
						schema,
						{
							buttonClasses: ['btn', 'btn-default'],
							caption: '',
							action: null,
							onButtonClick: (event) => {}
						}
					)
				}
			}
		},

		methods: {
			onClick(event) {
				this.schema.onButtonClick(event);
			}
		}
	}
