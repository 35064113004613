const VueRenderer = require('client/src/renderers/vue-renderer');
const Chart = require('chart.js');
const ChartJs = require('./chartjs.vue').default;
const appDefaultFont = require('../../../apps/default/font');

Chart.defaults.global.defaultFontFamily = appDefaultFont.family;

'use strict';

const ChartJsViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
ChartJsViewRenderer.viewType = 'ChartJsView';
ChartJsViewRenderer.prototype = Object.create(VueRenderer.prototype);

ChartJsViewRenderer.prototype.getVueComponent = function() {
	return ChartJs;
};

module.exports = ChartJsViewRenderer;